import { VCard } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.number.is-nan.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "bm-wrapper",
    "class": {
      firstColumnFrozen: _setup.freezeFirstColumn
    }
  }, [_vm.loading ? _c(VCard, {
    staticClass: "py-6",
    attrs: {
      "elevation": "0"
    }
  }, [_c(VProgressCircular, {
    staticClass: "quote",
    attrs: {
      "size": 70,
      "width": 7,
      "color": "primaryAction",
      "indeterminate": ""
    }
  })], 1) : _c(VDataTable, {
    staticClass: "earnings",
    attrs: {
      "dense": "",
      "hide-default-footer": true,
      "mobile-breakpoint": 0,
      "options": {
        itemsPerPage: _vm.data.length
      },
      "items": _vm.data
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('thead', [_c('tr', _vm._l(_vm.headers, function (h, idx) {
          return _c('th', {
            key: h.value,
            staticClass: "common-header-cell text-wrap",
            "class": _setup.getHeaderClass(h, idx),
            attrs: {
              "id": "header_earnings_".concat(h.value)
            }
          }, [_c('span', {
            "class": {
              sticky: idx === 0
            }
          }, [_vm._v("\n              " + _vm._s(h.text) + "\n            ")])]);
        }), 0)])];
      },
      proxy: true
    }, {
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item,
          index = _ref.index;
        return [_vm.lockedData ? _c('tr', {
          staticClass: "freetier-row"
        }, [_c('td', [_vm._v(_vm._s(item.title))]), _vm._v(" "), index === 0 ? _c('td', {
          staticClass: "freetier-msg text-center",
          attrs: {
            "colspan": "5",
            "rowspan": _vm.data.length
          }
        }, [_c(_setup.LockedDataMessage, {
          attrs: {
            "show-icon": true,
            "label": "Data is only available to Pro users",
            "label-extra-classes": "freetier-label"
          }
        })], 1) : _vm._e()]) : _c('tr', {
          "class": _setup.getRowClass(item === null || item === void 0 ? void 0 : item.meta, item.title)
        }, _vm._l(Object.keys(item).filter(function (i) {
          return i !== 'meta';
        }), function (field) {
          return _c('td', {
            key: _setup.getItemKey(field, item[field], item.title),
            "class": _setup.getCellClass(field, item, item.title)
          }, [item.title === 'blank' || !item.title ? [_vm._v("\n             \n          ")] : field === 'title' || item.title === 'Reporting Date' ? [_vm._v("\n            " + _vm._s(item[field]) + "\n          ")] : _c(_setup.BmTableCell, {
            attrs: {
              "desired-units": _vm.desiredUnits,
              "currency-toggle": _vm.currencyToggle,
              "format": item[field].format,
              "decimals": _vm.decimals,
              "unauth": item[field].unauth,
              "unit-id": item[field].unitId,
              "value": item[field].value,
              "price-close": item[field].priceClose,
              "format-function": _vm.formatFunction,
              "hide": Number.isNaN(item[field].value)
            }
          })], 2);
        }), 0)];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };