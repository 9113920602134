import LockedDataMessage from "~/components/LockedDataMessage.vue"
import BmTableCell from "~/components/tables/beatsAndMisses/BmTableCell.vue"
import useBaseUtils from "~/functions/useBaseUtils"
import { MILLION } from "~/utils/constants/numbers"


export default {
  __name: 'BeatsAndMisses',
  props: {
  data: { type: Array, default: () => [] },
  headers: { type: Array, default: () => [] },
  desiredUnits: { type: Number, default: 1 },
  currencyToggle: { type: Number, default: 0 },
  decimals: { type: Number, default: 2 },
  formatFunction: { type: Function, default: (value) => value },
  loading: { type: Boolean, default: true },
  lockedData: { type: Boolean, default: true },
},
  setup(__props) {

const props = __props;



const { freezeFirstColumn } = useBaseUtils()

const getRandom = () => Math.random() * MILLION

const getItemKey = (fieldName, fieldValue, title) => {
  return title === "blank"
    ? `${getRandom()}`
    : `${fieldName}_${title}_${fieldValue}`
}

const greenText = "green--text text--darken-2"
const redText = "red--text text--darken-1"

const getCellClass = (fieldName, obj, title) => {
  const val = obj[fieldName]
  const [datePart] = fieldName.split(" ")
  const base = props.headers.find((i) => i.text === datePart)
  const isMetricName = fieldName === "title"
  const toggleColorClasses =
    ["% Difference", "1D Stock Price Change"].includes(title) && !isMetricName
  const isReportingDate = title === "Reporting Date"
  const isValidRow = !isReportingDate && !isMetricName
  const baseClasses = base?.cellClass || ""
  let extraClasses = ""

  if (isReportingDate && fieldName !== "title") {
    extraClasses = "text-right"
  }

  if (!isValidRow) {
    return `${baseClasses} ${extraClasses}`
  }
  if (!isMetricName && !val?.hasNegativeCells) {
    if (toggleColorClasses) {
      extraClasses = val.value >= 0 ? greenText : redText
    } else {
      extraClasses = val.value >= 0 ? "" : redText
    }
  }
  if (!isMetricName) {
    extraClasses += " text-right"
  }
  return `${baseClasses} ${extraClasses}`
}

const getHeaderClass = (headerObj, idx) => {
  const classes = headerObj?.class?.split(" ")
  const initialObj = { "text-right": idx > 0, "text-left": idx === 0 }
  const classObj = classes.reduce((acc, curr) => {
    acc[curr] = true
    return acc
  }, {})
  return {
    ...classObj,
    ...initialObj,
  }
}

const getRowClass = (metaObj, title) => {
  const rowClassList = metaObj?.rowClass
  if (!title) {
    return { "blank-row": true }
  }
  const baseObj = {
    "common-row": true,
    "actual-row": metaObj?.isActual,
    "estimate-row": metaObj?.isEstimate,
    "diff-row": metaObj?.isDiff,
  }
  if (!rowClassList) {
    return baseObj
  }
  return rowClassList?.split(" ").reduce((acc, curr) => {
    acc[curr] = true
    return acc
  }, baseObj)
}

return { __sfc: true,props, freezeFirstColumn, getRandom, getItemKey, greenText, redText, getCellClass, getHeaderClass, getRowClass, LockedDataMessage, BmTableCell }
}

}