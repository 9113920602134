const LOWERCASE = ["and", "for", "of", "the", "to", "a", "an", "other"]
const UPPERCASE = ["us"]
const REPLACE = {
  corporateandfinancingmember: "corporate and financing member",
  "ipad and related products and services": "ipad",
  "ipad related products and services": "ipad",
  "apple ipad": "ipad",
  "iphone and related products and services": "iphone",
  "iphone related products and services": "iphone",
  "apple iphone": "iphone",
  "apple mac": "mac",
  "manufactured product other": "other products",
  "total macs": "total mac net sales",
  us: "United States",
  "u s": "United States",
  "c n": "China",
}

const capitalize = (word) => {
  return `${word.charAt(0).toUpperCase()}${word.slice(1)}`
}

const normalizeText = (rawText, DYNAMIC_DIC) => {
  const normalized = rawText
    .replace(/[,.]/g, "")
    .replace(/-/g, " ")
    .replace(/\s{2,}/g, " ")
    .toLowerCase()

  const commonTextKey = normalized.split(" ").join("")

  const replacedText = DYNAMIC_DIC[normalized] || normalized

  const caseCorrected = replacedText
    .split(" ")
    .map((word) => {
      const shouldSkip =
        replacedText.startsWith(word) || replacedText.endsWith(word)

      if (!shouldSkip && LOWERCASE.includes(word)) return word.toLowerCase()
      if (UPPERCASE.includes(word)) return word.toUpperCase()

      return capitalize(word)
    })
    .join(" ")

  return { normalized, caseCorrected, commonTextKey }
}

// LINK static/fmp_worker.js:2257
const mergeKeys = (obj) => {
  const result = {}
  const DYNAMIC_REPLACE = { ...REPLACE }

  // Generate additional cases for REPLACE dictionary such as
  // passengerrevenuenonloyalty: 'Passenger Revenue Non Loyalty',
  // cargoandfreight: 'Cargo and Freight',
  Object.keys(obj).forEach((key) => {
    const { caseCorrected, commonTextKey } = normalizeText(key, DYNAMIC_REPLACE)

    const notInDict = !Object.keys(DYNAMIC_REPLACE).includes(commonTextKey)

    if (notInDict && caseCorrected.includes(" ")) {
      DYNAMIC_REPLACE[commonTextKey] = caseCorrected
    }
  })

  Object.keys(obj).forEach((key) => {
    const { caseCorrected } = normalizeText(key, DYNAMIC_REPLACE)

    if (result[caseCorrected]) {
      result[caseCorrected] = { ...result[caseCorrected], ...obj[key] }
    } else {
      result[caseCorrected] = obj[key]
    }
  })
  return result
}

export { mergeKeys }
