import {
  bookValuePerShareDescriptionMatch,
  ffoDescriptionMatch,
} from "~/utils/constants/industryCategories"
import metrics from "~/utils/constants/metrics"

const headerCellClasses = "grey darken-4 white--text py-1 font-weight-regular"
const rightAlignedHeaderClasses = `${headerCellClasses} text-right`
const ebitda = {
  name: "ebitda act.",
  source: "estimates",
}

const bmHeaders = [
  {
    text: "Metric",
    value: "metricName",
    class: headerCellClasses,
  },
  {
    text: "Actual Results",
    class: rightAlignedHeaderClasses,
    cellClass: "text-right highlighted-column",
  },
  {
    text: "Street Estimates",
    class: rightAlignedHeaderClasses,
    cellClass: "text-right border-right",
  },
  {
    text: "Actual Results",
    class: rightAlignedHeaderClasses,
    cellClass: "text-right",
  },
  {
    text: "Actual Results",
    class: rightAlignedHeaderClasses,
    cellClass: "text-right border-right",
  },
  {
    text: "Beat / Miss vs Street",
    class: rightAlignedHeaderClasses,
    cellClass: "text-right",
    cellFormat: "pct",
    toggleColor: true,
  },
  {
    text: "% Change YoY",
    class: rightAlignedHeaderClasses,
    cellClass: "text-right highlighted-column",
    cellFormat: "pct",
  },
  {
    text: "% Change QoQ",
    class: rightAlignedHeaderClasses,
    cellClass: "text-right",
    cellFormat: "pct",
  },
]

const bmRows = [
  {
    metricName: "Revenue",
    meta: {
      rowClass: "border-below font-weight-bold",
      disableLessThenZeroValues: true,
      metricData: {
        metricKeys: [metrics.revenue],
        formula: "val",
      },
    },
  },
  {
    metricName: "EBITDA",
    meta: {
      disableLessThenZeroValues: true,
      metricData: {
        metricKeys: [ebitda],
        formula: "val",
      },
    },
  },
  {
    metricName: "% EBITDA Margins",
    meta: {
      rowClass: "font-italic",
      spacing: true,
      cellFormat: "whole_pct",
      hideOnEarningsTable: true,
      metricData: {
        metricKeys: [ebitda, metrics.revenue],
        formula: "div",
      },
    },
  },
  {
    metricName: "EBIT",
    meta: {
      rowClass: "font-weight-bold",
      disableLessThenZeroValues: true,
      metricData: {
        metricKeys: [metrics.ebitAct],
        formula: "val",
      },
    },
  },
  {
    metricName: "EBIT Margins",
    meta: {
      rowClass: "font-italic",
      spacing: true,
      cellFormat: "pct",
      hideOnEarningsTable: true,
      metricData: {
        metricKeys: [metrics.ebitAct, metrics.revenue],
        formula: "div",
      },
    },
  },
  {
    metricName: "Net Income",
    meta: {
      rowClass: "border-below",
      disableLessThenZeroValues: true,
      metricData: {
        metricKeys: [metrics.netIncomeAct],
        formula: "val",
      },
    },
  },
  {
    metricName: "Adjusted EPS",
    meta: {
      rowClass: "font-weight-bold border-below",
      disableLessThenZeroValues: true,
      metricData: {
        metricKeys: [metrics.epsNormalized],
        formula: "val",
      },
    },
  },
  {
    metricName: "EPS GAAP",
    meta: {
      disableLessThenZeroValues: true,
      metricData: {
        metricKeys: [metrics.epsGaap],
        formula: "val",
      },
    },
  },
  {
    metricName: "CFO",
    meta: {
      metricData: {
        metricKeys: [metrics.cfoAct],
        formula: "val",
      },
    },
  },
  {
    metricName: "CAPEX",
    meta: {
      rowClass: "border-below",
      metricData: {
        metricKeys: [metrics.capitalExpenditureAct],
        formula: "val",
      },
    },
  },
  {
    metricName: "FCF",
    meta: {
      rowClass: "font-weight-bold",
      metricData: {
        metricKeys: [metrics.freeCashFlow],
        formula: "val",
      },
    },
  },
  {
    metricName: "Book Value / Share",
    meta: {
      allowedCategories: bookValuePerShareDescriptionMatch,
      metricData: {
        metricKeys: [metrics.bookValuePerShare],
        formula: "val",
      },
    },
  },
  {
    metricName: "FFO (REIT)",
    meta: {
      allowedCategories: ffoDescriptionMatch,
      metricData: {
        metricKeys: [metrics.ffoReit],
        formula: "val",
      },
    },
  },
  {
    metricName: "FFO/Share (REIT)",
    meta: {
      allowedCategories: ffoDescriptionMatch,
      metricData: {
        metricKeys: [metrics.ffoPerShare],
        formula: "val",
      },
    },
  },
]

const configByIndex = [
  { type: "actual", relativeDateKey: 0, prefix: "actual" },
  { type: "estimate", relativeDateKey: 0, prefix: "estimate" },
  { type: "actual", relativeQuarterKey: -4, prefix: "oneYearAgo" },
  {
    type: "actual",
    relativeDateKey: 0,
    relativeQuarterKey: -1,
    prefix: "oneQuarterAgo",
  },
  {
    type: "actual",
    relativeDateKey: 0,
    prefix: "beatMiss",
    cellFormat: "pct",
  },
  {
    type: "actual",
    relativeDateKey: 0,
    prefix: "changeYoY",
    formula: "YoY",
    cellFormat: "pct",
  },
  {
    type: "actual",
    relativeDateKey: 0,
    prefix: "changeQoQ",
    formula: "QoQ",
    cellFormat: "pct",
  },
]

const possibleBpsHeaders = [
  "Beat / Miss vs Street",
  "% Change YoY",
  "% Change QoQ",
]

const possibleBpsRows = ["% EBITDA Margins", "EBIT Margins"]

export { bmHeaders, bmRows, configByIndex, possibleBpsHeaders, possibleBpsRows }
