"use strict"

import { calculateCAGR } from "~/modules/ratios"

export default {
  methods: {
    transformEstRowToHighchart(
      resData,
      dateHeaders,
      row,
      desiredUnits,
      currencyToggle,
      rowId,
      guidance = false
    ) {
      // transform the finRow object into an object suitable for plotting
      /**
       * type: "column" or "line"
       * name: "Series name"
       * data: [[date, yVal]]
       * this will involve using the dateHeaders
       */
      const formula = row.formula
      let type =
        formula === "val" || formula === "turns" || formula === "sum"
          ? "column"
          : "line"
      // FIXME: add a key on the row in selectedRows that if present will force the type
      if (row.seriesType) {
        type = row.seriesType
      }
      // now all you need to do is create the UI that allows this key to be changed
      // in a reactive manner
      const yAxis =
        formula === "dxdt" || formula === "pct" || formula === "div" ? 1 : 0

      const formatNumber = this.formatNumber
      const dataLabels = {
        formatter: function () {
          return `${formatNumber.format(this.y)}`
        },
      }
      const tooltip = {
        pointFormat: `<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b><br/>`,
      }

      if (yAxis === 1) {
        dataLabels.formatter = function () {
          return `${formatNumber.format(this.y)}%`
        }
        tooltip.pointFormat = `<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}%</b><br/>`
      }

      const data = dateHeaders.reduce((acc, d) => {
        const dataObj = this.determineDataObject(row, d, guidance)
        if (dataObj && !dataObj.unauth) {
          let value

          if (row.formula === "val" || row.formula === "pct") {
            value = parseFloat(dataObj.dataitemvalue)

            if (row.formula === "val") {
              value = this.convertEstUnits(
                value,
                dataObj.estimatescaleid,
                desiredUnits
              )
            }
          } else {
            value = dataObj.dataitemvalue
          }

          if (row.formula === "dxdt" || row.formula === "div") {
            value = value * 100
          }

          const currExRate = parseFloat(dataObj.priceclose) || 1
          const exRate = currencyToggle === 1 ? currExRate : 1
          if (row.formula === "val") {
            value = value / exRate
          }
          // const point = [d.timeVal, value / exRate]
          const point = { x: d.timeVal, y: value, dataLabels }
          acc.push(point)
          return acc
        } else {
          return acc
        }
      }, [])

      let name = row.dataitemname.trim()
      if (guidance) {
        name = `${name} ${guidance}`
      }
      const id = rowId // OLD row.dataitemid
      if (formula === "dxdt") {
        // FIXME: difference between how the dataitemid is stored for dxdt types
        // between estimates and financials
        // estimates have it in an array while financials have it as a raw number
        const thing = row.dataitemid[0]
        const weird = resData[thing] // resData for estimates doesn't contain the metadata like name
        name = `${weird.dataitemname.trim()} ${name}`
      }

      return {
        type,
        name,
        data,
        tooltip,
        yAxis,
        rowId,
        id,
        formula,
      }
    },
    convertEstUnits(value, unitTypeId, desiredUnits) {
      /**
       * convertEstUnits takes a value (NUMBER TYPE) and a financial
       * unitType and performs the required conversion depending
       * on the desiredUnits (ENUM either 0, 1, 2)
       * returns the value as a Number which has been converted
       */
      if ([18, 19, 30, 36].includes(unitTypeId)) {
        // Oil and Gas Metric
        // return `${print(result)} ${unittypeid}`
        return value
      }
      if (unitTypeId < 3) {
        // value is a reported financial number in some currency of either thousands or millions... convert

        // Now convert from reported units (1e3, 1e6) to desired units
        // desiredUnits - 0 thousand, 1 million, 2 billion
        // right now a zero means no conversion
        // and a negative 2 means that you want it a lot bigger than it currently is
        // so transform
        const unitTypeTransform = {
          0: 2, // millions,
          1: 3, // billions,
          2: 1, // thousands,
        }
        const unitConversion =
          unitTypeTransform[unitTypeId] - (desiredUnits + 1) // results the 10^unitConversion needed
        /**
         * desired units plus one means..
         * 1 = thousands
         * 2 = million
         * 3 = billion
         */
        let result = value
        switch (unitConversion) {
          case -2:
            // multiply 1e-6
            result = result / 1000000
            return result
          case -1:
            // multiply by 1e-3
            result = result / 1000
            return result
          case 0:
            // multiply by 1
            return result
          case 1:
            // 1 e3
            result = result * 1000
            return result
          default:
            console.log("Converting Units Logic Error: ", unitConversion)
            return result
        }
      } else if (unitTypeId === 3) {
        // in absolute units
        return value
      } else if (unitTypeId === 4) {
        return value / 100
      }
    },
    determineDataObject(lineitem, dateObj, guidance = false) {
      // Function goal - take the lineitem and dateObj and return
      // the correct object containing the dataitemvalue because the actual
      // data object is stored under the key 'mean' 'median' 'actual'... eventually high/low as well

      // First, determine the type of date (actual, mean, median)
      // FIXME: This is where you would perform logic to switch between mean and median
      let dateType = dateObj.isEstimate ? "mean" : "actual"
      if (this.specialPeriod) {
        dateType = "actual"
      } else if (guidance) {
        dateType = guidance
      }
      const periodId = dateObj.value
      // check lineitem[dataType], return the dataitemvalue object or undefined
      return lineitem[dateType] ? lineitem[dateType][periodId] : undefined
    },
    estValCAGR(lineitem, dateHeaders, reverseDates = false) {
      const firstDate = reverseDates
        ? dateHeaders[dateHeaders.length - 1]
        : dateHeaders[0]
      const lastDate = reverseDates
        ? dateHeaders[0]
        : dateHeaders[dateHeaders.length - 1]

      const firstDataObj = this.determineDataObject(lineitem, firstDate)
      const lastDataObj = this.determineDataObject(lineitem, lastDate)
      if (firstDataObj && lastDataObj) {
        const { cagr } = calculateCAGR({
          initialValue: firstDataObj.dataitemvalue,
          lastValue: lastDataObj.dataitemvalue,
          firstDate: firstDataObj.timeVal,
          lastDate: lastDataObj.timeVal,
        })

        return cagr
      }
    },
  },
}
