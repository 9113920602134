import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
function _slicedToArray(r, e) {
  return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest();
}
function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
function _iterableToArrayLimit(r, l) {
  var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"];
  if (null != t) {
    var e,
      n,
      i,
      u,
      a = [],
      f = !0,
      o = !1;
    try {
      if (i = (t = t.call(r)).next, 0 === l) {
        if (Object(t) !== t) return;
        f = !1;
      } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0);
    } catch (r) {
      o = !0, n = r;
    } finally {
      try {
        if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return;
      } finally {
        if (o) throw n;
      }
    }
    return a;
  }
}
function _arrayWithHoles(r) {
  if (Array.isArray(r)) return r;
}
import dayjs from "dayjs";
import { cloneDeep, last } from "lodash";
import { hasProp } from "~/utils/screener";
var mapObjectToTableType = function mapObjectToTableType(tableType, obj) {
  if (tableType === "financials") {
    return {
      iso: obj === null || obj === void 0 ? void 0 : obj.iso,
      pc: obj === null || obj === void 0 ? void 0 : obj.pc,
      timeVal: obj.dV,
      u: obj === null || obj === void 0 ? void 0 : obj.u,
      unauth: obj === null || obj === void 0 ? void 0 : obj.unauth,
      v: obj === null || obj === void 0 ? void 0 : obj.v
    };
  } else {
    return {
      dataitemvalue: obj === null || obj === void 0 ? void 0 : obj.v,
      effectivedate: obj === null || obj === void 0 ? void 0 : obj.dT,
      estimatescaleid: obj === null || obj === void 0 ? void 0 : obj.u,
      isocode: obj === null || obj === void 0 ? void 0 : obj.iso,
      priceclose: obj === null || obj === void 0 ? void 0 : obj.pc,
      timeVal: obj === null || obj === void 0 ? void 0 : obj.dV,
      unauth: obj === null || obj === void 0 ? void 0 : obj.unauth
    };
  }
};
var createAnnualPeriodic = function createAnnualPeriodic(tblDataObj, tableType, mostRecentDateKey) {
  var _mostRecentDateKey$sp = mostRecentDateKey.split("##").map(Number),
    _mostRecentDateKey$sp2 = _slicedToArray(_mostRecentDateKey$sp, 1),
    year = _mostRecentDateKey$sp2[0];
  var keys = Object.keys(tblDataObj);
  return keys.reduce(function (acc, key) {
    var _tblDataObj$key;
    var currentItemYear = dayjs.utc((_tblDataObj$key = tblDataObj[key]) === null || _tblDataObj$key === void 0 ? void 0 : _tblDataObj$key.dT).year();
    var isMostRecentDateKeyYear = year === currentItemYear;
    var sameYearKeys = keys.filter(function (i) {
      var item = tblDataObj[i];
      return dayjs.utc(item.dV).year() === currentItemYear;
    });
    var yearKey = "".concat(currentItemYear, "##FY");
    if (isMostRecentDateKeyYear) {
      var ltmKey = "".concat(currentItemYear, "##").concat(sameYearKeys.length);
      if (!acc[ltmKey]) {
        acc[ltmKey] = tblDataObj[last(sameYearKeys)];
      }
    }
    acc[yearKey] = mapObjectToTableType(tableType, tblDataObj[last(sameYearKeys)]);
    return acc;
  }, {});
};
var createQuarterlyPeriodic = function createQuarterlyPeriodic(tblDataObj, tableType) {
  var keys = Object.keys(tblDataObj);
  return keys.reduce(function (acc, key) {
    var _tblDataObj$key2;
    var currentItemYear = dayjs.utc((_tblDataObj$key2 = tblDataObj[key]) === null || _tblDataObj$key2 === void 0 ? void 0 : _tblDataObj$key2.dT).year();
    var sameYearKeys = keys.filter(function (i) {
      var item = tblDataObj[i];
      return dayjs.utc(item.dV).year() === currentItemYear;
    });
    var indexOfCurrentItem = sameYearKeys.indexOf(key);
    var newKey = "".concat(currentItemYear, "##").concat(indexOfCurrentItem + 1);
    acc[newKey] = mapObjectToTableType(tableType, tblDataObj[key]);
    return acc;
  }, {});
};
var createSemiAnnualPeriodic = function createSemiAnnualPeriodic(rows, tableType, dateArr) {
  return dateArr.reduce(function (acc, item) {
    var key = "".concat(item.calendaryear, "##").concat(item.calendarquarter);
    var dateToMatch = item.timeVal;
    var matchingRowKeys = Object.keys(rows).filter(function (rowKey) {
      var row = rows[rowKey];
      return row.dV === dateToMatch || row.dV < dateToMatch;
    });
    var lastMatchingRowKey = last(matchingRowKeys);
    acc[key] = mapObjectToTableType(tableType, (rows === null || rows === void 0 ? void 0 : rows[lastMatchingRowKey]) || {});
    return acc;
  }, {});
};
var createLtmPeriodic = function createLtmPeriodic(rows, tableType, dateArr) {
  return dateArr.reduce(function (acc, item) {
    var key = "".concat(item.calendaryear, "##").concat(item.calendarquarter);
    var dateToMatch = item.timeVal;
    var matchingRowKeys = Object.keys(rows).filter(function (rowKey) {
      var row = rows[rowKey];
      return row.dV === dateToMatch || row.dV < dateToMatch;
    });
    var lastMatchingRowKey = last(matchingRowKeys);
    acc[key] = mapObjectToTableType(tableType, (rows === null || rows === void 0 ? void 0 : rows[lastMatchingRowKey]) || {});
    return acc;
  }, {});
};
var createCalendarYearPeriodic = function createCalendarYearPeriodic(rows, tableType, dateArr) {
  return dateArr.reduce(function (acc, item) {
    var key = "".concat(item.calendaryear, "##CY");
    var dateToMatch = item.timeVal;
    var matchingRowKeys = Object.keys(rows).filter(function (rowKey) {
      var row = rows[rowKey];
      return row.dV === dateToMatch || row.dV < dateToMatch;
    });
    var lastMatchingRowKey = last(matchingRowKeys);
    acc[key] = mapObjectToTableType(tableType, (rows === null || rows === void 0 ? void 0 : rows[lastMatchingRowKey]) || {});
    return acc;
  }, {});
};
var createPeriodic = function createPeriodic(periodType, tblDataObj, metricKey, tableType, mostRecentDateKey, datesArr) {
  var rows = (tblDataObj === null || tblDataObj === void 0 ? void 0 : tblDataObj[metricKey]) || {};
  if (periodType === "a") {
    return createAnnualPeriodic(rows, tableType, mostRecentDateKey);
  } else if (periodType === "q") {
    return createQuarterlyPeriodic(rows, tableType);
  } else if (periodType === "semi") {
    return createSemiAnnualPeriodic(rows, tableType, datesArr);
  } else if (periodType === "ltm") {
    return createLtmPeriodic(rows, tableType, datesArr);
  } else if (periodType === "cy") {
    return createCalendarYearPeriodic(rows, tableType, datesArr);
  }
  return {};
};
var useCiqValuationTimeSeries = function useCiqValuationTimeSeries(_ref) {
  var _ref$rows = _ref.rows,
    rows = _ref$rows === void 0 ? [] : _ref$rows,
    _ref$tableType = _ref.tableType,
    tableType = _ref$tableType === void 0 ? "estimates" : _ref$tableType,
    _ref$periodType = _ref.periodType,
    periodType = _ref$periodType === void 0 ? "a" : _ref$periodType;
  var _useNuxtApp = useNuxtApp(),
    $store = _useNuxtApp.$store;
  var loading = computed(function () {
    if (tableType === "estimates") {
      return $store.state.ciq.fetchingEstimates || $store.state.ciq.fetchingValuation;
    }
    return $store.state.ciq.fetchingFinancials || $store.state.ciq.fetchingValuation;
  });
  var mostRecentDateKey = computed(function () {
    var _$store$state$ciq;
    return (_$store$state$ciq = $store.state.ciq) === null || _$store$state$ciq === void 0 || (_$store$state$ciq = _$store$state$ciq[tableType]) === null || _$store$state$ciq === void 0 || (_$store$state$ciq = _$store$state$ciq[periodType]) === null || _$store$state$ciq === void 0 ? void 0 : _$store$state$ciq.mostRecentDateKey;
  });
  var dates = computed(function () {
    var _$store$state$ciq2;
    return ((_$store$state$ciq2 = $store.state.ciq) === null || _$store$state$ciq2 === void 0 || (_$store$state$ciq2 = _$store$state$ciq2[tableType]) === null || _$store$state$ciq2 === void 0 || (_$store$state$ciq2 = _$store$state$ciq2[periodType]) === null || _$store$state$ciq2 === void 0 ? void 0 : _$store$state$ciq2.dates) || [];
  });
  var currentTidMultiplesStore = computed(function () {
    var _$store$state$ciq$add;
    var tid = $store.state.ciq.ticker.tradingitemid;
    return (_$store$state$ciq$add = $store.state.ciq.addMultiples) === null || _$store$state$ciq$add === void 0 ? void 0 : _$store$state$ciq$add[tid];
  });
  var dailyMultiplesStoreObj = computed(function () {
    var _currentTidMultiplesS;
    return (_currentTidMultiplesS = currentTidMultiplesStore.value) === null || _currentTidMultiplesS === void 0 ? void 0 : _currentTidMultiplesS.dailyMultiples;
  });
  var tblDataStoreObj = computed(function () {
    var _currentTidMultiplesS2;
    return (_currentTidMultiplesS2 = currentTidMultiplesStore.value) === null || _currentTidMultiplesS2 === void 0 ? void 0 : _currentTidMultiplesS2.tblDataObj;
  });
  var dailyPriceFactorsForHighChart = computed(function () {
    if (loading.value) {
      return {};
    }
    var dailyMultiplesObj = dailyMultiplesStoreObj.value;
    return rows.reduce(function (acc, item) {
      var metricKey = item.dailyId;
      if (hasProp(dailyMultiplesObj, metricKey)) {
        var metricObj = cloneDeep(dailyMultiplesObj[metricKey]) || {};
        acc[metricKey] = metricObj;
      }
      return acc;
    }, {});
  });
  var priceFactorsForTables = computed(function () {
    if (loading.value) {
      return _defineProperty({}, periodType, {});
    }
    var tblDataObj = tblDataStoreObj.value;
    return rows.reduce(function (acc, row) {
      var metricKey = row.priceFactorId;
      acc[periodType][metricKey] = createPeriodic(periodType, tblDataObj, metricKey, tableType, mostRecentDateKey.value, dates.value);
      return acc;
    }, _defineProperty({}, periodType, {}));
  });
  return {
    dailyPriceFactorsForHighChart: dailyPriceFactorsForHighChart,
    priceFactorsForTables: priceFactorsForTables
  };
};
export default useCiqValuationTimeSeries;