import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/esnext.set.add-all.js";
import "core-js/modules/esnext.set.delete-all.js";
import "core-js/modules/esnext.set.difference.js";
import "core-js/modules/esnext.set.every.js";
import "core-js/modules/esnext.set.filter.js";
import "core-js/modules/esnext.set.find.js";
import "core-js/modules/esnext.set.intersection.js";
import "core-js/modules/esnext.set.is-disjoint-from.js";
import "core-js/modules/esnext.set.is-subset-of.js";
import "core-js/modules/esnext.set.is-superset-of.js";
import "core-js/modules/esnext.set.join.js";
import "core-js/modules/esnext.set.map.js";
import "core-js/modules/esnext.set.reduce.js";
import "core-js/modules/esnext.set.some.js";
import "core-js/modules/esnext.set.symmetric-difference.js";
import "core-js/modules/esnext.set.union.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _toConsumableArray(r) {
  return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread();
}
function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _iterableToArray(r) {
  if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r);
}
function _arrayWithoutHoles(r) {
  if (Array.isArray(r)) return _arrayLikeToArray(r);
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
import { SHOW_PRICE_FACTORS } from "~/feature-toggle";
import useCiqValuationTimeSeries from "~/functions/useCiqValuationTimeSeries";
import useDateTimeFormatters from "~/functions/useDateTimeFormatters";
var useCIQEstimates = function useCIQEstimates(props) {
  var _useNuxtApp = useNuxtApp(),
    $store = _useNuxtApp.$store;
  var _useDateTimeFormatter = useDateTimeFormatters(),
    utcEpochToShortDate = _useDateTimeFormatter.utcEpochToShortDate,
    utcEpochToTwoDigitYear = _useDateTimeFormatter.utcEpochToTwoDigitYear;
  var getExtraData = function getExtraData(_ref) {
    var keys = _ref.keys,
      period = _ref.period,
      initialIndex = _ref.initialIndex;
    var _useCiqValuationTimeS = useCiqValuationTimeSeries({
        tableType: "estimates",
        rows: keys,
        periodType: period
      }),
      priceFactorsForTables = _useCiqValuationTimeS.priceFactorsForTables;
    var currentPeriodPriceFactors = priceFactorsForTables.value[period] || {};
    var mappedPriceFactors = Object.values(currentPeriodPriceFactors).reduce(function (acc, obj, i) {
      var item = keys[i];
      var isStockPrice = item.name === "Price Close";
      acc.push({
        actual: obj,
        mean: obj,
        dataitemid: item.dailyId,
        name: item.name,
        lineorder: initialIndex + i,
        tikrdisplay: "1",
        format: item.format || "normal",
        formula: "val",
        multiplyby: 1,
        datasource: "valuation",
        yAxis: isStockPrice ? 2 : 3,
        seriesType: "line"
      });
      return acc;
    }, []);
    return mappedPriceFactors;
  };
  var loading = computed(function () {
    return $store.state.ciq.fetchingEstimates || $store.state.ciq.fetchingActuals;
  });
  var resData = computed(function () {
    var period = props.period.value;
    var finData = $store.state.ciq.estimates;
    if (finData[period]) {
      return finData[period].resData || [];
    }
    return [];
  });
  var extraData = computed(function () {
    var _$store$state$ciq;
    if (!props.extraRows.length) {
      return [];
    }
    var data = (_$store$state$ciq = $store.state.ciq) === null || _$store$state$ciq === void 0 ? void 0 : _$store$state$ciq[props.stateKey.value];
    var period = props.period.value;
    var item = data[period].estimates[0];
    var highestIndex = item.reduce(function (acc, item) {
      if (item.lineorder > acc) {
        acc = item.lineorder;
      }
      return acc;
    }, 0);
    return getExtraData({
      keys: props.extraRows,
      period: props.period.value,
      initialIndex: highestIndex
    });
  });
  var dailyPriceFactors = computed(function () {
    var _props$extraRows;
    if (!(props !== null && props !== void 0 && (_props$extraRows = props.extraRows) !== null && _props$extraRows !== void 0 && _props$extraRows.length) || !SHOW_PRICE_FACTORS) {
      return {};
    }
    var _useCiqValuationTimeS2 = useCiqValuationTimeSeries({
        tableType: "estimates",
        rows: props.extraRows,
        periodType: props.period.value
      }),
      dailyPriceFactorsForHighChart = _useCiqValuationTimeS2.dailyPriceFactorsForHighChart;
    return dailyPriceFactorsForHighChart.value;
  });
  var availableDates = computed(function () {
    var estData = $store.state.ciq.estimates;
    var dates = [];
    if (estData[props.period.value]) {
      dates = estData[props.period.value].dates || [];
    }
    return dates.map(function (d) {
      d.text = utcEpochToShortDate.value.format(d.epoch);
      d.annualLabel = "'".concat(utcEpochToTwoDigitYear.value.format(d.epoch));
      d.quarterLabel = "".concat(d.calendarquarter, "Q").concat(utcEpochToTwoDigitYear.value.format(d.epoch));
      return d;
    });
  });
  var ciqCategories = computed(function () {
    var period = props.period.value;
    var finData = $store.state.ciq.estimates;
    if (finData[period]) {
      return finData[period].estimatesLineItems || [];
    }
    return [];
  });
  var estimates = computed(function () {
    var _$store$state$ciq2;
    var stateKey = props.stateKey.value;
    var data = (_$store$state$ciq2 = $store.state.ciq) === null || _$store$state$ciq2 === void 0 ? void 0 : _$store$state$ciq2[stateKey];
    var period = props.period.value;
    if (data !== null && data !== void 0 && data[period]) {
      var _props$extraRows2;
      if ((props === null || props === void 0 || (_props$extraRows2 = props.extraRows) === null || _props$extraRows2 === void 0 ? void 0 : _props$extraRows2.length) > 0 && SHOW_PRICE_FACTORS) {
        return data[period].estimates.reduce(function (acc, item, idx) {
          var isActualsAndForwardEstimatesTab = idx === 0;
          if (isActualsAndForwardEstimatesTab) {
            acc.push([].concat(_toConsumableArray(item), _toConsumableArray(extraData.value)));
            return acc;
          }
          acc.push(item);
          return acc;
        }, []);
      }
      return data[period].estimates || [];
    }
    return [];
  });
  var dateHeaders = computed(function () {
    var periodId = new Set(); /**
                              * The dates array needs to be on the dates key
                              * headers needs to have
                              * estimateperiodid -> relativeconstant ... key to extract data
                              * d.periodenddate to make label -> quarterenddate or pricing date if relconst = 500
                              *
                              * basically the date object needs to contain the information to
                              * make the rendered label and the information that acts as
                              * the key to extract the data from the lineitem object
                              */
    var fullDatesArr = availableDates.value.reduce(function (acc, d) {
      if (periodId.has(d.value)) {
        return acc;
      }
      periodId.add(d.value);
      acc.push(d);
      return acc;
    }, []);
    var estDatesOnly = fullDatesArr.filter(function (f) {
      return f.isEstimate;
    });
    var estDatesLength = estDatesOnly.length;
    var headers = fullDatesArr.map(function (m, mIdx) {
      m.lengthMinusIdx = fullDatesArr.length - estDatesLength - mIdx;
      return m;
    }).filter(function (_f, i) {
      return i >= props.dateRange.value[0] && i <= props.dateRange.value[1];
    });
    if (props.reverseDates.value) {
      headers = headers.reverse();
    }
    return headers;
  });
  var dateHeadersForHighcharts = computed(function () {
    // map over dateHeaders to create a new array so we can use .reverse
    var highChartsDates = dateHeaders.value.map(function (m) {
      return m;
    });
    if (props.reverseDates.value) {
      highChartsDates = highChartsDates.reverse();
    }
    return highChartsDates;
  });
  return {
    loading: loading,
    availableDates: availableDates,
    ciqCategories: ciqCategories,
    dateHeaders: dateHeaders,
    dateHeadersForHighcharts: dateHeadersForHighcharts,
    estimates: estimates,
    resData: resData,
    dailyPriceFactors: dailyPriceFactors
  };
};
export default useCIQEstimates;