var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{attrs:{"dense":""}},[(_vm.error)?_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-card',{style:(_vm.sectionWrapperStyle),attrs:{"elevation":"0"}},[_vm._v("\n      "+_vm._s(_vm.I18nFn("Error Fetching Data"))+"\n    ")])],1):(!_vm.loadingEstimates)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","order":"1"}},[_c('v-card',{style:(_vm.sectionWrapperStyle),attrs:{"elevation":"0"}},[_c('CiqTableOptions',{attrs:{"fluid":true,"table-type":"estimates","periods":_vm.periods,"available-dates":_vm.availableDates,"tick-labels":_vm.availableDates.map(_vm.makeDateLabels),"date-headers":_vm.dateHeaders,"show-all-empty-rows":_vm.showAllEmptyRows,"ticker":_vm.ticker,"alert-not-default-ticker":true,"display-empty-rows-toggle":false,"display-range-slider":_vm.displayRangeSlider,"is-bm":_vm.isBeatsAndMisses,"is-eb":_vm.isEstimatesBreakdown},on:{"copytable":() => _vm.copyTable(_vm.tableClassToMatch),"showallemptyrows":function($event){_vm.showAllEmptyRows = !_vm.showAllEmptyRows}}})],1)],1),_vm._v(" "),_c('v-col',{staticClass:"py-0 mt-4",attrs:{"cols":"12","order":"2"}},[_c('v-card',{staticClass:"py-3",style:(_vm.sectionWrapperStyle),attrs:{"elevation":"0"}},[_c('TableChartButton',{attrs:{"charttype":"Estimates","period":_vm.period,"charttitle":_vm.ticker.tickersymbol
                ? `${_vm.ticker.companyname} (${_vm.ticker.tickersymbol})`
                : '',"chartsymbol":_vm.ticker.tickersymbol,"selectedrows":_vm.rowsForChart,"dateheaders":_vm.dateHeaders,"columndecimals":_vm.decimals,"columnunits":_vm.units[_vm.desiredUnits].title,"desiredunits":_vm.desiredUnits,"extra-rows":_vm.dailyPriceFactors,"columnisocode":_vm.currencies[_vm.currencyToggle]?.code},on:{"removerow":_vm.removeRowFromSelectedObject}})],1)],1),_vm._v(" "),_c('v-col',{staticClass:"pt-0 mt-4",attrs:{"cols":"12","order":"3"}},[_c('v-card',{staticClass:"pt-2",style:(_vm.sectionWrapperStyle),attrs:{"elevation":"0"}},[_c('StyledTabs',{attrs:{"tablist":_vm.statementTabs,"old-layout":true},on:{"change":_vm.handleTabChange}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[(_vm.ciqCategories.length === 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-center"},[_vm._v("\n                    "+_vm._s(`${_vm.ticker.exchangename} ${_vm.I18nFn("ticker")} ${
                        _vm.ticker.tickersymbol
                      } ${_vm.I18nFn("has no estimates for this time period")}`)+"\n                  ")])]):_vm._e(),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","justify":"center","align":"center"}},[_vm._l((_vm.ciqCategories),function(statement,statementIndex){return _c('v-responsive',{directives:[{name:"show",rawName:"v-show",value:(statementIndex === _vm.activeStatement),expression:"statementIndex === activeStatement"}],key:`${statement.statement}-tikrEstimate`,staticClass:"tblcontainer d-inline-block",class:{ lighttableshadows: !_vm.$vuetify.theme.dark },attrs:{"max-height":"90vh"}},[(
                        _vm.activeStatement === _vm.actualsAndEstimatesTabId &&
                        statementIndex === 0
                      )?_c('CiqEstimateTable',{key:statement.statement.trim(),attrs:{"tbltitle":statement.statement.trim(),"date-headers":_vm.dateHeaders,"lineitems":_vm.estimates[statementIndex],"metric-obj":_vm.resData,"desired-units":_vm.desiredUnits,"decimals":_vm.decimals,"currency-toggle":_vm.currencyToggle,"show-turns":_vm.showTurns,"period":_vm.period,"show-all-empty-rows":_vm.showAllEmptyRows,"period-obj":_vm.periodObj,"current-iso":_vm.currentIso}}):(
                        _vm.activeStatement === _vm.mgmtGuidanceTabId &&
                        statementIndex === 1
                      )?[(!_vm.data?.[_vm.period]?.hasGuidance)?_c('div',{staticStyle:{"min-height":"90vh"}},[_vm._v("\n                        "+_vm._s(`No management guidance data for this company and period - try another period type (ex. quarterly)`)+"\n                      ")]):_c('CiqMgmtGuidanceTable',{attrs:{"date-headers":_vm.visibleMgmtGuidanceDateHeaders,"lineitems":_vm.visibleMgmtGuidanceLineitems,"est-data-obj":_vm.data,"desiredunits":_vm.desiredUnits,"decimals":_vm.decimals,"currencytoggle":_vm.currencyToggle,"showturns":_vm.showTurns,"period":_vm.period}})]:_vm._e()],2)}),_vm._v(" "),_c('v-responsive',{directives:[{name:"show",rawName:"v-show",value:(
                      [
                        _vm.earningsTabId,
                        _vm.beatsAndMissesTabId,
                        _vm.earningTrendsTabId,
                      ].includes(_vm.activeStatement)
                    ),expression:"\n                      [\n                        earningsTabId,\n                        beatsAndMissesTabId,\n                        earningTrendsTabId,\n                      ].includes(activeStatement)\n                    "}],staticClass:"tblcontainer d-inline-block",class:{ lighttableshadows: !_vm.$vuetify.theme.dark },attrs:{"max-height":"90vh"}},[(_vm.activeStatement === _vm.earningsTabId)?_c('Earnings',{attrs:{"format-function":_vm.formatCellVal,"locked-data":Boolean(_vm.isFreeTier || _vm.isPlusTier)}}):_vm._e(),_vm._v(" "),(_vm.activeStatement === _vm.beatsAndMissesTabId)?_c('BeatsAndMisses',{attrs:{"data":_vm.earnings,"headers":_vm.earningsHeaders,"decimals":_vm.decimals,"desired-units":_vm.desiredUnits,"currency-toggle":_vm.currencyToggle,"format-function":_vm.formatCellVal,"loading":_vm.loadingTable,"locked-data":Boolean(_vm.isFreeTier || _vm.isPlusTier)}}):_vm._e(),_vm._v(" "),_c('feature-toggle',{attrs:{"name":"earnings-trends","value":true}},[(_vm.activeStatement === _vm.earningTrendsTabId)?_c('div',[_c('EstimatesBreakdown',{attrs:{"period":_vm.period,"format-function":_vm.formatCellVal,"desired-units":_vm.desiredUnits,"decimals":_vm.decimals,"currency-toggle":_vm.currencyToggle,"locked-data":Boolean(_vm.isFreeTier || _vm.isPlusTier)}})],1):_vm._e()])],1)],2)],1)],1)],1)],1)],1)],1)],1):_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"py-6",style:(_vm.sectionWrapperStyle),attrs:{"elevation":"0"}},[_c('v-progress-circular',{staticClass:"quote",attrs:{"size":70,"width":5,"color":"primaryAction","indeterminate":""}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }