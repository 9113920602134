var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('table',{staticClass:"guidance"},[_c('colgroup',[_c('col'),_vm._v(" "),_vm._l((_vm.dateHeaders),function(date){return _c('col',{key:date.value + 'header',staticClass:"datacol"})})],2),_vm._v(" "),_c('thead',[_c('tr',{staticClass:"tableheader"},[_c('th',{class:{
          fixedfirstheader: _vm.freezeFirstColumn,
          description: !_vm.freezeFirstColumn,
        }},[_vm._v("\n        "+_vm._s(`${_vm.$Amplify.I18n.get(_vm.tbltitle)}${
            _vm.$device.isDesktopOrTablet ? " | TIKR.com" : ""
          }`)+"\n      ")]),_vm._v(" "),_vm._l((_vm.dateHeaders),function(date){return _c('th',{key:date.value + '-tikrHead'},[_vm._v("\n        "+_vm._s(`${date.text} ${
            date.isEstimate ? _vm.$Amplify.I18n.get("E") : _vm.$Amplify.I18n.get("A")
          }`)+"\n      ")])})],2)]),_vm._v(" "),_c('tbody',[_c('tr',[_vm._v("\n       \n    ")]),_vm._v(" "),_vm._l((_vm.lineitems),function(est){return [_c('tr',{key:`${est.lineorder}-${est.name}-est`},[_c('td',{class:{
            fixedfirstrow: _vm.freezeFirstColumn,
            description: !_vm.freezeFirstColumn,
            fixedfirstrowcolorlight: !_vm.darkActive,
            fixedfirstrowcolordark: _vm.darkActive,
          }},[_vm._v("\n          "+_vm._s(_vm.I18nFn(est.name.trim()))+"\n          "),(_vm.glossary[est.name.trim()] && _vm.showturns)?[_c('v-tooltip',{attrs:{"bottom":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"x-small":"","color":"primaryAction"}},on),[_vm._v("\n                  mdi-information-outline\n                ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("\n                "+_vm._s(_vm.glossary[est.name.trim()])+"\n              ")])])]:_vm._e()],2)]),_vm._v(" "),_vm._l((Object.entries(_vm.estDataToShow)),function([key, value]){return [(est.formula !== 'h3' && typeof est[key] === 'object')?_c('tr',{key:`${key}-${est.name}-${key}`,class:_vm.generateRowClasses(
              _vm.nestedRowToSelectedKey(est, key),
              _vm.selectedRows,
              _vm.darkActive,
              est,
              false
            ),on:{"click":function($event){return _vm.estGuideRowClick(est, key)}}},[_c('td',{class:{
              fixedfirstrow: _vm.freezeFirstColumn,
              description: !_vm.freezeFirstColumn,
              fixedfirstrowcolorlight: !_vm.darkActive,
              fixedfirstrowcolordark: _vm.darkActive,
            }},[_vm._v("\n               \n            "),_c('i',[_vm._v(_vm._s(_vm.I18nFn(value)))])]),_vm._v(" "),_vm._l((_vm.dateHeaders),function(d,dateIdx){return _c('EstValueTblCell',{key:d.value + est.dataitemid + key,attrs:{"data-obj":est[key][d.value] ? est[key][d.value] : {},"lineitem":est,"formatclass":_vm.formatCiqDataObj(est[key][d.value], est),"decimals":_vm.decimals,"currencytoggle":_vm.currencytoggle,"desiredunits":_vm.desiredunits,"dateheaders":_vm.dateHeaders,"dateobj":d,"dateidx":dateIdx,"period":_vm.period,"guidance-data":true}})})],2):_vm._e()]})]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }