import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.starts-with.js";
import { watch } from "vue";
import TableCell from "~/components/tables/beatsAndMisses/BmTableCell.vue";
import useBaseUtils from "~/functions/useBaseUtils";
import useBeatsAndMisses from "~/functions/useBeatsAndMisses";
import useCiqTableOptions from "~/functions/useCiqTableOptions";
export default {
  __name: 'EarningsReview',
  props: {
    formatFunction: {
      type: Function,
      "default": function _default(value) {
        return value;
      }
    },
    lockedData: {
      type: Boolean,
      "default": true
    }
  },
  setup: function setup(__props) {
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store;
    var _useBaseUtils = useBaseUtils(),
      freezeFirstColumn = _useBaseUtils.freezeFirstColumn;
    var _useCiqTableOptions = useCiqTableOptions({
        storeModule: "ciq",
        tableType: "estimates"
      }),
      desiredUnits = _useCiqTableOptions.desiredUnits,
      currencyToggle = _useCiqTableOptions.currencyToggle,
      decimals = _useCiqTableOptions.decimals;
    var _useBeatsAndMisses = useBeatsAndMisses(),
      currentPeriods = _useBeatsAndMisses.currentPeriods,
      data = _useBeatsAndMisses.data,
      loading = _useBeatsAndMisses.loading;
    var activeQuarter = ref(currentPeriods.value[0]);
    var activeQuarterData = computed(function () {
      if (loading.value) {
        return {};
      }
      return data.value[activeQuarter.value];
    });
    watch(currentPeriods, function (newVal) {
      if (newVal.length > 1) {
        activeQuarter.value = currentPeriods.value[0];
      }
    });
    var tickerSymbol = computed(function () {
      return $store.state.ciq.ticker.tickersymbol;
    });
    var greenText = "green--text text--darken-2";
    var redText = "red--text text--darken-1";
    var allowedPrefixes = ["beatMiss", "changeYoY", "changeQoQ"];
    var getCellClass = function getCellClass(fieldName, obj) {
      var val = obj[fieldName];
      var base = activeQuarterData.value.headers.find(function (i) {
        return i.columnId === fieldName || i.value === fieldName;
      });
      var toggleColorClasses = base.toggleColor;
      var isMetricName = fieldName === "metricName";
      var isAllowedPrefix = allowedPrefixes.some(function (i) {
        return fieldName.startsWith(i);
      });
      var extraClasses = "";
      if (!isMetricName && !(val !== null && val !== void 0 && val.hasNegativeCells) || isAllowedPrefix) {
        if (toggleColorClasses) {
          extraClasses = val.value >= 0 ? greenText : redText;
        } else {
          extraClasses = val.value >= 0 ? "" : redText;
        }
      }
      if (extraClasses) {
        return "".concat(base.cellClass, " ").concat(extraClasses);
      }
      return (base === null || base === void 0 ? void 0 : base.cellClass) || "";
    };
    return {
      __sfc: true,
      $store: $store,
      freezeFirstColumn: freezeFirstColumn,
      desiredUnits: desiredUnits,
      currencyToggle: currencyToggle,
      decimals: decimals,
      currentPeriods: currentPeriods,
      data: data,
      loading: loading,
      activeQuarter: activeQuarter,
      activeQuarterData: activeQuarterData,
      tickerSymbol: tickerSymbol,
      greenText: greenText,
      redText: redText,
      allowedPrefixes: allowedPrefixes,
      getCellClass: getCellClass,
      TableCell: TableCell
    };
  }
};