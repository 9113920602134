import "core-js/modules/es.number.constructor.js";
import useBaseUtils from "~/functions/useBaseUtils";
import { calculateCAGR } from "~/modules/ratios";
export default defineComponent({
  props: {
    datearr: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    lineitem: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    formatclass: {
      type: String,
      "default": "black--text" // this isn't right, dependent upon darkmode
    },
    decimals: {
      type: Number,
      "default": 2
    },
    showturns: {
      type: Boolean,
      "default": true
    },
    specialperiod: {
      type: Boolean,
      "default": false
    },
    reversedates: {
      type: Boolean,
      "default": false
    }
  },
  setup: function setup() {
    var _useBaseUtils = useBaseUtils(),
      refCode = _useBaseUtils.refCode,
      dev = _useBaseUtils.dev;
    var createPercentFormatter = function createPercentFormatter(pctDecimals) {
      var lang = navigator.language || "default";
      return new Intl.NumberFormat(lang, {
        style: "percent",
        minimumFractionDigits: pctDecimals,
        maximumFractionDigits: pctDecimals
      });
    };
    var formatPercent = createPercentFormatter(1);
    var formatCagrResult = function formatCagrResult(cagr) {
      if (cagr) {
        return cagr > 0 ? "".concat(formatPercent.format(cagr)) // FIXME: .value if formatPercent becomes computed
        : "(".concat(formatPercent.format(Math.abs(cagr)), ")");
      }
    };
    var determineDataObject = function determineDataObject(lineitem, dateObj, specialPeriod) {
      // Function goal - take the lineitem and dateObj and return
      // the correct object containing the dataitemvalue because the actual
      // data object is stored under the key 'mean' 'median' 'actual'... eventually high/low as well
      // First, determine the type of date (actual, mean, median)
      // FIXME: This is where you would perform logic to switch between mean and median
      var dateType = dateObj.isEstimate ? "mean" : "actual";
      if (specialPeriod) {
        dateType = "actual";
      }
      var periodId = dateObj.value; // check lineitem[dataType], return the dataitemvalue object or undefined
      return lineitem[dateType] ? lineitem[dateType][periodId] : {};
    };
    return {
      refCode: refCode,
      dev: dev,
      formatCagrResult: formatCagrResult,
      determineDataObject: determineDataObject
    };
  },
  computed: {
    firstDataObj: function firstDataObj() {
      var dateHeaders = this.datearr;
      var lineitem = this.lineitem;
      var firstDate = this.reversedates ? dateHeaders[dateHeaders.length - 1] : dateHeaders[0] || {};
      var specialPeriod = this.specialperiod;
      return this.determineDataObject(lineitem, firstDate, specialPeriod);
    },
    lastDataObj: function lastDataObj() {
      var dateHeaders = this.datearr;
      var lineitem = this.lineitem;
      var specialPeriod = this.specialperiod;
      var lastDate = this.reversedates ? dateHeaders[0] : dateHeaders[dateHeaders.length - 1] || {};
      return this.determineDataObject(lineitem, lastDate, specialPeriod);
    },
    cagr: function cagr() {
      var firstDataObj = this.firstDataObj;
      var lastDataObj = this.lastDataObj;
      if (firstDataObj && lastDataObj) {
        var _calculateCAGR = calculateCAGR({
            initialValue: firstDataObj.dataitemvalue,
            lastValue: lastDataObj.dataitemvalue,
            firstDate: firstDataObj.timeVal,
            lastDate: lastDataObj.timeVal
          }),
          cagr = _calculateCAGR.cagr;
        return cagr;
      }
      return "";
    },
    unauth: function unauth() {
      var firstObj = this.firstDataObj;
      var lastObj = this.lastDataObj;
      if (firstObj && lastObj) {
        return Boolean(this.firstDataObj.unauth || this.lastDataObj.unauth);
      } else {
        return false;
      }
    },
    cellValue: function cellValue() {
      var cagr = this.cagr;
      return this.formatCagrResult(cagr);
    }
  }
});