import { is, is_bulk } from "./isFmp"
import { processFmpFinancials } from "./transformFmpFinancials"

const dontConvertTikrKeys = [
  "weightedAverageShsOutDil",
  "weightedAverageShsOut",
  "cashConversionCycle_r",
  "daysOfSalesOutstanding_r",
  "daysOfInventoryOutstanding_r",
  "daysOfPayablesOutstanding_r",
]

export { is, is_bulk, dontConvertTikrKeys, processFmpFinancials }
