import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import { utcParse } from "d3-time-format";
import useBaseUtils from "~/functions/useBaseUtils";
import useCiqTableUtils from "~/functions/useCiqTableUtils";
import useCiqTableOptions from "~/functions/useCiqTableOptions";
import useDateTimeFormatters from "~/functions/useDateTimeFormatters";
var parseTime = utcParse("%Y-%m-%dT%H:%M:%S.%LZ");
export default defineComponent({
  props: {
    tbltitle: {
      type: String,
      "default": "Income Statement"
    },
    dateHeaders: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    lineitems: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    metricObj: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    desiredUnits: {
      type: Number,
      "default": 1
    },
    decimals: {
      type: Number,
      "default": 2
    },
    currencyToggle: {
      type: Number,
      "default": 0
    },
    showTurns: {
      type: Boolean,
      "default": true
    },
    showAllEmptyRows: {
      type: Boolean,
      "default": true
    },
    // period is needed for the selectedRows object
    period: {
      type: String,
      "default": "a"
    },
    // why is periodObj needed?
    periodObj: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    // specialPeriod was a boolean flag I was thinking about
    // using when I envisoned the estimates table and the multiples
    // table using the same component. I am including in props as
    // it is referenced in the template
    specialPeriod: {
      type: Boolean,
      "default": false
    },
    currentIso: {
      type: String,
      "default": "USD"
    }
  },
  setup: function setup(props) {
    var _useBaseUtils = useBaseUtils(),
      freezeFirstColumn = _useBaseUtils.freezeFirstColumn,
      darkActive = _useBaseUtils.darkActive,
      specialUser = _useBaseUtils.specialUser,
      onlyShowOrigEstMetrics = _useBaseUtils.onlyShowOrigEstMetrics,
      I18nFn = _useBaseUtils.I18nFn;
    var _useNuxtApp = useNuxtApp(),
      $device = _useNuxtApp.$device;
    var _useDateTimeFormatter = useDateTimeFormatters(),
      formatDate = _useDateTimeFormatter.utcEpochToShortDate;
    var cagrCol = ref(true);
    var _useCiqTableUtils = useCiqTableUtils(props),
      selectedRows = _useCiqTableUtils.selectedEstimateRows,
      addRowToSelectedObject = _useCiqTableUtils.addRowToSelectedObject,
      rowObjToSelectedKey = _useCiqTableUtils.rowObjToSelectedKey,
      removeRowFromSelectedObject = _useCiqTableUtils.removeRowFromSelectedObject,
      glossary = _useCiqTableUtils.glossary,
      formatEstimateClass = _useCiqTableUtils.formatEstimateClass,
      determineDataObject = _useCiqTableUtils.determineDataObject,
      estValCAGR = _useCiqTableUtils.estValCAGR,
      formatCagrClass = _useCiqTableUtils.formatCagrClass;
    var _useCiqTableOptions = useCiqTableOptions({
        storeModule: "ciq",
        tableType: "estimates"
      }),
      reverseDates = _useCiqTableOptions.reverseDates;
    var iconColor = darkActive.value ? "white" : "#838383";
    var cagrTooltip = function cagrTooltip(dateHeaders) {
      try {
        var first = dateHeaders[0];
        var last = dateHeaders[dateHeaders.length - 1];
        var firstPeriod = formatDate.value.format(parseTime(first.periodenddate ? first.periodenddate : first.fiperiodenddate)); // TODO: for financials with LTM... need to figure out how to
        // handle this last period
        var lastPeriod = formatDate.value.format(parseTime(last.periodenddate ? last.periodenddate : last.fiperiodenddate));
        return "".concat(firstPeriod, " to ").concat(lastPeriod, " CAGR");
      } catch (error) {
        console.error("Error creating CAGR tooltip: ", error);
      }
    };
    var estRowClick = function estRowClick(_e, row) {
      if (row.formula === "h3") {
        return;
      } // const tr = e.target.parentNode
      var rowId = rowObjToSelectedKey(row);
      if (selectedRows.value[rowId]) {
        // remove the class list
        // tr.classList.remove("clicked")
        removeRowFromSelectedObject(rowId, props.period, "estimatesChart"); // remove this object from the array
      } else {
        // add the class list
        // tr.classList.add("clicked")
        addRowToSelectedObject(row, rowId, props.period, "estimatesChart"); // add this list to the array
      }
    }; // TODO: compare generateRowClasses to function within useCiqTableUtils
    var generateRowClasses = function generateRowClasses(row) {
      var classArray = row.format.split(" ");
      var rowId = rowObjToSelectedKey(row);
      if (row.formula === "h3") {
        classArray.push("heading");
      }
      if (selectedRows.value[rowId]) {
        classArray.push("clicked");
      }
      return classArray;
    };
    var firstCellClass = computed(function () {
      return {
        fixedfirstheader: freezeFirstColumn.value,
        description: !freezeFirstColumn.value,
        "py-1": true,
        "pl-1": true
      };
    });
    var firstCellLabel = computed(function () {
      return "".concat(I18nFn(props.tbltitle), " ").concat($device.isDesktopOrTablet ? " | TIKR.com" : "");
    });
    var computedLineItems = computed(function () {
      return props.lineitems.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          key: "".concat(item.lineorder, "-").concat(item.name, "-est"),
          classes: generateRowClasses(item)
        });
      }).filter(function (item) {
        return onlyShowOrigEstMetrics.value ? item.tikrdisplay === "1" : item.tikrdisplay === "1" || item.tikrdisplay === "wip" || specialUser && ["wip", "out"].includes(item.tikrdisplay);
      });
    });
    var commonTableCellClasses = computed(function () {
      return {
        fixedfirstrow: freezeFirstColumn.value,
        description: !freezeFirstColumn.value,
        fixedfirstrowcolorlight: !darkActive.value,
        fixedfirstrowcolordark: darkActive.value
      };
    });
    return {
      commonTableCellClasses: commonTableCellClasses,
      computedLineItems: computedLineItems,
      firstCellClass: firstCellClass,
      firstCellLabel: firstCellLabel,
      freezeFirstColumn: freezeFirstColumn,
      darkActive: darkActive,
      specialUser: specialUser,
      onlyShowOrigEstMetrics: onlyShowOrigEstMetrics,
      selectedRows: selectedRows,
      estRowClick: estRowClick,
      rowObjToSelectedKey: rowObjToSelectedKey,
      generateRowClasses: generateRowClasses,
      formatEstimateClass: formatEstimateClass,
      glossary: glossary,
      formatCagrClass: formatCagrClass,
      cagrCol: cagrCol,
      cagrTooltip: cagrTooltip,
      determineDataObject: determineDataObject,
      estValCAGR: estValCAGR,
      formatDate: formatDate,
      reverseDates: reverseDates,
      iconColor: iconColor,
      I18nFn: I18nFn
    };
  }
});