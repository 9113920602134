import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.string.includes.js";
import useBaseUtils from "~/functions/useBaseUtils"; // import useNumberFormatters from "~/functions/useNumberFormatters"
export default defineComponent({
  props: {
    dataObj: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    lineitem: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    desiredunits: {
      type: Number,
      "default": 0
    },
    formatclass: {
      type: String,
      "default": "black--text" // this isn't right, dependent upon darkmode
    },
    decimals: {
      type: Number,
      "default": 2
    },
    currencytoggle: {
      type: Number,
      "default": 0
    },
    dateidx: {
      type: Number,
      "default": 0
    },
    dateheaders: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    reservedates: {
      type: Boolean,
      "default": false
    },
    period: {
      type: String,
      "default": "annual"
    },
    dateobj: {
      type: Object,
      "default": function _default() {
        var obj = {};
        return obj;
      }
    },
    guidanceData: {
      type: Boolean,
      "default": false
    }
  },
  setup: function setup(props) {
    var _useBaseUtils = useBaseUtils(),
      dev = _useBaseUtils.dev,
      refCode = _useBaseUtils.refCode,
      specialUser = _useBaseUtils.specialUser; // in previous est table this is a computed property but now you've made it a method
    // thinking about the best way to do this
    var createNumberFormatter = function createNumberFormatter(decimals) {
      var lang = navigator.language || "default";
      return new Intl.NumberFormat(lang, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
      });
    };
    var formatNumber = computed(function () {
      return createNumberFormatter(props.decimals);
    });
    var print = function print(result) {
      return "".concat(result > 0 ? "".concat(formatNumber.value.format(result)) : "(".concat(formatNumber.value.format(Math.abs(result)), ")"));
    };
    var createPercentFormatter = function createPercentFormatter(pctDecimals) {
      var lang = navigator.language || "default";
      return new Intl.NumberFormat(lang, {
        style: "percent",
        minimumFractionDigits: pctDecimals,
        maximumFractionDigits: pctDecimals
      });
    };
    var formatPercent = createPercentFormatter(1);
    var actualMetricToHide = computed(function () {
      if (!props.dateobj.isEstimate) {
        var lengthMinusIdx = props.dateobj.lengthMinusIdx;
        if (props.period === "annual" || props.period === "a") {
          return lengthMinusIdx > 10;
        } else if (props.period === "quarter" || props.period === "q") {
          return lengthMinusIdx > 40;
        } else if (props.period === "semiAnnual" || props.period === "semi") {
          return lengthMinusIdx > 20;
        } else if (props.period === "cy") {
          return lengthMinusIdx > 10;
        } else if (props.period === "ntm") {
          return lengthMinusIdx > 40;
        } else {
          return true;
        }
      }
      return false;
    });
    return {
      dev: dev,
      print: print,
      formatPercent: formatPercent,
      refCode: refCode,
      specialUser: specialUser,
      actualMetricToHide: actualMetricToHide
    };
  },
  computed: {
    cellValue: function cellValue() {
      var dataObj = this.dataObj;
      var lineitem = this.lineitem;
      var desiredUnits = this.desiredunits;
      var currencyToggle = this.currencytoggle;
      if (dataObj.unauth) {
        return "upgrade";
      }
      if (!dataObj.dataitemvalue) {
        return "";
      }
      var exrate = parseFloat(dataObj.priceclose) || 1; // makes sure items w/o currency still toggle
      var result = parseFloat(dataObj.dataitemvalue);
      var unittypeid = dataObj.estimatescaleid; // estimatescaleid is a number
      if (!exrate && lineitem.name.includes("%")) {
        // I'm noticing a pattern - if there is no exchange rate them most likely
        // the number is a percentage or some other type of dimensionless number
        // TODO: Should I also format this as a percent as it is dimensionless?
        result = result / 100;
        return "".concat(result > 0 ? "".concat(this.formatPercent.format(result)) : "(".concat(this.formatPercent.format(Math.abs(result)), ")"));
      }
      if ([18, 19, 30, 36].includes(unittypeid)) {
        // Oil and Gas Metric
        // return `${this.print(result)} ${unittypeid}`
        return "".concat(this.print(result));
      } // result = result / exrate // this is where everything is forced to USD
      // if (!dataObj.priceclose && stage === "dev") {
      //   console.log("No exchange rate for lineitem: ", lineitem)
      //   console.log("No exchange rate for object: ", dataObj)
      // }
      if (currencyToggle === 1) {
        result = result / exrate;
      }
      if (unittypeid === 3) {
        // in absolute units
        return this.print(result, dataObj);
      }
      if (unittypeid === 4) {
        // in absolute units
        return this.print(result / 100, dataObj);
      }
      if (unittypeid <= 9) {
        // value is a reported financial number in some currency of either thousands or millions... convert
        // Now convert from reported units (1e3, 1e6) to desired units
        // desiredUnits - 0 thousand, 1 million, 2 billion
        // right now a zero means no conversion
        // and a negative 2 means that you want it a lot bigger than it currently is
        // so transform
        var unitTypeTransform = {
          0: 2,
          // millions,
          1: 3,
          // billions,
          2: 1,
          // thousands,
          3: -1,
          // absolute, TODO: everything below!
          4: -3,
          // 1/100th 1e4 10/1
          5: -4,
          // 1/1000th
          6: -5,
          // 1/10000th
          7: 4,
          // Trillion
          8: -2,
          // 1/10th
          9: 0 // Hundred
        };
        var unitConversion = unitTypeTransform[unittypeid] - (desiredUnits + 1); // results the 10^unitConversion needed
        /**
                 * desired units plus one means..
                 * 1 = thousands
                 * 2 = million
                 * 3 = billion
                 * 4 = Trillion
                 */ // const toThe = Math.pow(1000, unitConversion)
        // result = result * toThe
        // return this.print(result, dataObj)
        switch (unitConversion) {
          case -4:
            // multiply 1e-9     1e(-4 * 3)
            result = result / 1000000000000; // return `${result.toFixed(decimals)}`
            return this.print(result, dataObj);
          case -3:
            // multiply 1e-9     1e(-3 * 3)
            result = result / 1000000000; // return `${result.toFixed(decimals)}`
            return this.print(result, dataObj);
          case -2:
            // multiply 1e-6     1e(-2 * 3)
            result = result / 1000000; // return `${result.toFixed(decimals)}`
            return this.print(result, dataObj);
          case -1:
            // multiply by 1e-3   1e(-1 * 3)
            result = result / 1000;
            return this.print(result, dataObj);
          case 0:
            // 1e(0 * 3)
            // multiply by 1
            return this.print(result, dataObj);
          case 1:
            // 1e(1*3)
            // 1 e3
            result = result * 1000;
            return this.print(result, dataObj);
          case 2:
            // 1e(2*3)
            // 1 e6
            result = result * 1000000;
            return this.print(result, dataObj);
          case 3:
            // 1e(3*3)
            // 1 e9
            result = result * 1000000000;
            return this.print(result, dataObj);
          case 4:
            // 1e(4*3)
            // 1 e12
            result = result * 1000000000000;
            return this.print(result, dataObj);
          default:
            console.log("Converting Units Logic Error: ", unitConversion);
          // ah this is what was 4
          // return this.print(result, dataObj)
        }
      } else {
        console.log("Converting Units Logic Error: ", unittypeid); // ah this is what was 4
      }
      return "";
    },
    dateKey: function dateKey() {
      var _this$dateobj;
      return this === null || this === void 0 || (_this$dateobj = this.dateobj) === null || _this$dateobj === void 0 ? void 0 : _this$dateobj.value;
    },
    noOfEstimates: function noOfEstimates() {
      var _this$dateobj2, _this$lineitem;
      if (this.guidanceData || !(this !== null && this !== void 0 && (_this$dateobj2 = this.dateobj) !== null && _this$dateobj2 !== void 0 && _this$dateobj2.isEstimate) || !(this !== null && this !== void 0 && this.cellValue) || !(this !== null && this !== void 0 && (_this$lineitem = this.lineitem) !== null && _this$lineitem !== void 0 && (_this$lineitem = _this$lineitem.numEst) !== null && _this$lineitem !== void 0 && (_this$lineitem = _this$lineitem[this === null || this === void 0 ? void 0 : this.dateKey]) !== null && _this$lineitem !== void 0 && _this$lineitem.dataitemvalue)) {
        return;
      }
      return parseInt(this.lineitem.numEst[this.dateKey].dataitemvalue, 10);
    },
    diffActualEstimate: function diffActualEstimate() {
      var _this$dateobj3, _this$dateobj4, _this$lineitem2, _this$lineitem3;
      if ((_this$dateobj3 = this.dateobj) !== null && _this$dateobj3 !== void 0 && _this$dateobj3.isEstimate || !((_this$dateobj4 = this.dateobj) !== null && _this$dateobj4 !== void 0 && _this$dateobj4.hasEstimate) || !(this !== null && this !== void 0 && (_this$lineitem2 = this.lineitem) !== null && _this$lineitem2 !== void 0 && (_this$lineitem2 = _this$lineitem2.actual) !== null && _this$lineitem2 !== void 0 && (_this$lineitem2 = _this$lineitem2[this === null || this === void 0 ? void 0 : this.dateKey]) !== null && _this$lineitem2 !== void 0 && _this$lineitem2.dataitemvalue) || !((_this$lineitem3 = this.lineitem) !== null && _this$lineitem3 !== void 0 && (_this$lineitem3 = _this$lineitem3.mean[this.dateKey]) !== null && _this$lineitem3 !== void 0 && _this$lineitem3.dataitemvalue)) {
        return;
      }
      var actual = this.lineitem.actual[this.dateKey].dataitemvalue;
      var mean = this.lineitem.mean[this.dateKey].dataitemvalue;
      return actual - mean;
    }
  },
  methods: {// formatFinancialData(dataObj, desiredUnits) {
    //   /**
    //    * 'data' = {v: 'number stored as string', u: 'unittypeid of value key'} not sure if this is true anymore
    //    * 'unittypeid' = units of 'data'  0: Actual scale they were reported, 1: Thousands, 2: Millions
    //    * only financial numbers should be going through this function, so they should all be numbers stored as strings
    //    * 'exrate' = currencyRate / US Dollar
    //    * 'desiredUnits' = desired units to return 0: thousands, 1: millions, 2: billions
    //    * 'decimals' = # of decimal points to return
    //    */
    //   // if there is no financial data then value will be "", return data passed to function
    //   // const estLabel = dateObj.isEstimate ? "E" : "A"
    //   if (dataObj) {
    //     if (dataObj.unauth) {
    //       return "upgrade"
    //     }
    //     let exrate = parseFloat(dataObj.priceclose) || 1 // makes sure items w/o currency still toggle
    //     let result = parseFloat(dataObj.dataitemvalue)
    //     let unittypeid = dataObj.estimatescaleid //estimatescaleid is a number
    //     // if unittypeid = 0 the number is a % or ratio so currency conversion unit conversion does not apply
    //     // if unittypeid is <= 2 then it is either 0: millions 1: billions 2: thousands 3: absolute
    //     if (!exrate && this.lineitem.name.includes("%")) {
    //       // I'm noticing a pattern - if there is no exchange rate them most likely
    //       // the number is a percentage or some other type of dimensionless number
    //       // TODO: Should I also format this as a percent as it is dimensionless?
    //       result = result / 100
    //       return `${
    //         result > 0
    //           ? `${this.formatPercent.format(result)}`
    //           : `(${this.formatPercent.format(Math.abs(result))})`
    //       }`
    //     }
    //     if ([18, 19, 30, 36].includes(unittypeid)) {
    //       // Oil and Gas Metric
    //       // return `${this.print(result)} ${unittypeid}`
    //       return `${this.print(result)}`
    //     }
    //     // result = result / exrate // this is where everything is forced to USD
    //     // if (!dataObj.priceclose && stage === "dev") {
    //     //   console.log("No exchange rate for lineitem: ", lineitem)
    //     //   console.log("No exchange rate for object: ", dataObj)
    //     // }
    //     if (this.currencyToggle === 1) {
    //       result = result / exrate
    //     }
    //     if (unittypeid === 3) {
    //       // in absolute units
    //       return this.print(result, dataObj)
    //     }
    //     if (unittypeid === 4) {
    //       // in absolute units
    //       return this.print(result / 100, dataObj)
    //     }
    //     if (unittypeid <= 9) {
    //       // value is a reported financial number in some currency of either thousands or millions... convert
    //       // Now convert from reported units (1e3, 1e6) to desired units
    //       // desiredUnits - 0 thousand, 1 million, 2 billion
    //       // right now a zero means no conversion
    //       // and a negative 2 means that you want it a lot bigger than it currently is
    //       // so transform
    //       const unitTypeTransform = {
    //         0: 2, // millions,
    //         1: 3, // billions,
    //         2: 1, // thousands,
    //         3: -1, // absolute, TODO: everything below!
    //         4: -3, // 1/100th 1e4 10/1
    //         5: -4, // 1/1000th
    //         6: -5, // 1/10000th
    //         7: 4, // Trillion
    //         8: -2, // 1/10th
    //         9: 0, // Hundred
    //       }
    //       const unitConversion =
    //         unitTypeTransform[unittypeid] - (desiredUnits + 1) // results the 10^unitConversion needed
    //       /**
    //        * desired units plus one means..
    //        * 1 = thousands
    //        * 2 = million
    //        * 3 = billion
    //        * 4 = Trillion
    //        */
    //       // const toThe = Math.pow(1000, unitConversion)
    //       // result = result * toThe
    //       // return this.print(result, dataObj)
    //       switch (unitConversion) {
    //         case -4:
    //           // multiply 1e-9     1e(-4 * 3)
    //           result = result / 1000000000000
    //           // return `${result.toFixed(decimals)}`
    //           return this.print(result, dataObj)
    //         case -3:
    //           // multiply 1e-9     1e(-3 * 3)
    //           result = result / 1000000000
    //           // return `${result.toFixed(decimals)}`
    //           return this.print(result, dataObj)
    //         case -2:
    //           // multiply 1e-6     1e(-2 * 3)
    //           result = result / 1000000
    //           // return `${result.toFixed(decimals)}`
    //           return this.print(result, dataObj)
    //         case -1:
    //           // multiply by 1e-3   1e(-1 * 3)
    //           result = result / 1000
    //           return this.print(result, dataObj)
    //         case 0: // 1e(0 * 3)
    //           // multiply by 1
    //           return this.print(result, dataObj)
    //         case 1: // 1e(1*3)
    //           // 1 e3
    //           result = result * 1000
    //           return this.print(result, dataObj)
    //         case 2: // 1e(2*3)
    //           // 1 e6
    //           result = result * 1000000
    //           return this.print(result, dataObj)
    //         case 3: // 1e(3*3)
    //           // 1 e9
    //           result = result * 1000000000
    //           return this.print(result, dataObj)
    //         case 4: // 1e(4*3)
    //           // 1 e12
    //           result = result * 1000000000000
    //           return this.print(result, dataObj)
    //         default:
    //
    //           console.log("Converting Units Logic Error: ", unitConversion) // ah this is what was 4
    //         // return this.print(result, dataObj)
    //       }
    //     } else {
    //       console.log("Converting Units Logic Error: ", unittypeid) // ah this is what was 4
    //     }
    //   } else {
    //     return
    //   }
    // },
  }
});