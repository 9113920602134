const divisionCalc = (
  numeratorMetricKey,
  denominatorMetricKey,
  metricDataObj,
  dateArr
) => {
  const numeratorData = metricDataObj[numeratorMetricKey]
  const denominatorData = metricDataObj[denominatorMetricKey]
  const division = dateArr.reduce((acc, date) => {
    const period = date.value
    if (numeratorData[period] && denominatorData[period]) {
      const calc = numeratorData[period].v / denominatorData[period].v
      const res = { v: calc, u: 0 }
      if (numeratorData[period].unauth || denominatorData[period].unauth) {
        res.unauth = true
      }
      acc[period] = res
    }
    return acc
  }, {})
  return division
}

const annualChangeCalc = (metricKey, metricObj, dateArr) => {
  const dateDelimiter = "##"
  const metricDataObj = metricObj[metricKey] || {}

  const pctChangeYoY = dateArr.reduce((acc, dateObj) => {
    const currentDateKey = dateObj.value
    const splitDateKey = currentDateKey.split(dateDelimiter)
    const priorDateKey = `${splitDateKey[0] - 1}${dateDelimiter}${
      splitDateKey[1]
    }`
    const currentMetricObj = metricDataObj[currentDateKey]
    const priorMetricObj = metricDataObj[priorDateKey]
    if (currentMetricObj?.v && priorMetricObj?.v) {
      // perform math, add result to acc[currentDateKey]
      const currentExRate = currentMetricObj?.pc || false
      const lastExRate = priorMetricObj?.pc || false
      const result = calculatePercentChange(
        currentMetricObj,
        priorMetricObj,
        currentExRate,
        lastExRate
      )
      acc[currentDateKey] = result
    }
    return acc
  }, {})
  return pctChangeYoY
}

const calculatePercentChange = (
  currentData,
  lastData,
  currentExRate,
  lastExRate
) => {
  // FIXME: Check the u for each value and normalize?
  const currentValue = parseFloat(currentData.v)
  const lastValue = parseFloat(lastData.v)
  const calc = (currentValue - lastValue) / lastValue
  const truthy = currentValue > lastValue
  const rightCalc = truthy ? Math.abs(calc) : calc
  // double check exchangeRatesWere Present
  let exRightCalc = 0

  // right now I'm worried about the possibilty of no exchange rate on the date
  if (currentExRate && lastExRate) {
    const exCalc =
      (currentValue / currentExRate - lastValue / lastExRate) /
      (lastValue / lastExRate)
    exRightCalc = truthy ? Math.abs(exCalc) : exCalc
  } else {
    exRightCalc = false
  }
  const result = { v: rightCalc, u: 0, vex: exRightCalc } // FIXME: figure out what to put for u...
  if (currentData.unauth || lastData.unauth) {
    result.unauth = true
  }
  return result
}

export { divisionCalc, annualChangeCalc }
