import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.is-finite.js";
import "core-js/modules/es.number.is-nan.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
function _slicedToArray(r, e) {
  return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest();
}
function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
function _iterableToArrayLimit(r, l) {
  var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"];
  if (null != t) {
    var e,
      n,
      i,
      u,
      a = [],
      f = !0,
      o = !1;
    try {
      if (i = (t = t.call(r)).next, 0 === l) {
        if (Object(t) !== t) return;
        f = !1;
      } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0);
    } catch (r) {
      o = !0, n = r;
    } finally {
      try {
        if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return;
      } finally {
        if (o) throw n;
      }
    }
    return a;
  }
}
function _arrayWithHoles(r) {
  if (Array.isArray(r)) return r;
}
import { isObject } from "lodash";
import dayjs from "~/utils/tools/dayjs";
import BmTableCell from "~/components/tables/beatsAndMisses/BmTableCell.vue";
import LockedDataMessage from "~/components/LockedDataMessage.vue";
import useBaseUtils from "~/functions/useBaseUtils";
import useCiqDataSource from "~/functions/useCiqDataSource";
import metrics from "~/utils/constants/metrics";
import UnauthorizedIcon from "~/components/UnauthorizedIcon.vue";
import { findFirstAvailableItemInObject } from "~/utils/tools/object";
import { convertToUsdObject } from "~/utils/values/scale";
export default {
  __name: 'EstimatesBreakdown',
  props: {
    period: {
      type: String,
      "default": "a"
    },
    formatFunction: {
      type: Function,
      "default": function _default(value) {
        return value;
      }
    },
    desiredUnits: {
      type: Number,
      "default": 1
    },
    decimals: {
      type: Number,
      "default": 2
    },
    currencyToggle: {
      type: Number,
      "default": 0
    },
    lockedData: {
      type: Boolean,
      "default": true
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store;
    var _useBaseUtils = useBaseUtils(),
      I18nFn = _useBaseUtils.I18nFn,
      freezeFirstColumn = _useBaseUtils.freezeFirstColumn;
    var tickerSymbol = computed(function () {
      var _$store$state$ciq$tic;
      return (_$store$state$ciq$tic = $store.state.ciq.ticker) === null || _$store$state$ciq$tic === void 0 ? void 0 : _$store$state$ciq$tic.tickersymbol;
    });
    var metricList = [{
      label: "Sales",
      metricKeys: [metrics.revenue],
      formula: "val",
      actualOrEstimateKey: "mean"
    }, {
      label: "EBITDA",
      metricKeys: [metrics.ebitda],
      formula: "val",
      actualOrEstimateKey: "mean"
    }, {
      label: "EBIT",
      metricKeys: [metrics.ebitAct],
      formula: "val",
      actualOrEstimateKey: "mean"
    }, {
      label: "EPS",
      metricKeys: [metrics.epsNormalized],
      formula: "val",
      actualOrEstimateKey: "mean"
    }, {
      label: "EPS GAAP",
      metricKeys: [metrics.epsGaap],
      formula: "val",
      actualOrEstimateKey: "mean"
    }];
    var staticHeaders = [{
      value: "title",
      text: "Period",
      sortable: false
    }, {
      value: "numAnalysts",
      text: "Analysts",
      sortable: false,
      align: "right",
      meta: {
        "class": "text-right"
      }
    }, {
      value: "avg",
      text: "Average",
      sortable: false,
      align: "right",
      meta: {
        "class": "text-right"
      }
    }, {
      value: "median",
      text: "Median",
      sortable: false,
      align: "right",
      meta: {
        "class": "text-right"
      }
    }, {
      value: "yoyChange",
      text: "YoY Chg %",
      cellFormat: "pct",
      sortable: false,
      toggleColor: true,
      align: "right",
      meta: {
        "class": "text-right"
      }
    }, {
      value: "qoqChange",
      text: "QoQ Chg %",
      cellFormat: "pct",
      sortable: false,
      toggleColor: true,
      allowedPeriods: ["q"],
      align: "right",
      meta: {
        "class": "text-right"
      }
    }, {
      value: "evMultiple",
      text: "EV Multiple (Avg)",
      cellFormat: "turns",
      sortable: false,
      allowedPeriods: ["a"],
      align: "right",
      meta: {
        "class": "text-right"
      }
    }, {
      value: "priceMultiple",
      text: "Price Multiple (Avg)",
      cellFormat: "turns",
      sortable: false,
      allowedPeriods: ["a"],
      align: "right",
      meta: {
        "class": "text-right"
      }
    }, {
      value: "evMultipleMedian",
      text: "EV Multiple (Median)",
      cellFormat: "turns",
      sortable: false,
      allowedPeriods: ["a"],
      align: "right",
      meta: {
        "class": "text-right"
      }
    }, {
      value: "priceMultipleMedian",
      text: "Price Multiple (Median)",
      cellFormat: "turns",
      sortable: false,
      allowedPeriods: ["a"],
      align: "right",
      meta: {
        "class": "text-right"
      }
    }];
    var headers = computed(function () {
      return staticHeaders.filter(function (headerObj) {
        var _headerObj$allowedPer;
        var isValidHeader = headerObj !== null && headerObj !== void 0 && (_headerObj$allowedPer = headerObj.allowedPeriods) !== null && _headerObj$allowedPer !== void 0 && _headerObj$allowedPer.length ? headerObj === null || headerObj === void 0 ? void 0 : headerObj.allowedPeriods.includes(props.period) : true;
        return isValidHeader;
      });
    });
    var loading = computed(function () {
      var _$store$state$ciq$est;
      var fetchingValuation = $store.state.ciq.fetchingValuation;
      var hasNoPeriods = !((_$store$state$ciq$est = $store.state.ciq.estimates) !== null && _$store$state$ciq$est !== void 0 && (_$store$state$ciq$est = _$store$state$ciq$est[props.period]) !== null && _$store$state$ciq$est !== void 0 && _$store$state$ciq$est.lastTenPeriods.length);
      return fetchingValuation || hasNoPeriods;
    });
    var getPeriodStr = function getPeriodStr(periodStr, period) {
      if (!periodStr) {
        return "";
      }
      if (period === "a") {
        var _periodStr$split = periodStr.split("##"),
          _periodStr$split2 = _slicedToArray(_periodStr$split, 1),
          year = _periodStr$split2[0];
        return year[2] + year[3];
      }
      return periodStr;
    };
    var getUnitId = function getUnitId(firstCell) {
      var arrayOfScaleIds = ["estimatescaleid", "u"];
      var itemToIterate = Array.isArray(firstCell) ? firstCell[0] : firstCell;
      return findFirstAvailableItemInObject({
        item: itemToIterate,
        keys: arrayOfScaleIds,
        defaultEmpty: NaN
      });
    };
    var getPriceClose = function getPriceClose(firstCell) {
      var keys = ["priceclose", "pc"];
      var itemToIterate = Array.isArray(firstCell) ? firstCell[0] : firstCell;
      return Number(findFirstAvailableItemInObject({
        item: itemToIterate,
        keys: keys,
        defaultEmpty: 1
      }));
    };
    var getIso = function getIso(firstCell) {
      return findFirstAvailableItemInObject({
        item: firstCell,
        keys: ["iso", "isocode"],
        defaultEmpty: "USD"
      });
    };
    var getRowProps = function getRowProps() {
      var cellProps = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var actualOrEstimateKey = arguments.length > 1 ? arguments[1] : undefined;
      var format = arguments.length > 2 ? arguments[2] : undefined;
      var props = _objectSpread({}, cellProps);
      if (actualOrEstimateKey) {
        props.actualOrEstimateKey = actualOrEstimateKey;
      }
      var rowData = useCiqDataSource(props);
      var firstCell = rowData.firstCell.value;
      var unitId = getUnitId(firstCell);
      var priceClose = getPriceClose(firstCell);
      var iso = getIso(firstCell);
      var data = {
        unauth: rowData.isAnyCellUnauth.value,
        value: rowData.cellValue.value,
        unitId: unitId,
        priceClose: priceClose,
        iso: iso
      };
      if (format) {
        data.format = format;
      }
      return data;
    };
    var getNumAnalysts = function getNumAnalysts() {
      var cellProps = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return getRowProps(cellProps, "numEst");
    };
    var getMedianData = function getMedianData() {
      var cellProps = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return getRowProps(cellProps, "median");
    };
    var getAvgData = function getAvgData() {
      var cellProps = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return getRowProps(cellProps, "mean");
    };
    var getYoYChange = function getYoYChange() {
      var cellProps = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var index = arguments.length > 1 ? arguments[1] : undefined;
      var rowProps = _objectSpread(_objectSpread({}, cellProps), {}, {
        formula: "YoY"
      }); // first index compares with an
      // actual instead of an estimate
      if (index === 0) {
        var firstIndexProps = _objectSpread(_objectSpread({}, rowProps), {}, {
          formula: "val"
        });
        var actual = getRowProps(_objectSpread(_objectSpread({}, firstIndexProps), {}, {
          relativeDateKey: 0,
          relativeQuarterKey: -3
        }), "actual");
        var estimate = getRowProps(firstIndexProps, "mean");
        var value = estimate.value / actual.value - 1;
        return {
          value: value,
          unauth: estimate.unauth || actual.unauth,
          unitId: estimate.unitId || actual.unitId,
          format: "pct"
        };
      }
      var from;
      if (rowProps.periodKey === "q") {
        from = getRowProps(_objectSpread(_objectSpread({}, rowProps), {}, {
          formula: "val",
          relativeDateKey: Number.isFinite(rowProps.relativeDateKey) ? rowProps.relativeDateKey - 4 : 0,
          relativeQuarterKey: Number.isFinite(rowProps.relativeQuarterKey) ? rowProps.relativeQuarterKey - 4 : 0
        }), index > 3 ? "mean" : "actual");
      } else {
        from = getRowProps(_objectSpread(_objectSpread({}, rowProps), {}, {
          relativeDateKey: rowProps.relativeDateKey - 1,
          formula: "val"
        }), "mean");
      }
      var to = getRowProps(_objectSpread(_objectSpread({}, rowProps), {}, {
        formula: "val"
      }), "mean");
      var result = to.value / from.value - 1;
      return {
        value: result,
        unauth: to.unauth || from.unauth,
        unitId: to.unitId || from.unitId,
        format: "pct"
      };
    };
    var getQoQChange = function getQoQChange() {
      var cellProps = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var index = arguments.length > 1 ? arguments[1] : undefined;
      var rowProps = _objectSpread(_objectSpread({}, cellProps), {}, {
        formula: "QoQ"
      }); // first index compares with an
      // actual instead of an estimate
      if (index === 0) {
        var firstIndexProps = _objectSpread(_objectSpread({}, rowProps), {}, {
          formula: "val"
        });
        var actual = getRowProps(_objectSpread(_objectSpread({}, firstIndexProps), {}, {
          relativeDateKey: 0,
          relativeQuarterKey: 0
        }), "actual");
        var estimate = getRowProps(firstIndexProps, "mean");
        var value = estimate.value / actual.value - 1;
        return {
          value: value,
          unauth: estimate.unauth || actual.unauth,
          unitId: estimate.unitId || actual.unitId,
          format: "pct"
        };
      }
      return getRowProps(rowProps, "mean", "pct");
    };
    var invalidEvMultipleRows = ["EPS", "EPS GAAP"];
    var getEvMultiple = function getEvMultiple() {
      var cellProps = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var tevDataObj = arguments.length > 1 ? arguments[1] : undefined;
      var cellDataAvg = getRowProps(cellProps, "mean");
      var isValid = !invalidEvMultipleRows.includes(cellProps.label);
      var unauth = tevDataObj.isAnyCellUnauth.value || cellDataAvg.unauth;
      var unitIdTev = tevDataObj.unitId;
      var unitIdCell = cellDataAvg.unitId;
      var unitId = unitIdCell !== null && unitIdCell !== void 0 ? unitIdCell : unitIdTev;
      var tevFirstCell = tevDataObj.firstCell.value;
      var isSameIso = cellDataAvg.iso === getIso(tevFirstCell);
      var value = tevDataObj.cellValue.value / cellDataAvg.value;
      if (!isSameIso) {
        var normalizedTev = convertToUsdObject(tevFirstCell, tevDataObj.cellValue.value || 1);
        var normalizedCellData = convertToUsdObject(cellDataAvg, cellDataAvg.value || 1);
        value = normalizedTev.v / normalizedCellData.value;
      }
      if (!isValid) {
        return "-";
      }
      return {
        unauth: unauth,
        value: value,
        unitId: unitId,
        format: "turns"
      };
    };
    var getEvMultipleMedian = function getEvMultipleMedian() {
      var cellProps = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var tevDataObj = arguments.length > 1 ? arguments[1] : undefined;
      var isValid = !invalidEvMultipleRows.includes(cellProps.label);
      if (!isValid) {
        return "-";
      }
      var cellDataMedian = getRowProps(cellProps, "median");
      var unauth = tevDataObj.isAnyCellUnauth.value || cellDataMedian.unauth;
      var unitId = cellDataMedian.unitId || getUnitId(tevDataObj.firstCell.value);
      var tevFirstCell = tevDataObj.firstCell.value;
      var isSameIso = cellDataMedian.iso === getIso(tevFirstCell);
      var value = tevDataObj.cellValue.value / cellDataMedian.value;
      if (!isSameIso) {
        var normalizedTev = convertToUsdObject(tevFirstCell, tevDataObj.cellValue.value || 1);
        var normalizedCellData = convertToUsdObject(cellDataMedian, cellDataMedian.value || 1);
        value = normalizedTev.v / normalizedCellData.value;
      }
      return {
        value: value,
        unauth: unauth,
        unitId: unitId,
        format: "turns"
      };
    };
    var invalidPriceMultipleRows = ["Sales", "EBITDA", "EBIT"];
    var getPriceMultiple = function getPriceMultiple() {
      var cellProps = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var priceCloseData = arguments.length > 1 ? arguments[1] : undefined;
      var isValid = !invalidPriceMultipleRows.includes(cellProps.label);
      if (!isValid) {
        return "-";
      }
      var cellData = getRowProps(cellProps, "mean");
      var priceCloseFirstCell = priceCloseData.firstCell.value;
      var unitId = cellData.unitId || getUnitId(priceCloseFirstCell);
      var cellValue = cellData.value;
      var priceCloseValue = priceCloseData.cellValue.value;
      var isSameIso = cellData.iso === getIso(priceCloseFirstCell);
      var value = priceCloseValue / cellValue;
      if (!isSameIso) {
        var normalizedCellData = convertToUsdObject(cellData, cellValue || 1);
        var normalizedPriceClose = convertToUsdObject(priceCloseFirstCell, priceCloseValue || 1);
        value = normalizedPriceClose.v / normalizedCellData.value;
      }
      return {
        unauth: cellData.unauth || priceCloseData.isAnyCellUnauth.value,
        value: value,
        unitId: unitId,
        format: "turns"
      };
    };
    var getPriceMultipleMedian = function getPriceMultipleMedian() {
      var cellProps = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var priceCloseData = arguments.length > 1 ? arguments[1] : undefined;
      var isValid = !invalidPriceMultipleRows.includes(cellProps.label);
      if (!isValid) {
        return "-";
      }
      var cellData = getRowProps(cellProps, "median");
      var priceCloseFirstCell = priceCloseData.firstCell.value;
      var unitId = cellData.unitId || getUnitId(priceCloseFirstCell);
      var isSameIso = cellData.iso === getIso(priceCloseFirstCell);
      var cellValue = cellData.value;
      var priceCloseValue = priceCloseData.cellValue.value;
      var value = priceCloseValue / cellValue;
      if (!isSameIso) {
        var normalizedCellData = convertToUsdObject(cellData, cellValue || 1);
        var normalizedPriceClose = convertToUsdObject(priceCloseFirstCell, priceCloseValue || 1);
        value = normalizedPriceClose.v / normalizedCellData.value;
      }
      return {
        unauth: cellData.unauth || priceCloseData.isAnyCellUnauth.value,
        value: value,
        unitId: unitId,
        format: "turns"
      };
    };
    var addHideRowValuesProp = function addHideRowValuesProp(obj, actualValue) {
      Object.keys(obj).forEach(function (key) {
        if (_typeof(obj[key]) === "object" && obj[key] !== null) {
          obj[key].hideRowValues = actualValue;
        }
      });
    };
    var getFiveYearBeyondDataArray = function getFiveYearBeyondDataArray(metricObj, period) {
      var arr = [];
      var tevData = useCiqDataSource({
        metricKeys: [metrics.tev],
        formula: "val",
        relativeDateKey: 0,
        periodKey: "a",
        formattedValue: false
      });
      var lastClosePrice = useCiqDataSource({
        metricKeys: [metrics.stockPriceClose],
        formula: "val",
        formatType: "currency",
        periodKey: "a",
        formattedValue: false
      });
      var isCellEmpty = function isCellEmpty(cellValue) {
        return cellValue === "" || Number.isNaN(cellValue);
      };
      var cells = headers.value.map(function (item) {
        return item.value;
      });
      for (var index = 0; index < 5; index++) {
        var relativeDateKey = index + 1;
        var key = period === "a" ? "relativeDateKey" : "relativeQuarterKey";
        var currentCellProps = _objectSpread(_objectSpread({}, metricObj), {}, _defineProperty(_defineProperty(_defineProperty({}, key, relativeDateKey), "periodKey", period), "formattedValue", false));
        var _useCiqDataSource = useCiqDataSource(currentCellProps),
          firstCell = _useCiqDataSource.firstCell;
        var periodYear = getPeriodStr(firstCell.value._fiscalYear, period);
        var row = {
          period: periodYear
        };
        if (cells.includes("numAnalysts")) {
          var numAnalystsData = getNumAnalysts(currentCellProps);
          row.numAnalysts = numAnalystsData;
        }
        if (cells.includes("avg")) {
          var avgData = getAvgData(currentCellProps);
          row.avg = avgData;
        }
        if (cells.includes("median")) {
          var medianData = getMedianData(currentCellProps);
          row.median = medianData;
        }
        if (cells.includes("yoyChange")) {
          var yoyChangeData = getYoYChange(currentCellProps, index);
          row.yoyChange = yoyChangeData;
        }
        if (cells.includes("qoqChange")) {
          var qoqChangeData = getQoQChange(currentCellProps, index);
          row.qoqChange = qoqChangeData;
        }
        if (cells.includes("evMultiple")) {
          var evMultiple = getEvMultiple(currentCellProps, tevData);
          row.evMultiple = evMultiple;
        }
        if (cells.includes("priceMultiple")) {
          var priceMultiple = getPriceMultiple(currentCellProps, lastClosePrice);
          row.priceMultiple = priceMultiple;
        }
        if (cells.includes("evMultipleMedian")) {
          var evMultipleMedian = getEvMultipleMedian(currentCellProps, tevData);
          row.evMultipleMedian = evMultipleMedian;
        }
        if (cells.includes("priceMultipleMedian")) {
          var priceMultipleMedian = getPriceMultipleMedian(currentCellProps, lastClosePrice);
          row.priceMultipleMedian = priceMultipleMedian;
        }
        var isNumAnalystsCellEmpty = isCellEmpty(row.numAnalysts.value);
        var isAvgCellEmpty = isCellEmpty(row.avg.value);
        var isMedianCellEmpty = isCellEmpty(row.median.value);
        var hideRowValues = [isNumAnalystsCellEmpty, isAvgCellEmpty, isMedianCellEmpty].every(function (value) {
          return value === true;
        });
        addHideRowValuesProp(row, hideRowValues);
        arr.push(_objectSpread(_objectSpread({}, row), {}, {
          hideRowValues: hideRowValues
        }));
      }
      return arr;
    };
    var getTitle = function getTitle(period, dataObj, index, fivePeriodsForward) {
      if (period === "a") {
        return "12/31/".concat(dataObj.period, " E");
      }
      var quarterItem = fivePeriodsForward[index];
      if (quarterItem !== null && quarterItem !== void 0 && quarterItem.periodenddate) {
        return dayjs.utc(quarterItem.periodenddate).format("M/D/YY");
      }
      return "";
    };
    var data = computed(function () {
      return metricList.reduce(function (acc, item, idx) {
        var _$store$state$ciq$est2;
        var category = item.label;
        var fiveYearsOfDataArr = getFiveYearBeyondDataArray(item, props.period);
        var hideMetric = fiveYearsOfDataArr.every(function (value) {
          return value.hideRowValues === true;
        });
        var fivePeriodsForward = ((_$store$state$ciq$est2 = $store.state.ciq.estimates) === null || _$store$state$ciq$est2 === void 0 || (_$store$state$ciq$est2 = _$store$state$ciq$est2[props.period]) === null || _$store$state$ciq$est2 === void 0 ? void 0 : _$store$state$ciq$est2.fivePeriodsForward) || [];
        fiveYearsOfDataArr.forEach(function (dataObj, index) {
          var title = getTitle(props.period, dataObj, index, fivePeriodsForward);
          if (!hideMetric) {
            var row = {};
            Object.keys(dataObj).filter(function (key) {
              return !["hideRowValues", "period"].includes(key);
            }).forEach(function (key) {
              var obj = dataObj[key];
              row[key] = obj;
            });
            acc.push(_objectSpread(_objectSpread({
              title: title,
              category: category
            }, row), {}, {
              position: idx,
              meta: {
                key: "".concat(category, "_row_").concat(dataObj.period || Math.random()),
                hideRowValues: dataObj.hideRowValues,
                hide: dataObj.hideRowValues
              }
            }));
          }
        });
        return acc;
      }, []);
    });
    var unusedProps = ["category", "meta", "position"];
    var getFilteredRow = function getFilteredRow(row, isLockedData) {
      if (isLockedData) {
        return Object.keys(row).filter(function (i) {
          return i === "title";
        });
      }
      return Object.keys(row).filter(function (i) {
        return !unusedProps.includes(i);
      });
    };
    var getColSpan = function getColSpan(headerList) {
      return Object.keys(headerList).filter(function (key) {
        return !unusedProps.includes(key);
      }).length;
    };
    var getRowSpan = function getRowSpan(data, items) {
      return data.length + items.length;
    };
    var getCellClasses = function getCellClasses(field, item, headersArr) {
      var _item$meta, _header$meta;
      var header = headersArr.find(function (item) {
        return item.value === field;
      });
      var value = item[field].value;
      var unauth = item[field].unauth;
      var itemClass = (item === null || item === void 0 || (_item$meta = item.meta) === null || _item$meta === void 0 ? void 0 : _item$meta["class"]) || "";
      var headerClass = (header === null || header === void 0 || (_header$meta = header.meta) === null || _header$meta === void 0 ? void 0 : _header$meta["class"]) || "";
      var classList = "".concat(itemClass, " ").concat(headerClass, " ");
      if (unauth) {
        return classList;
      }
      if (header !== null && header !== void 0 && header.toggleColor) {
        var newClass = value < 0 ? "red--text text--darken-1" : "green--text text--darken-2";
        classList = "".concat(classList, " ").concat(newClass);
      }
      return classList;
    };
    var getCustomStr = function getCustomStr(itemObj, fieldName) {
      if (fieldName === "numAnalysts") {
        return itemObj[fieldName].value.toString();
      }
      return "";
    };
    var shouldDisplayLockedDataMsg = function shouldDisplayLockedDataMsg(catName, isLockedData) {
      return isLockedData && catName === "Sales";
    };
    return {
      __sfc: true,
      props: props,
      $store: $store,
      I18nFn: I18nFn,
      freezeFirstColumn: freezeFirstColumn,
      tickerSymbol: tickerSymbol,
      metricList: metricList,
      staticHeaders: staticHeaders,
      headers: headers,
      loading: loading,
      getPeriodStr: getPeriodStr,
      getUnitId: getUnitId,
      getPriceClose: getPriceClose,
      getIso: getIso,
      getRowProps: getRowProps,
      getNumAnalysts: getNumAnalysts,
      getMedianData: getMedianData,
      getAvgData: getAvgData,
      getYoYChange: getYoYChange,
      getQoQChange: getQoQChange,
      invalidEvMultipleRows: invalidEvMultipleRows,
      getEvMultiple: getEvMultiple,
      getEvMultipleMedian: getEvMultipleMedian,
      invalidPriceMultipleRows: invalidPriceMultipleRows,
      getPriceMultiple: getPriceMultiple,
      getPriceMultipleMedian: getPriceMultipleMedian,
      addHideRowValuesProp: addHideRowValuesProp,
      getFiveYearBeyondDataArray: getFiveYearBeyondDataArray,
      getTitle: getTitle,
      data: data,
      unusedProps: unusedProps,
      getFilteredRow: getFilteredRow,
      getColSpan: getColSpan,
      getRowSpan: getRowSpan,
      getCellClasses: getCellClasses,
      getCustomStr: getCustomStr,
      shouldDisplayLockedDataMsg: shouldDisplayLockedDataMsg,
      isObject: isObject,
      BmTableCell: BmTableCell,
      LockedDataMessage: LockedDataMessage,
      UnauthorizedIcon: UnauthorizedIcon
    };
  }
};