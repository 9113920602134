import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import useCiqTableOptions from "~/functions/useCiqTableOptions";
import useBaseUtils from "~/functions/useBaseUtils";
import { getRowsAndTickersSelected } from "~/functions/common";
import { createCurrencyFormatter } from "~/utils/valuationTransform/peModel";
import { getChartOptionsByType } from "~/utils/ciq/charts";
export default defineComponent({
  props: {
    dataseries: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    dateheaders: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    columnunits: {
      type: String,
      "default": "MM"
    },
    columnisocode: {
      type: String,
      "default": "USD"
    },
    columndecimals: {
      type: Number,
      "default": 1
    },
    rightylabel: {
      type: String,
      "default": "%"
    },
    showchartlabels: {
      type: Boolean,
      "default": false
    },
    charttitle: {
      type: String,
      "default": ""
    },
    period: {
      type: String,
      "default": "a"
    }
  },
  setup: function setup(props) {
    var _useCiqTableOptions = useCiqTableOptions(),
      reverseDates = _useCiqTableOptions.reverseDates;
    var _useBaseUtils = useBaseUtils(),
      darkActive = _useBaseUtils.darkActive;
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store;
    var chartDescription = computed(function () {
      var _getRowsAndTickersSel = getRowsAndTickersSelected({
          $store: $store,
          chartType: "financials",
          period: props.period
        }),
        rows = _getRowsAndTickersSel.rows,
        tickers = _getRowsAndTickersSel.tickers; // en-GB has no oxford comma at the end
      var list = new Intl.ListFormat("en-GB");
      return "".concat(list.format(tickers), "'s ").concat(list.format(rows));
    });
    return {
      chartDescription: chartDescription,
      reverseDates: reverseDates,
      darkActive: darkActive
    };
  },
  data: function data() {
    return {
      fullscreen: false,
      chartHeight: "400"
    };
  },
  computed: {
    tikrChartBaseOptions: function tikrChartBaseOptions() {
      return getChartOptionsByType({
        fullscreen: this.fullscreen,
        darkActive: this.darkActive,
        $vuetify: this.$vuetify,
        chartHeight: this.chartHeight,
        chartTitle: this.charttitle,
        chartColor: this.$store.state.chartColor,
        chartDescription: this.chartDescription,
        dateHeaders: this.dateheaders.length ? this.dateheaders : [],
        onlyPercent: false,
        onlyPriceFactor: false,
        formatters: {
          formatCurrency: this.formatCurrency,
          formatNumber: this.formatNumber,
          formatPercent: this.formatPercent,
          formatDate: this.formatDate
        },
        decimals: this.columndecimals,
        showChartLabels: this.showchartlabels,
        seriesDataGrouping: true,
        amountOfZeroIdxItems: this.dataseries.filter(function (item) {
          return item.xAxis === 0;
        }).length,
        onlyIdxFour: this.dataseries.reduce(function (acc, i) {
          if (i.yAxis === 4) {
            return false;
          } else {
            return acc;
          }
        }, true),
        rightyLabel: this.rightylabel
      });
    },
    tikrOptions: function tikrOptions() {
      var series = this.dataseries;
      var defaultTikrOptions = this.tikrChartBaseOptions;
      return Object.assign({}, defaultTikrOptions, {
        series: series
      });
    },
    availableSeries: function availableSeries() {
      return [{}, {}];
    },
    chartColor: function chartColor() {
      return this.$store.state.chartColor;
    },
    darkMode: function darkMode() {
      return this.$vuetify.theme.dark;
    },
    formatDate: function formatDate() {
      var lang = navigator.language || "default";
      return new Intl.DateTimeFormat(lang, {
        timeZone: "UTC",
        year: "2-digit",
        month: "numeric",
        day: "numeric"
      });
    },
    formatNumber: function formatNumber() {
      var lang = navigator.language || "default";
      var decimals = this.columndecimals;
      return new Intl.NumberFormat(lang, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
      });
    },
    formatPercent: function formatPercent() {
      var lang = navigator.language || "default";
      var decimals = this.columndecimals;
      return new Intl.NumberFormat(lang, {
        style: "percent",
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
      });
    }
  },
  created: function created() {
    var _this = this;
    document.addEventListener("fullscreenchange", function () {
      _this.fullscreen = Boolean(document.fullscreenElement);
    });
  },
  methods: {
    formatCurrency: function formatCurrency(isoStr) {
      var lang = navigator.language || "default";
      return createCurrencyFormatter({
        minDigits: 2,
        maxDigits: 2,
        isocode: isoStr,
        lang: lang
      });
    }
  }
});