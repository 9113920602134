import "core-js/modules/es.number.constructor.js";
import useNumberFormatters from "~/functions/useNumberFormatters";
import { findFirstAvailableItemInObject } from "~/utils/tools/object";
export default {
  __name: 'ValuationExtraTblCell',
  props: {
    currentIso: {
      type: String,
      "default": "USD"
    },
    dataObj: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    decimals: {
      type: Number,
      "default": 2
    },
    desiredunits: {
      type: Number,
      "default": 0
    },
    formatClass: {
      type: String,
      "default": "black--text"
    },
    lineitem: {
      type: Object,
      "default": function _default() {
        return {};
      }
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var _useNumberFormatters = useNumberFormatters(),
      dynamicTwoDigitCurrencyFormatter = _useNumberFormatters.dynamicTwoDigitCurrencyFormatter;
    var createNumberFormatter = function createNumberFormatter(decimals) {
      var lang = navigator.language || "default";
      return new Intl.NumberFormat(lang, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
      });
    };
    var formatNumber = computed(function () {
      return createNumberFormatter(props.decimals);
    });
    var currencyFormatter = computed(function () {
      return dynamicTwoDigitCurrencyFormatter(props.currentIso);
    });
    var scale = function scale(value) {
      if (props.desiredunits === 2) {
        // convert from millions to billions
        return value / 1000;
      }
      if (props.desiredunits === 0) {
        // convert from millions to thousands
        return value * 1000;
      }
      return value;
    };
    var print = function print(result, format) {
      var isCurrency = format === "currency";
      var numberFormatter = isCurrency ? currencyFormatter.value : formatNumber.value;
      return "".concat(result > 0 ? numberFormatter.format(result) : "(".concat(numberFormatter.format(Math.abs(result)), ")"));
    };
    var cellValue = computed(function () {
      var dataObj = props.dataObj;
      var isCurrency = props.lineitem.format === "currency";
      var actualValue = findFirstAvailableItemInObject({
        item: dataObj,
        keys: ["dataitemvalue", "v"],
        defaultEmpty: null
      });
      if (dataObj !== null && dataObj !== void 0 && dataObj.unauth) {
        return "upgrade";
      }
      if (!actualValue) {
        return "";
      }
      var priceClose = findFirstAvailableItemInObject({
        item: dataObj,
        keys: ["priceclose", "pc"],
        defaultEmpty: 1
      });
      var exrate = parseFloat(priceClose);
      var result = parseFloat(actualValue);
      if (props.currentIso === "USD" && dataObj.iso !== "USD") {
        result = result / exrate;
      }
      var scaled = isCurrency ? result : scale(result);
      return print(scaled, props.lineitem.format);
    });
    return {
      __sfc: true,
      props: props,
      dynamicTwoDigitCurrencyFormatter: dynamicTwoDigitCurrencyFormatter,
      createNumberFormatter: createNumberFormatter,
      formatNumber: formatNumber,
      currencyFormatter: currencyFormatter,
      scale: scale,
      print: print,
      cellValue: cellValue
    };
  }
};