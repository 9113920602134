import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.flat.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.unscopables.flat.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.ends-with.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function _slicedToArray(r, e) {
  return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest();
}
function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _iterableToArrayLimit(r, l) {
  var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"];
  if (null != t) {
    var e,
      n,
      i,
      u,
      a = [],
      f = !0,
      o = !1;
    try {
      if (i = (t = t.call(r)).next, 0 === l) {
        if (Object(t) !== t) return;
        f = !1;
      } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0);
    } catch (r) {
      o = !0, n = r;
    } finally {
      try {
        if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return;
      } finally {
        if (o) throw n;
      }
    }
    return a;
  }
}
function _arrayWithHoles(r) {
  if (Array.isArray(r)) return r;
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
function _toConsumableArray(r) {
  return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread();
}
function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _iterableToArray(r) {
  if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r);
}
function _arrayWithoutHoles(r) {
  if (Array.isArray(r)) return _arrayLikeToArray(r);
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
import TableChart from "~/components/charts/TableChart.vue";
import algoliaSearch from "~/mixins/algoliaSearch";
import multiChartUtils from "~/mixins/multiChartUtils";
import estTableToChart from "~/mixins/estTableToChart";
import useCiqFinTableInChart from "~/functions/useCiqFinTableInChart";
import useCiqTableOptions from "~/functions/useCiqTableOptions";
import useBaseUtils from "~/functions/useBaseUtils";
export default defineComponent({
  components: {
    TableChart: TableChart
  },
  mixins: [estTableToChart, algoliaSearch(), multiChartUtils],
  props: {
    selectedrows: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    dateheaders: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    columnunits: {
      type: String,
      "default": "MM"
    },
    columndecimals: {
      type: Number,
      "default": 1
    },
    desiredunits: {
      type: Number,
      "default": 1
    },
    charttype: {
      type: String,
      "default": "Financials"
    },
    columnisocode: {
      type: String,
      "default": "USD"
    },
    period: {
      type: String,
      "default": "a"
    },
    charttitle: {
      type: String,
      "default": ""
    },
    chartsymbol: {
      type: String,
      "default": ""
    },
    dataSource: {
      type: String,
      "default": "ciq"
    },
    extraRows: {
      type: Object,
      "default": function _default() {
        return {};
      }
    }
  },
  setup: function setup(props) {
    var _useBaseUtils = useBaseUtils(),
      I18nFn = _useBaseUtils.I18nFn;
    var _useCiqFinTableInChar = useCiqFinTableInChart(),
      transformFinRowToHighchart = _useCiqFinTableInChar.transformFinRowToHighchart;
    var _useCiqTableOptions = useCiqTableOptions({
        storeModule: "ciq",
        tableType: props.charttype.toLowerCase()
      }),
      formatNumberToDecimals = _useCiqTableOptions.formatNumberToDecimals,
      currencyToggle = _useCiqTableOptions.currencyToggle;
    var getUSDValue = function getUSDValue(dataArr, priceCloseArr) {
      return dataArr.map(function (item, idx) {
        var clone = _toConsumableArray(item);
        clone[1] = clone[1] / priceCloseArr[idx][0];
        return clone;
      });
    };
    return {
      getUSDValue: getUSDValue,
      transformFinRowToHighchart: transformFinRowToHighchart,
      formatNumberToDecimals: formatNumberToDecimals,
      currencyToggle: currencyToggle,
      I18nFn: I18nFn
    };
  },
  data: function data() {
    return {
      pointTypes: [{
        text: "column"
      }, {
        text: "line"
      }],
      hideSelf: false
    };
  },
  computed: {
    allselectedrows: function allselectedrows() {
      var _this = this;
      var series = [];
      var additionalTickersForChart = this.addTickersForChart;
      var additionalTids = Object.keys(additionalTickersForChart);
      var selectedRows = this.selectedrows || [];
      var rowTypes = selectedRows.map(function (item) {
        return item.type;
      }); // FIXME: Change this to a collection of companies / tid's the user has specifically
      // added for this company... just trying to get it working right?
      if (additionalTids.length === 0) {
        selectedRows.forEach(function (row) {
          var isPriceFactor = Object.keys(_this.extraRows).find(function (key) {
            return row.id.endsWith(key);
          });
          if (isPriceFactor) {
            var _this$dateheaders$, _this$dateheaders;
            var itemId = Object.keys(_this.extraRows).find(function (key) {
              return row.id.endsWith(key);
            });
            var item = _this.extraRows[itemId];
            var firstDate = (_this$dateheaders$ = _this.dateheaders[0]) === null || _this$dateheaders$ === void 0 ? void 0 : _this$dateheaders$.epoch;
            var lastDate = (_this$dateheaders = _this.dateheaders[_this.dateheaders.length - 1]) === null || _this$dateheaders === void 0 ? void 0 : _this$dateheaders.epoch;
            var isUSD = _this.columnisocode === "USD";
            var dataArrToFilter = isUSD ? _this.getUSDValue(item.data, item.curr) : item === null || item === void 0 ? void 0 : item.data;
            var filteredData = dataArrToFilter.filter(function (d) {
              return d[0] >= firstDate && d[0] <= lastDate;
            });
            series.push(_objectSpread(_objectSpread({}, row), {}, {
              name: _this.I18nFn(row.name),
              data: filteredData,
              xAxis: "daily",
              dataLabels: {
                enabled: false
              },
              iso: _this.columnisocode
            }));
          } else {
            series.push(_objectSpread(_objectSpread({}, row), {}, {
              name: _this.I18nFn(row.name),
              xAxis: 0
            }));
          }
        });
      } else {
        selectedRows.forEach(function (row, idx) {
          var currentRowType = rowTypes[idx];
          var isPriceFactor = Object.keys(_this.extraRows).find(function (key) {
            return row.id.endsWith(key);
          });
          if (isPriceFactor) {
            var _this$dateheaders$2, _this$dateheaders2;
            var itemId = Object.keys(_this.extraRows).find(function (key) {
              return row.id.endsWith(key);
            });
            var item = _this.extraRows[itemId];
            var firstDate = (_this$dateheaders$2 = _this.dateheaders[0]) === null || _this$dateheaders$2 === void 0 ? void 0 : _this$dateheaders$2.epoch;
            var lastDate = (_this$dateheaders2 = _this.dateheaders[_this.dateheaders.length - 1]) === null || _this$dateheaders2 === void 0 ? void 0 : _this$dateheaders2.epoch;
            var filteredData = item === null || item === void 0 ? void 0 : item.data.filter(function (d) {
              return d[0] >= firstDate && d[0] <= lastDate;
            });
            series.push(_objectSpread(_objectSpread({}, row), {}, {
              name: _this.I18nFn(row.name),
              data: filteredData
            }));
          } else {
            series.push(_objectSpread(_objectSpread({}, row), {}, {
              name: "".concat(_this.I18nFn(_this.chartsymbol), " ").concat(_this.I18nFn(row.name)),
              visible: !_this.hideSelf,
              type: currentRowType
            }));
            additionalTids.forEach(function (id) {
              var rowKey = row.rowId;
              var chart = _this.addTableChart[id];
              if (!chart) return;
              var dataObj;
              if (_this.dataSource === "ciq") {
                var nameToKeyMap = chart.nameToKey;
                dataObj = chart[rowKey] || chart[nameToKeyMap[row.name]];
              } else if (_this.dataSource === "morningstar") {
                // eslint-disable-next-line no-unused-vars
                var _row$rowId$split = row.rowId.split("-"),
                  _row$rowId$split2 = _slicedToArray(_row$rowId$split, 3),
                  _source = _row$rowId$split2[0],
                  _type = _row$rowId$split2[1],
                  metricName = _row$rowId$split2[2];
                var morningstarFinancials = chart.financials.flat();
                var finData = chart.financialsLineItems.reduce(function (accum, obj) {
                  var metricsList = obj.keys;
                  if (obj.formula !== "h3") {
                    metricsList.forEach(function (metric) {
                      var secondaryMetric = morningstarFinancials.find(function (secondMetricKey) {
                        return secondMetricKey.tikrKey === metric.tikrKey;
                      }) || {};
                      accum[metric.tikrKey] = secondaryMetric;
                    });
                  }
                  return accum;
                }, {});
                dataObj = finData === null || finData === void 0 ? void 0 : finData[metricName];
              }
              if (Object.keys(dataObj).length === 0) return;
              var tickerObj = _this.addTickersForChart[id] || {};
              var toUSD = _this.columnisocode === "USD" ? 1 : 0;
              var newSeries = _this.charttype === "Financials" ? _this.transformFinRowToHighchart({
                period: _this.period,
                resData: chart,
                dateHeaders: _this.dateheaders,
                row: dataObj,
                desiredUnits: _this.desiredunits,
                currencyToggle: toUSD,
                rowId: "".concat(id, "-").concat(rowKey),
                formatNumber: _this.formatNumberToDecimals,
                tickerObj: tickerObj,
                typeOverwrite: currentRowType
              }) : _this.transformEstimates(row, dataObj, id);
              series.push(newSeries);
            });
          }
        });
      }
      return series;
    },
    buttonActive: function buttonActive() {
      // check that the user has selected a series to plot
      // if the user has selected a series to plot, return true
      // if the user hasn't selected any series to plot, return false
      return this.selectedrows.length > 0;
    },
    addTableChart: function addTableChart() {
      if (this.dataSource === "fmp") {
        return this.$store.state.fmp["add".concat(this.charttype)][this.period] || {};
      } else if (this.dataSource === "morningstar") {
        return this.$store.state.morningstar["add".concat(this.charttype)][this.period] || {};
      }
      return this.$store.state.ciq["add".concat(this.charttype)][this.period] || {};
    },
    addTickersForChart: function addTickersForChart() {
      if (this.dataSource === "fmp") {
        var _this$$store$state$fm;
        return ((_this$$store$state$fm = this.$store.state.fmp["".concat(this.type, "Tickers")]) === null || _this$$store$state$fm === void 0 ? void 0 : _this$$store$state$fm[this.period]) || {};
      } else if (this.dataSource === "morningstar") {
        var _this$$store$state$mo;
        return ((_this$$store$state$mo = this.$store.state.morningstar["".concat(this.type, "Tickers")]) === null || _this$$store$state$mo === void 0 ? void 0 : _this$$store$state$mo[this.period]) || {};
      }
      return this.$store.state.ciq["".concat(this.type, "Tickers")][this.period] || {};
    },
    toUSD: {
      get: function get() {
        return this.$store.state.ciq["compare".concat(this.charttype, "ToUSD")];
      },
      set: function set(value) {
        this.$store.commit("ciq/setToggle", {
          type: "compare".concat(this.charttype, "ToUSD"),
          value: value
        });
      }
    },
    showChart: {
      get: function get() {
        var chartType = "show".concat(this.charttype, "Chart");
        return this.$store.state.ciq[chartType];
      },
      set: function set() {
        // console.log(value)
        var chartType = "show".concat(this.charttype, "Chart");
        this.$store.commit("ciq/setChartBool", {
          chartType: chartType
        });
      }
    },
    showChartLabels: {
      get: function get() {
        var chartType = "show".concat(this.charttype, "ChartLabels");
        return this.$store.state.ciq[chartType];
      },
      set: function set() {
        var chartType = "show".concat(this.charttype, "ChartLabels");
        this.$store.commit("ciq/setChartBool", {
          chartType: chartType
        });
      }
    },
    chart: function chart() {
      return this.showChart;
    } // type() {
    //   // watchout this is camel case and different from props
    //   return `${this.charttype.toLowerCase()}Chart`
    // },
  },
  methods: {
    // transformEstimates(activeSeries, dataObj, dateHeaders, periodType) {
    transformEstimates: function transformEstimates(activeSeries, allDataObj, id) {
      var _this2 = this;
      var dateHeaders = this.dateheaders;
      var periodType = this.period;
      var isoCode = this.columnisocode;
      var desiredUnits = this.desiredunits;
      var ticker = this.addTickersForChart[id];
      var type = activeSeries.type;
      var yAxis = activeSeries.yAxis;
      var formula = activeSeries.formula;
      var activeData = activeSeries.data || [];
      var activeDataLabelFormatter = activeData.length > 0 ? activeData[0].dataLabels : null;
      var newData = dateHeaders.reduce(function (acc, d) {
        var periodId = "";
        if (periodType === "a" || periodType === "cy") {
          // annual, use ${d.calendaryear}
          periodId = "".concat(d.calendaryear);
        } else {
          // any other period with a month
          periodId = "".concat(d.calendaryear).concat(d.calendarquarter);
        }
        var dataObj = allDataObj[periodId];
        if (dataObj && !dataObj.unauth) {
          var newDataPoint = {};
          newDataPoint.dataLabels = activeDataLabelFormatter;
          var value;
          if (formula === "val" || formula === "pct") {
            value = parseFloat(dataObj.dataitemvalue);
            if (formula === "val") {
              value = _this2.convertEstUnits(value, dataObj.estimatescaleid, desiredUnits);
            }
          } else {
            value = dataObj.dataitemvalue;
          }
          if (formula === "dxdt" || formula === "div") {
            value = value * 100;
          }
          var currExRate = parseFloat(dataObj.priceclose) || 1;
          var exRate = isoCode === "USD" ? currExRate : 1;
          if (formula === "val") {
            value = value / exRate;
          }
          newDataPoint.x = d.timeVal;
          if ((periodType === "a" || periodType === "cy") && dataObj.timeVal) {
            newDataPoint.x = dataObj.timeVal;
          }
          newDataPoint.y = value;
          acc.push(newDataPoint);
        }
        return acc;
      }, []);
      return {
        data: newData,
        id: "".concat(id, "-").concat(activeSeries.id),
        name: "".concat(ticker.tickersymbol, " ").concat(this.I18nFn(activeSeries.name)),
        rowId: activeSeries.rowId,
        tooltip: activeSeries.tooltip,
        type: type,
        yAxis: yAxis
      };
    },
    // addTicker & removeTicker are methods to add an additional company
    // to the existing TableChart
    // TODO: make these methods for FMP
    addTicker: function addTicker(e) {
      this.$store.dispatch("".concat(this.dataSource, "/tableChartOneStep"), {
        ticker: e[e.length - 1],
        period: this.period,
        type: this.charttype
      });
      this.$refs.tableChartAutocomplete.blur();
    },
    removeTicker: function removeTicker(e) {
      // TODO: change this
      this.$store.commit("".concat(this.dataSource, "/removeTickerFromTableChart"), {
        chartType: this.type,
        period: this.period,
        tid: e.tradingitemid.toString(),
        cid: e.companyid.toString()
      });
      this.$refs.tableChartAutocomplete.blur();
    },
    changeRowType: function changeRowType(event, row) {
      var payload = {
        seriesType: event,
        chartType: this.type,
        period: this.period,
        rowId: row.rowId
      };
      this.$store.commit("".concat(this.dataSource, "/toggleSeriesType"), payload);
    },
    clearChart: function clearChart() {
      var chartType = this.type;
      var period = this.period; // console.log(`Clear chartType ${chartType} for period ${period}`)
      confirm("Are you sure you want to clear the chart?") && this.$store.commit("".concat(this.dataSource, "/clearChart"), {
        chartType: chartType,
        period: period
      });
    },
    customFilter: function customFilter(f) {
      // remove the already selected items from the autocomplete
      // select while allowing them to be present
      // for multiple chips
      var activeTicker = this.$store.state.ciq.ticker || {};
      var alreadyFetched = this.addTickersForChart;
      if (this.charttype === "Financials") {
        if (activeTicker.companyid && f.companyid === activeTicker.companyid) {
          return false;
        }
        return !alreadyFetched[f.companyid];
      } else {
        if (activeTicker.tradingitemid && f.tradingitemid === activeTicker.tradingitemid) {
          return false;
        }
        return !alreadyFetched[f.tradingitemid];
      }
    },
    defaultSelect: function defaultSelect(row) {
      if (row.chartType) {
        return row.chartType;
      } else {
        return row.type;
      }
    }
  }
});