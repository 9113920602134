import { VCard } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _setup.loading ? _c(VCard, {
    staticClass: "py-6",
    attrs: {
      "elevation": "0"
    }
  }, [_c(VProgressCircular, {
    staticClass: "quote",
    attrs: {
      "size": 50,
      "width": 5,
      "color": "primaryAction",
      "indeterminate": ""
    }
  })], 1) : _c('div', [_c('div', {
    staticClass: "estimates-table-wrapper",
    "class": {
      firstColumnFrozen: _setup.freezeFirstColumn
    }
  }, [_c(VDataTable, {
    attrs: {
      "headers": _setup.headers,
      "items": _setup.data,
      "options": {
        itemsPerPage: _setup.data.length
      },
      "mobile-breakpoint": 0,
      "hide-default-header": true,
      "hide-default-footer": true,
      "dense": "",
      "item-key": "meta?.key",
      "group-by": "position"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('thead', [_c('tr', [_c('th', {
          staticClass: "first-row common-header-cell pr-4 py-2",
          "class": {
            quarterly: _vm.period === 'q'
          },
          attrs: {
            "colspan": _setup.getColSpan(_setup.headers)
          }
        }, [_c('strong', {
          staticClass: "pl-4"
        }, [_vm._v("\n                " + _vm._s(_setup.tickerSymbol) + "\n                " + _vm._s(_setup.I18nFn("Analyst Estimates Breakdown")) + " | TIKR.com\n              ")])])]), _vm._v(" "), _c('tr', _vm._l(_setup.headers, function (h) {
          var _h$meta;
          return _c('th', {
            key: "header_".concat(h.text),
            staticClass: "common-header-cell second-row",
            "class": (_h$meta = h.meta) === null || _h$meta === void 0 ? void 0 : _h$meta["class"],
            attrs: {
              "id": "header_".concat(h.text.toLowerCase())
            }
          }, [_vm._v("\n              " + _vm._s(h.text) + "\n            ")]);
        }), 0)])];
      },
      proxy: true
    }, {
      key: "group.header",
      fn: function fn(_ref) {
        var items = _ref.items,
          toggle = _ref.toggle,
          isOpen = _ref.isOpen;
        return [_c('th', {
          staticClass: "th-toggle pl-0",
          "class": {
            locked: _setup.shouldDisplayLockedDataMsg(items[0].category, _vm.lockedData),
            frozen: _setup.freezeFirstColumn
          },
          attrs: {
            "colspan": _vm.lockedData ? 1 : _setup.getColSpan(_setup.headers)
          },
          on: {
            "click": toggle
          }
        }, [_c('span', {
          staticClass: "pl-3 row-title"
        }, [_c(VIcon, {
          staticClass: "mr-2",
          attrs: {
            "small": ""
          }
        }, [_vm._v("\n              " + _vm._s(isOpen ? "mdi-minus" : "mdi-plus") + "\n            ")]), _vm._v("\n            " + _vm._s(items[0].category) + "\n          ")], 1)]), _vm._v(" "), _setup.shouldDisplayLockedDataMsg(items[0].category, _vm.lockedData) ? _c('td', {
          staticClass: "locked-cell",
          attrs: {
            "colspan": _setup.getColSpan(_setup.headers) - 1,
            "rowspan": _setup.getRowSpan(_setup.data, items)
          }
        }, [_c(_setup.LockedDataMessage, {
          attrs: {
            "show-icon": true,
            "label": "Data is only available to Pro users",
            "label-extra-classes": "freetier-label"
          }
        })], 1) : _vm._e()];
      }
    }, {
      key: "item",
      fn: function fn(_ref2) {
        var _item$meta;
        var item = _ref2.item;
        return [!item.meta.hide ? _c('tr', {
          key: item.meta.key,
          staticClass: "common-row",
          "class": item === null || item === void 0 || (_item$meta = item.meta) === null || _item$meta === void 0 ? void 0 : _item$meta.rowClass
        }, _vm._l(_setup.getFilteredRow(item, _vm.lockedData), function (field) {
          return _c('td', {
            key: "".concat(field, "_").concat(item.meta.key),
            "class": _setup.getCellClasses(field, item, _setup.headers)
          }, [!_setup.isObject(item[field]) ? [_vm._v("\n              " + _vm._s(item[field]) + "\n            ")] : item[field].unauth ? _c(_setup.UnauthorizedIcon) : _c(_setup.BmTableCell, {
            attrs: {
              "desired-units": _vm.desiredUnits,
              "currency-toggle": _vm.currencyToggle,
              "format": item[field].format,
              "decimals": _vm.decimals,
              "unauth": item[field].unauth,
              "unit-id": item[field].unitId,
              "value": item[field].value,
              "price-close": item[field].priceClose,
              "hide": item[field].hideRowValues,
              "format-function": _vm.formatFunction,
              "custom-string-value": _setup.getCustomStr(item, field)
            }
          })], 2);
        }), 0) : _vm._e()];
      }
    }])
  })], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };