const is = [
  "revenue",
  "costOfRevenue",
  "grossProfit",
  "grossProfitRatio",
  "researchAndDevelopmentExpenses",
  "generalAndAdministrativeExpenses",
  "sellingAndMarketingExpenses",
  "sellingGeneralAndAdministrativeExpenses",
  "otherExpenses",
  "operatingExpenses",
  "costAndExpenses",
  "interestIncome",
  "interestExpense",
  "depreciationAndAmortization",
  "ebitda",
  "ebitdaratio",
  "operatingIncome",
  "operatingIncomeRatio",
  "totalOtherIncomeExpensesNet",
  "incomeBeforeTax",
  "incomeBeforeTaxRatio",
  "incomeTaxExpense",
  "netIncome",
  "netIncomeRatio",
  "eps",
  "epsdiluted",
  "weightedAverageShsOut",
  "weightedAverageShsOutDil",
]

const is_bulk = [
  "revenue",
  "costOfRevenue",
  "grossProfit",
  "grossProfitRatio",
  "ResearchAndDevelopmentExpenses",
  "GeneralAndAdministrativeExpenses",
  "SellingAndMarketingExpenses",
  "sellingGeneralAndAdministrativeExpenses",
  "otherExpenses",
  "operatingExpenses",
  "costAndExpenses",
  "interestExpense",
  "depreciationAndAmortization",
  "EBITDA",
  "EBITDARatio",
  "operatingIncome",
  "operatingIncomeRatio",
  "totalOtherIncomeExpensesNet",
  "incomeBeforeTax",
  "incomeBeforeTaxRatio",
  "incomeTaxExpense",
  "netIncome",
  "netIncomeRatio",
  "EPS",
  "EPSDiluted",
  "weightedAverageShsOut",
  "weightedAverageShsOutDil",
]

export { is, is_bulk }
