import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.is-nan.js";
import "core-js/modules/es.object.entries.js";
import "core-js/modules/es.object.from-entries.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function _toConsumableArray(r) {
  return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread();
}
function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _iterableToArray(r) {
  if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r);
}
function _arrayWithoutHoles(r) {
  if (Array.isArray(r)) return _arrayLikeToArray(r);
}
function _slicedToArray(r, e) {
  return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest();
}
function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
function _iterableToArrayLimit(r, l) {
  var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"];
  if (null != t) {
    var e,
      n,
      i,
      u,
      a = [],
      f = !0,
      o = !1;
    try {
      if (i = (t = t.call(r)).next, 0 === l) {
        if (Object(t) !== t) return;
        f = !1;
      } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0);
    } catch (r) {
      o = !0, n = r;
    } finally {
      try {
        if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return;
      } finally {
        if (o) throw n;
      }
    }
    return a;
  }
}
function _arrayWithHoles(r) {
  if (Array.isArray(r)) return r;
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import { takeRight } from "lodash";
import dayjs from "~/utils/tools/dayjs";
import useCiqDataSource from "~/functions/useCiqDataSource";
import useDateTimeFormatters from "~/functions/useDateTimeFormatters";
import { bmHeaders, bmRows, configByIndex, possibleBpsHeaders, possibleBpsRows } from "~/utils/constants/beatsAndMisses";
var getReportingDates = function getReportingDates(periods, formatterFn, reverse) {
  var periodsArr = reverse ? periods : periods.reverse();
  var dates = periodsArr.reduce(function (acc, period) {
    var key = getQuarterItemKey(period);
    var date = new Date(period.advancedate);
    acc[key] = formatterFn(date);
    return acc;
  }, {});
  return dates;
};
var sample = [{
  title: "Reporting Date"
}, {
  title: "1D Stock Price Change",
  meta: {
    toggleColor: true,
    rowClass: "font-weight-bold highlighted-row"
  }
}];
var getQuarterItemKey = function getQuarterItemKey(item) {
  if (!item) {
    return "";
  }
  return "".concat(item.text, " Earnings");
};
var getColumnId = function getColumnId(_ref) {
  var quarterInfo = _ref.quarterInfo,
    index = _ref.index;
  var configObj = configByIndex[index];
  var quarterKey = (configObj === null || configObj === void 0 ? void 0 : configObj.relativeQuarterKey) || 0;
  var quarter = quarterInfo.calendarquarter + quarterKey;
  var relativeDate = (configObj === null || configObj === void 0 ? void 0 : configObj.relativeDateKey) || 0;
  var year = Number(quarterInfo.annualLabel.substring(1)) + relativeDate;
  return "".concat(configObj.prefix, "_q").concat(quarter, "_f").concat(year);
};
var getHeaderInfo = function getHeaderInfo(item, idx, quarterInfo, periods, index) {
  var isMetricName = item.text === "Metric";
  if (isMetricName) {
    return item;
  }
  var cfgObj = configByIndex[idx - 1];
  var strSuffix = cfgObj.type === "actual" ? "A" : "E";
  var relativeQuarterKey = (cfgObj === null || cfgObj === void 0 ? void 0 : cfgObj.relativeQuarterKey) || 0;
  var columnId = getColumnId({
    quarterInfo: quarterInfo,
    index: idx - 1
  });
  var bold = periods[index + Math.abs(relativeQuarterKey)];
  return _objectSpread(_objectSpread({}, item), {}, {
    sortable: false,
    bold: "".concat(bold, " ").concat(strSuffix),
    columnId: columnId
  });
};
var getUnitId = function getUnitId(cells) {
  var _firstVal$0$estimates, _firstVal$;
  var firstVal = getFirstVal(cells);
  var unit = (_firstVal$0$estimates = firstVal === null || firstVal === void 0 || (_firstVal$ = firstVal[0]) === null || _firstVal$ === void 0 ? void 0 : _firstVal$.estimatescaleid) !== null && _firstVal$0$estimates !== void 0 ? _firstVal$0$estimates : firstVal.estimatescaleid;
  if (cells.size === 1) {
    return unit;
  } else {
    var cellsArr = Array.from(cells, function (_ref2) {
      var _ref3 = _slicedToArray(_ref2, 2),
        key = _ref3[0],
        value = _ref3[1];
      return {
        key: key,
        value: value
      };
    });
    var units = cellsArr.map(function (cell) {
      var _cell$value$estimates, _cell$value, _cell$value2;
      var id = (_cell$value$estimates = (_cell$value = cell.value) === null || _cell$value === void 0 ? void 0 : _cell$value.estimatescaleid) !== null && _cell$value$estimates !== void 0 ? _cell$value$estimates : (_cell$value2 = cell.value) === null || _cell$value2 === void 0 || (_cell$value2 = _cell$value2[0]) === null || _cell$value2 === void 0 ? void 0 : _cell$value2.estimatescaleid;
      return id;
    });
    var isEveryUnitTheSame = units.every(function (unitVal) {
      return unitVal === unit;
    });
    if (isEveryUnitTheSame) {
      return unit;
    }
  }
  return undefined;
};
var getFirstVal = function getFirstVal(cells) {
  var firstKey = cells.keys().next().value;
  return cells.get(firstKey);
};
var getPriceClose = function getPriceClose(cells) {
  var _firstVal$2;
  var firstVal = getFirstVal(cells);
  var pc = Number((firstVal === null || firstVal === void 0 || (_firstVal$2 = firstVal[0]) === null || _firstVal$2 === void 0 ? void 0 : _firstVal$2.priceclose) || (firstVal === null || firstVal === void 0 ? void 0 : firstVal.priceclose));
  return Number.isNaN(pc) ? 1 : pc;
};
var getOneDayAfterAndBefore = function getOneDayAfterAndBefore(priceList, indexToSearch) {
  var twoDaysBefore = priceList[indexToSearch - 2];
  var closestDayBefore = priceList[indexToSearch - 1];
  var actualDayOrClosestDayBefore = priceList[indexToSearch];
  var dayAfter = priceList[indexToSearch + 1];
  var daysArr = [twoDaysBefore, closestDayBefore, actualDayOrClosestDayBefore, dayAfter];
  return daysArr;
};
var findQuotesForDates = function findQuotesForDates(periodArr, priceList) {
  var priceCalculationType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "1D";
  var periodsPriceList = periodArr.map(function (item) {
    var filteredPrices = priceList.filter(function (priceItem) {
      return dayjs.utc(priceItem.d).isBefore(dayjs.utc(new Date(item)));
    });
    if (priceCalculationType === "1D") {
      return getOneDayAfterAndBefore(priceList, filteredPrices.length);
    } else {
      return filteredPrices;
    }
  });
  return periodsPriceList;
};
var getPeriodDiff = function getPeriodDiff(periods, periodsPriceList) {
  return periods.map(function (dateStr, idx) {
    var _item$startPriceIdx, _item$endPriceIdx;
    var item = periodsPriceList[idx];
    var isBefore4Pm = dayjs.utc(dateStr, "HH").$H < 16;
    var startPriceIdx = 0;
    var endPriceIdx = 1;
    if (!isBefore4Pm) {
      startPriceIdx = startPriceIdx + 1;
      endPriceIdx = endPriceIdx + 1;
    }
    var startPrice = (_item$startPriceIdx = item[startPriceIdx]) === null || _item$startPriceIdx === void 0 ? void 0 : _item$startPriceIdx.c;
    var endPrice = (_item$endPriceIdx = item[endPriceIdx]) === null || _item$endPriceIdx === void 0 ? void 0 : _item$endPriceIdx.c;
    var difference = endPrice / startPrice - 1;
    return difference;
  });
};
var beatsAndMisses = function beatsAndMisses() {
  var _useNuxtApp = useNuxtApp(),
    $store = _useNuxtApp.$store;
  var _useDateTimeFormatter = useDateTimeFormatters(),
    formatDate = _useDateTimeFormatter.utcEpochToShortDate;
  var reverse = computed(function () {
    return $store.state.config.reverseDates;
  });
  var currentPeriods = computed(function () {
    if (loading.value) {
      return ["Loading"];
    } else {
      var _$store$state$ciq;
      var fivePeriods = takeRight((_$store$state$ciq = $store.state.ciq) === null || _$store$state$ciq === void 0 || (_$store$state$ciq = _$store$state$ciq.estimates) === null || _$store$state$ciq === void 0 || (_$store$state$ciq = _$store$state$ciq.q) === null || _$store$state$ciq === void 0 ? void 0 : _$store$state$ciq.lastTenPeriods, 5);
      return fivePeriods.map(function (item) {
        return getQuarterItemKey(item);
      }).reverse();
    }
  });
  var loading = computed(function () {
    var _$store$state$ciq$est;
    return $store.state.ciq.fetchingEstimates || $store.state.ciq.fetchingValuation || !((_$store$state$ciq$est = $store.state.ciq.estimates) !== null && _$store$state$ciq$est !== void 0 && (_$store$state$ciq$est = _$store$state$ciq$est.q) !== null && _$store$state$ciq$est !== void 0 && (_$store$state$ciq$est = _$store$state$ciq$est.lastTenPeriods) !== null && _$store$state$ciq$est !== void 0 && _$store$state$ciq$est.length);
  });
  var data = computed(function () {
    var _$store$state$ciq$tic;
    if (loading.value) {
      return {};
    }
    var fivePeriods = takeRight($store.state.ciq.estimates.q.lastTenPeriods, 5);
    var clone = _toConsumableArray(fivePeriods).reverse();
    var industryDesc = (_$store$state$ciq$tic = $store.state.ciq.ticker) === null || _$store$state$ciq$tic === void 0 ? void 0 : _$store$state$ciq$tic.simpleindustrydescription;
    return clone.reduce(function (acc, item, idx) {
      var _$store$state$ciq2;
      var key = getQuarterItemKey(item);
      var lastTenPeriodsMapped = (_$store$state$ciq2 = $store.state.ciq) === null || _$store$state$ciq2 === void 0 || (_$store$state$ciq2 = _$store$state$ciq2.estimates) === null || _$store$state$ciq2 === void 0 || (_$store$state$ciq2 = _$store$state$ciq2.q) === null || _$store$state$ciq2 === void 0 ? void 0 : _$store$state$ciq2.lastTenPeriods.map(function (item) {
        return getQuarterItemKey(item);
      }).reverse();
      acc[key] = getQuarterItem(item, idx, lastTenPeriodsMapped, industryDesc);
      return acc;
    }, {});
  });
  var getOneDayStockPriceChange = function getOneDayStockPriceChange(periods, priceList, reverse) {
    var periodArr = periods.map(function (item) {
      return item.advancedate;
    }).sort(function (a, b) {
      return new Date(a) - new Date(b);
    });
    var quotes = findQuotesForDates(periodArr, priceList);
    var diffs = getPeriodDiff(periodArr, quotes);
    var objs = periods.reduce(function (acc, period, i) {
      var key = getQuarterItemKey(period);
      var obj = {
        value: diffs[i],
        unauth: false,
        formula: "val",
        unitId: 0,
        format: "pct",
        priceClose: 1
      };
      acc[key] = obj;
      return acc;
    }, {});
    if (reverse) {
      return Object.fromEntries(Object.entries(objs).reverse());
    }
    return objs;
  };
  var getQuarterItem = function getQuarterItem(quarterInfo, quarterIndex, periods, industryDesc) {
    var quarterInitialValue = quarterIndex === 0 ? 0 : -quarterIndex;
    var mappedPeriods = periods.map(function (item) {
      var value = item.split(" ");
      return value[0];
    });
    var mappedHeader = bmHeaders.map(function (item, idx) {
      return getHeaderInfo(item, idx, quarterInfo, mappedPeriods, quarterIndex);
    });
    var mappedData = bmRows.reduce(function (acc, item) {
      var metricName = item.metricName,
        meta = item.meta;
      var obj = {
        metricName: metricName
      };
      if (meta) {
        obj.meta = meta;
      }
      var hideRowValues = false;
      var show = true;
      mappedHeader.filter(function (i) {
        return i.text !== "Metric";
      }).forEach(function (headerItem, index) {
        var _meta$metricData;
        var isBpsHeader = possibleBpsHeaders.includes(headerItem.text);
        var isBpsRow = possibleBpsRows.includes(item.metricName);
        var isBPS = isBpsHeader && isBpsRow;
        var cfg = configByIndex[index];
        var actualOrEstimateKey = cfg.type === "estimate" ? "mean" : "actual";
        var relativeQuarterKey = quarterInitialValue + (cfg.relativeQuarterKey || 0);
        var relativeDateKey = cfg.relativeDateKey;
        var formula = (cfg === null || cfg === void 0 ? void 0 : cfg.formula) || (meta === null || meta === void 0 || (_meta$metricData = meta.metricData) === null || _meta$metricData === void 0 ? void 0 : _meta$metricData.formula) || "val";
        var format = (cfg === null || cfg === void 0 ? void 0 : cfg.cellFormat) || (meta === null || meta === void 0 ? void 0 : meta.cellFormat) || "val";
        var isBeatAndMiss = cfg.prefix === "beatMiss";
        var isChangeYoY = cfg.prefix === "changeYoY";
        var isChangeQoQ = cfg.prefix === "changeQoQ";
        var isPossibleNMRow = meta === null || meta === void 0 ? void 0 : meta.disableLessThenZeroValues;
        var isPossibleNMColumn = ["Beat / Miss vs Street", "% Change YoY", "% Change QoQ"].includes(headerItem.text);
        var isNMCell = isPossibleNMRow && isPossibleNMColumn;
        var props = _objectSpread(_objectSpread({
          actualOrEstimateKey: actualOrEstimateKey
        }, meta === null || meta === void 0 ? void 0 : meta.metricData), {}, {
          relativeDateKey: relativeDateKey,
          relativeQuarterKey: relativeQuarterKey,
          formattedValue: false,
          formula: formula,
          periodKey: "q"
        });
        var _useCiqDataSource = useCiqDataSource(props),
          cellValue = _useCiqDataSource.cellValue,
          isAnyCellUnauth = _useCiqDataSource.isAnyCellUnauth,
          isAnyCellNegative = _useCiqDataSource.isAnyCellNegative,
          mappedCellItems = _useCiqDataSource.mappedCellItems;
        var value = cellValue.value;
        var unauth = isAnyCellUnauth.value;
        var unitId = getUnitId(mappedCellItems.value);
        var priceClose = getPriceClose(mappedCellItems.value);
        var hasNegativeCells = false;
        if (isNMCell) {
          hasNegativeCells = isAnyCellNegative.value;
        }
        if (isBeatAndMiss) {
          var estimateProps = _objectSpread(_objectSpread({}, props), {}, {
            actualOrEstimateKey: "mean"
          });
          var estimateData = useCiqDataSource(estimateProps);
          value = isBPS ? (cellValue.value - estimateData.cellValue.value) * 10000 : cellValue.value / estimateData.cellValue.value - 1;
          if (cellValue.value < estimateData.cellValue.value) {
            value = -Math.abs(value);
          } else {
            value = Math.abs(value);
          }
          unauth = isAnyCellUnauth.value || estimateData.isAnyCellUnauth.value;
          if (isNMCell) {
            hasNegativeCells = isAnyCellNegative.value || estimateData.isAnyCellNegative.value;
          }
        } else if (isBPS) {
          if (isChangeYoY) {
            var currentYearProps = _objectSpread(_objectSpread({}, props), {}, {
              formula: "div"
            });
            var yearPriorProps = _objectSpread(_objectSpread({}, currentYearProps), {}, {
              relativeQuarterKey: props.relativeQuarterKey - 4 || -4
            });
            var currentYearData = useCiqDataSource(currentYearProps);
            var yearPriorData = useCiqDataSource(yearPriorProps);
            var currentYearVal = currentYearData.cellValue.value * 100;
            var oneYearBeforeVal = yearPriorData.cellValue.value * 100;
            value = currentYearVal * 100 - oneYearBeforeVal * 100;
            if (currentYearVal < oneYearBeforeVal) {
              value = -Math.abs(value);
            } else {
              value = Math.abs(value);
            }
            if (isNMCell) {
              hasNegativeCells = currentYearData.isAnyCellNegative.value || yearPriorData.isAnyCellNegative.value;
            }
          } else if (isChangeQoQ) {
            var _currentYearProps = _objectSpread(_objectSpread({}, props), {}, {
              formula: "div"
            });
            var _yearPriorProps = _objectSpread(_objectSpread({}, _currentYearProps), {}, {
              relativeQuarterKey: props.relativeQuarterKey - 1 || -1
            });
            var _currentYearData = useCiqDataSource(_currentYearProps);
            var _yearPriorData = useCiqDataSource(_yearPriorProps);
            var _currentYearVal = _currentYearData.cellValue.value * 100;
            var _oneYearBeforeVal = _yearPriorData.cellValue.value * 100;
            value = _currentYearVal * 100 - _oneYearBeforeVal * 100;
            if (_currentYearVal < _oneYearBeforeVal) {
              value = -Math.abs(value);
            } else {
              value = Math.abs(value);
            }
            if (isNMCell) {
              hasNegativeCells = _currentYearData.isAnyCellNegative.value || _yearPriorData.isAnyCellNegative.value;
            }
          }
        }
        if (index === 0 && Number.isNaN(value)) {
          hideRowValues = true;
        }
        if (!isValidCategory(meta.allowedCategories, industryDesc)) {
          show = false;
        }
        obj[headerItem.columnId] = isBPS ? {
          value: value,
          format: "bps",
          unauth: unauth,
          formula: formula,
          unitId: unitId,
          hideRowValues: hideRowValues,
          hasNegativeCells: hasNegativeCells
        } : {
          value: value,
          unauth: unauth,
          formula: formula,
          unitId: unitId,
          format: format,
          hasNegativeCells: hasNegativeCells,
          hideRowValues: hideRowValues,
          priceClose: priceClose
        };
      });
      if (!hideRowValues && show) {
        acc.push(obj);
      }
      return acc;
    }, []);
    return {
      quarterInfo: quarterInfo,
      headers: mappedHeader,
      data: mappedData
    };
  };
  var earningsHeaderCellClasses = "white--text py-1 font-weight-bold";
  var earningsHistTitle = {
    text: "Earnings History",
    "class": earningsHeaderCellClasses,
    value: "title"
  };
  var mapEarningsHeader = function mapEarningsHeader(item) {
    var value = "q".concat(item.fiscalquarter, "_").concat(item.fiscalyear);
    return {
      text: item.text,
      "class": earningsHeaderCellClasses,
      value: value
    };
  };
  var earningsHeaders = computed(function () {
    var _$store$state$ciq3;
    if (loading.value) {
      return [];
    }
    var fivePeriods = takeRight((_$store$state$ciq3 = $store.state.ciq) === null || _$store$state$ciq3 === void 0 || (_$store$state$ciq3 = _$store$state$ciq3.estimates) === null || _$store$state$ciq3 === void 0 || (_$store$state$ciq3 = _$store$state$ciq3.q) === null || _$store$state$ciq3 === void 0 ? void 0 : _$store$state$ciq3.lastTenPeriods, 5).reverse();
    var periodsArr = reverse.value ? fivePeriods.reverse() : fivePeriods;
    var quarters = periodsArr.reverse().map(function (item) {
      return mapEarningsHeader(item);
    });
    var symbol = $store.state.ciq.ticker.tickersymbol;
    var title = _objectSpread(_objectSpread({}, earningsHistTitle), {}, {
      text: "".concat(symbol, " Beats & Misses | TIKR.com")
    });
    return [title].concat(_toConsumableArray(quarters));
  });
  var getEarningsRow = function getEarningsRow(item, periods, formatterFn, reverse, quoteList) {
    var rowLabel = item.title;
    var meta = item.meta;
    switch (rowLabel) {
      case "Reporting Date":
        {
          var dates = getReportingDates(periods, formatterFn, reverse);
          return _objectSpread({
            title: item.title,
            meta: meta
          }, dates);
        }
      case "1D Stock Price Change":
        {
          var priceChange = getOneDayStockPriceChange(periods, quoteList, reverse);
          return _objectSpread({
            title: item.title,
            meta: meta
          }, priceChange);
        }
      default:
        {
          return _objectSpread({
            title: rowLabel
          }, item);
        }
    }
  };
  var getRowData = function getRowData(meta, periods, reverse, metricName) {
    var estimationProps = _objectSpread(_objectSpread({}, meta), {}, {
      actualOrEstimateKey: "mean"
    });
    var actualProps = _objectSpread(_objectSpread({}, meta), {}, {
      actualOrEstimateKey: "actual"
    });
    var estimations = {};
    var actuals = {};
    var differences = {};
    periods.forEach(function (period, index) {
      var periodKey = getQuarterItemKey(period);
      var relativeQuarterKey = index === 0 ? 0 : -Math.abs(index);
      var estimationData = useCiqDataSource(_objectSpread(_objectSpread({}, estimationProps), {}, {
        relativeQuarterKey: relativeQuarterKey
      }));
      var actualData = useCiqDataSource(_objectSpread(_objectSpread({}, actualProps), {}, {
        relativeQuarterKey: relativeQuarterKey
      }));
      var estimateUnitId = getUnitId(estimationData.mappedCellItems.value);
      var estimatePriceClose = getPriceClose(estimationData.mappedCellItems.value);
      var unitId = getUnitId(actualData.mappedCellItems.value);
      var priceClose = getPriceClose(actualData.mappedCellItems.value);
      var estimatedVal = estimationData.cellValue.value;
      var actualVal = actualData.cellValue.value;
      var difference = actualVal / estimatedVal - 1;
      if (actualVal < estimatedVal) {
        difference = -Math.abs(difference);
      } else {
        difference = Math.abs(difference);
      }
      estimations[periodKey] = {
        value: estimatedVal,
        unauth: estimationData.isAnyCellUnauth.value,
        format: "val",
        unitId: estimateUnitId,
        priceClose: estimatePriceClose
      };
      actuals[periodKey] = {
        value: actualVal,
        unauth: actualData.isAnyCellUnauth.value,
        format: "val",
        unitId: unitId,
        priceClose: priceClose
      };
      differences[periodKey] = {
        value: difference,
        unauth: estimationData.isAnyCellUnauth.value || actualData.isAnyCellUnauth.value,
        format: "pct",
        unitId: unitId,
        priceClose: priceClose
      };
    });
    if (!reverse) {
      estimations = Object.fromEntries(Object.entries(estimations).reverse());
      actuals = Object.fromEntries(Object.entries(actuals).reverse());
      differences = Object.fromEntries(Object.entries(differences).reverse());
    }
    return {
      estimations: estimations,
      actuals: actuals,
      differences: differences
    };
  };
  var getEstimatedObj = function getEstimatedObj(name, estimations) {
    return _objectSpread({
      title: "Avg. Estimated ".concat(name)
    }, estimations);
  };
  var getActualsObj = function getActualsObj(name, actuals) {
    return _objectSpread({
      title: "Actual ".concat(name)
    }, actuals);
  };
  var getDiffObj = function getDiffObj(differences) {
    return _objectSpread({
      title: "% Difference",
      meta: {
        rowClass: "font-weight-bold highlighted-row"
      }
    }, differences);
  };
  var getBlankObj = function getBlankObj(sample) {
    return Object.keys(sample).reduce(function (acc, key) {
      acc[key] = "";
      return acc;
    }, {});
  };
  var getInitialProps = function getInitialProps(meta) {
    return _objectSpread(_objectSpread({}, meta), {}, {
      periodKey: "q",
      formattedValue: false,
      relativeDateKey: 0,
      relativeQuarterKey: 0
    });
  };
  var isValidCategory = function isValidCategory() {
    var descriptionList = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var industryDesc = arguments.length > 1 ? arguments[1] : undefined;
    if (!descriptionList.length) {
      return true;
    }
    return descriptionList.includes(industryDesc);
  };
  var earnings = computed(function () {
    var _$store$state$ciq4, _$store$state$ciq$tic2, _$store$state$ciq$quo, _data;
    if (loading.value) {
      return [];
    }
    var periods = takeRight((_$store$state$ciq4 = $store.state.ciq) === null || _$store$state$ciq4 === void 0 || (_$store$state$ciq4 = _$store$state$ciq4.estimates) === null || _$store$state$ciq4 === void 0 || (_$store$state$ciq4 = _$store$state$ciq4.q) === null || _$store$state$ciq4 === void 0 ? void 0 : _$store$state$ciq4.lastTenPeriods, 5).reverse();
    var industryDesc = (_$store$state$ciq$tic2 = $store.state.ciq.ticker) === null || _$store$state$ciq$tic2 === void 0 ? void 0 : _$store$state$ciq$tic2.simpleindustrydescription;
    var quoteList = ((_$store$state$ciq$quo = $store.state.ciq.quotes) === null || _$store$state$ciq$quo === void 0 ? void 0 : _$store$state$ciq$quo.price) || [];
    var firstRows = sample.map(function (item) {
      return getEarningsRow(item, periods, formatDate.value.format, reverse.value, quoteList);
    });
    var rows = _toConsumableArray(bmRows);
    var data = rows.reduce(function (acc, item, idx) {
      var _item$meta, _item$meta2, _item$meta3;
      var name = item.metricName;
      var meta = item === null || item === void 0 || (_item$meta = item.meta) === null || _item$meta === void 0 ? void 0 : _item$meta.metricData;
      var optionalCategList = (item === null || item === void 0 || (_item$meta2 = item.meta) === null || _item$meta2 === void 0 ? void 0 : _item$meta2.allowedCategories) || [];
      var show = !(item !== null && item !== void 0 && (_item$meta3 = item.meta) !== null && _item$meta3 !== void 0 && _item$meta3.hideOnEarningsTable) && isValidCategory(optionalCategList, industryDesc);
      var initialProps = getInitialProps(meta);
      var hideRowValues = false;
      var _getRowData = getRowData(initialProps, periods, reverse.value, name),
        estimations = _getRowData.estimations,
        actuals = _getRowData.actuals,
        differences = _getRowData.differences;
      var isInvalidActual = Object.keys(actuals).every(function (key) {
        return Number.isNaN(actuals[key].value);
      });
      if (isInvalidActual) {
        hideRowValues = true;
      }
      if (show && !hideRowValues) {
        var estimatedObj = getEstimatedObj(name, estimations);
        var actualObj = getActualsObj(name, actuals);
        var diffObj = getDiffObj(differences);
        var blankItems = getBlankObj(estimatedObj);
        var blankObj = _objectSpread({
          title: "blank"
        }, blankItems);
        if (idx === 0) {
          acc.push(blankObj);
        }
        acc.push(_objectSpread(_objectSpread({}, estimatedObj), {}, {
          meta: _objectSpread(_objectSpread({}, estimatedObj.meta), {}, {
            isEstimate: true
          })
        }));
        acc.push(_objectSpread(_objectSpread({}, actualObj), {}, {
          meta: _objectSpread(_objectSpread({}, actualObj.meta), {}, {
            isActual: true
          })
        }));
        acc.push(_objectSpread(_objectSpread({}, diffObj), {}, {
          meta: _objectSpread(_objectSpread({}, diffObj.meta), {}, {
            isDiff: true
          })
        }));
        if (idx !== rows.length - 1) {
          acc.push(blankObj);
        }
      }
      return acc;
    }, []);
    var isLastBlank = data.length && ((_data = data[data.length - 1]) === null || _data === void 0 ? void 0 : _data.title) === "";
    if (isLastBlank) {
      data.pop();
    }
    return [].concat(_toConsumableArray(firstRows), _toConsumableArray(data));
  });
  return {
    data: data,
    earnings: earnings,
    earningsHeaders: earningsHeaders,
    currentPeriods: currentPeriods,
    loading: loading
  };
};
export default beatsAndMisses;