// LINK static/fmp_worker.js:9
const fmpTemplates = [
  {
    statement: "Income Statement",
    keys: [
      {
        key: ["revenue_is"],
        tikrKey: "revenue",
        name: "Revenue",
        tikrdisplay: "1",
        format: "bold",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["revenue_is"],
        tikrKey: "revenue",
        name: "% Change YoY",
        tikrdisplay: "1",
        format: "bold italic",
        formula: "dxdt",
        multiplyby: 1,
        type: "pct",
      },
      {
        key: ["costOfRevenue_is"],
        tikrKey: "costOfRevenue",
        name: "Cost Of Goods Sold",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: -1,
        type: "float",
      },
      {
        key: ["grossProfit_is"],
        tikrKey: "grossProfit",
        name: "Gross Profit",
        tikrdisplay: "1",
        format: "bold lineabove",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["grossProfit_is"],
        tikrKey: "grossProfit",
        name: "% Change YoY",
        tikrdisplay: "1",
        format: "bold italic",
        formula: "dxdt",
        multiplyby: 1,
        type: "pct",
      },
      {
        key: ["grossProfit_is", "revenue_is"],
        tikrKey: "grossProfit",
        name: "% Gross Margins",
        tikrdisplay: "1",
        format: "bold italic pb-3",
        formula: "div",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["sellingGeneralAndAdministrativeExpenses_is"],
        tikrKey: "sellingGeneralAndAdministrativeExpenses",
        name: "Selling General & Admin Expenses",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: -1,
        type: "float",
      },
      {
        key: ["researchAndDevelopmentExpenses_is"],
        tikrKey: "researchAndDevelopmentExpenses",
        name: "Research And Development Expenses",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: -1,
        type: "float",
      },
      {
        key: ["otherExpenses_is"],
        tikrKey: "otherExpenses",
        name: "Other Expenses",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: -1,
        type: "float",
      },
      {
        key: ["operatingExpenses_is"],
        tikrKey: "operatingExpenses",
        name: "Operating Expenses",
        tikrdisplay: "1",
        format: "bold lineabove",
        formula: "val",
        multiplyby: -1,
        type: "float",
      },
      {
        key: ["operatingIncome_is"],
        tikrKey: "operatingIncome",
        name: "Operating Income",
        tikrdisplay: "1",
        format: "bold",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["operatingIncome_is"],
        tikrKey: "operatingIncome",
        name: "% Change YoY",
        tikrdisplay: "1",
        format: "bold italic",
        formula: "dxdt",
        multiplyby: 1,
        type: "pct",
      },
      {
        key: ["operatingIncome_is", "revenue_is"],
        tikrKey: "operatingIncome",
        name: "% Operating Margins",
        tikrdisplay: "1",
        format: "bold italic pb-3",
        formula: "div",
        multiplyby: 1,
        type: "float",
      },
      // {
      //   key: ["operatingIncomeRatio_is"],
      //   tikrKey: "operatingIncomeRatio",
      //   name: "% Operating Margins FMP",
      //   tikrdisplay: "1",
      //   format: "bold italic pb-3",
      //   formula: "val",
      //   multiplyby: 1,
      //   type: "pct",
      // },
      {
        key: ["interestIncome_is"],
        tikrKey: "interestIncome",
        name: "Interest Income",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["interestExpense_is"],
        tikrKey: "interestExpense",
        name: "Interest Expense",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: -1,
        type: "float",
      },
      {
        key: ["totalOtherIncomeExpensesNet_is"],
        tikrKey: "totalOtherIncomeExpensesNet",
        name: "Total Other Income/Expenses Net",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["incomeBeforeTax_is"],
        tikrKey: "incomeBeforeTax",
        name: "Earnings Before Tax",
        tikrdisplay: "1",
        format: "bold",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["incomeBeforeTax_is"],
        tikrKey: "incomeBeforeTax",
        name: "% Change YoY",
        tikrdisplay: "1",
        format: "bold italic",
        formula: "dxdt",
        multiplyby: 1,
        type: "pct",
      },
      {
        key: ["incomeBeforeTax_is", "revenue_is"],
        tikrKey: "incomeBeforeTax",
        name: "% EBT Margins",
        tikrdisplay: "1",
        format: "bold italic pb-3",
        formula: "div",
        multiplyby: 1,
        type: "float",
      },
      // {
      //   key: ["incomeBeforeTaxRatio_is"],
      //   tikrKey: "incomeBeforeTaxRatio",
      //   name: "% EBT Margins FMP",
      //   tikrdisplay: "1",
      //   format: "bold italic",
      //   formula: "val",
      //   multiplyby: 1,
      //   type: "pct",
      // },
      {
        key: ["incomeTaxExpense_is"],
        tikrKey: "incomeTaxExpense",
        name: "Income Tax Expense",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: -1,
        type: "float",
      },
      {
        key: ["incomeTaxExpense_is", "incomeBeforeTax_is"],
        tikrKey: "effectiveTaxRate",
        name: "Effective Tax Rate %",
        tikrdisplay: "1",
        format: "italic",
        formula: "div",
        multiplyby: 1,
        type: "pct",
      },
      {
        key: ["netIncome_is"],
        tikrKey: "netIncome",
        name: "Net Income",
        tikrdisplay: "1",
        format: "bold lineabove",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["netIncome_is"],
        tikrKey: "netIncome",
        name: "% Change YoY",
        tikrdisplay: "1",
        format: "bold italic",
        formula: "dxdt",
        multiplyby: 1,
        type: "pct",
      },
      {
        key: ["netIncome_is", "revenue_is"],
        tikrKey: "netIncome",
        name: "% Net Income Margins",
        tikrdisplay: "1",
        format: "bold italic pb-3",
        formula: "div",
        multiplyby: 1,
        type: "float",
      },
      // {
      //   key: ["netIncomeRatio_is"],
      //   tikrKey: "netIncomeRatio",
      //   name: "% Net Income Margins FMP",
      //   tikrdisplay: "1",
      //   format: "bold italic pb-3",
      //   formula: "val",
      //   multiplyby: 1,
      //   type: "pct",
      // },
      {
        key: ["weightedAverageShsOutDil_is"],
        tikrKey: "weightedAverageShsOutDil",
        name: "Diluted Weighted Average Shares Outstanding",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["weightedAverageShsOutDil_is"],
        tikrKey: "weightedAverageShsOutDil",
        name: "% Change YoY",
        tikrdisplay: "1",
        format: "italic pb-3",
        formula: "dxdt",
        multiplyby: 1,
        type: "pct",
      },
      {
        key: ["epsdiluted_is"],
        tikrKey: "epsdiluted",
        name: "EPS Diluted",
        tikrdisplay: "1",
        format: "bold",
        formula: "val", // TODO need a way to say don't change k / MM / B
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["epsdiluted_is"],
        tikrKey: "epsdiluted",
        name: "% Change YoY",
        tikrdisplay: "1",
        format: "bold italic",
        formula: "dxdt",
        multiplyby: 1,
        type: "pct",
      },
      {
        key: [""],
        tikrKey: "",
        name: "Supplementary Data:",
        tikrdisplay: "1",
        format: "bold italic pb-3 pt-3",
        formula: "h3",
        multiplyby: 1,
        type: "h3",
      },
      {
        key: ["eps_is"],
        tikrKey: "eps",
        name: "Basic EPS",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["eps_is"],
        tikrKey: "eps",
        name: "% Change YoY",
        tikrdisplay: "1",
        format: "italic",
        formula: "dxdt",
        multiplyby: 1,
        type: "pct",
      },
      {
        key: ["weightedAverageShsOut_is"],
        tikrKey: "weightedAverageShsOut",
        name: "Basic Weighted Average Shares Out.",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["weightedAverageShsOut_is"],
        tikrKey: "weightedAverageShsOut",
        name: "% Change YoY",
        tikrdisplay: "1",
        format: "italic",
        formula: "dxdt",
        multiplyby: 1,
        type: "pct",
      },
      {
        key: ["ebitda_is"],
        tikrKey: "ebitda",
        name: "EBITDA",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["ebitda_is"],
        tikrKey: "ebitda",
        name: "% Change YoY",
        tikrdisplay: "1",
        format: "italic",
        formula: "dxdt",
        multiplyby: 1,
        type: "pct",
      },
      {
        key: ["ebitda_is", "revenue_is"],
        tikrKey: "ebitda",
        name: "% EBITDA Margins",
        tikrdisplay: "1",
        format: "italic pb-3",
        formula: "div",
        multiplyby: 1,
        type: "float",
      },
      // {
      //   key: ["ebitdaratio_is"],
      //   tikrKey: "ebitdaratio",
      //   name: "% EBITDA Margins FMP",
      //   tikrdisplay: "1",
      //   format: "italic pb-3",
      //   formula: "val",
      //   multiplyby: 1,
      //   type: "pct",
      // },
      {
        key: ["depreciationAndAmortization_is"],
        tikrKey: "depreciationAndAmortization",
        name: "Depreciation and Amortization",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["generalAndAdministrativeExpenses_is"],
        tikrKey: "generalAndAdministrativeExpenses",
        name: "General and Administrative Expenses",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["sellingAndMarketingExpenses_is"],
        tikrKey: "sellingAndMarketingExpenses",
        name: "Selling And Marketing Expenses",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
    ],
  },
  {
    statement: "Balance Sheet",
    keys: [
      {
        key: ["cashAndCashEquivalents_bs"],
        tikrKey: "cashAndCashEquivalents",
        name: "Cash And Cash Equivalents",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["shortTermInvestments_bs"],
        tikrKey: "shortTermInvestments",
        name: "Short Term Investments",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["cashAndShortTermInvestments_bs"],
        tikrKey: "cashAndShortTermInvestments",
        name: "Total Cash And Short Term Investments",
        tikrdisplay: "1",
        format: "bold",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["netReceivables_bs"],
        tikrKey: "netReceivables",
        name: "Net Receivables",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["inventory_bs"],
        tikrKey: "inventory",
        name: "Inventory",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["otherCurrentAssets_bs"],
        tikrKey: "otherCurrentAssets",
        name: "Other Current Assets",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["totalCurrentAssets_bs"],
        tikrKey: "totalCurrentAssets",
        name: "Total Current Assets",
        tikrdisplay: "1",
        format: "bold",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["propertyPlantEquipmentNet_bs"],
        tikrKey: "propertyPlantEquipmentNet",
        name: "Property Plant Equipment Net",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["goodwill_bs"],
        tikrKey: "goodwill",
        name: "Goodwill",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["intangibleAssets_bs"],
        tikrKey: "intangibleAssets",
        name: "Intangible Assets",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["longTermInvestments_bs"],
        tikrKey: "longTermInvestments",
        name: "Long Term Investments",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["taxAssets_bs"],
        tikrKey: "taxAssets",
        name: "Tax Assets",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["otherNonCurrentAssets_bs"],
        tikrKey: "otherNonCurrentAssets",
        name: "Other Non Current Assets",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["totalNonCurrentAssets_bs"],
        tikrKey: "totalNonCurrentAssets",
        name: "Total Non Current Assets",
        tikrdisplay: "1",
        format: "bold",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["otherAssets_bs"],
        tikrKey: "otherAssets",
        name: "Other Assets",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["totalAssets_bs"],
        tikrKey: "totalAssets",
        name: "Total Assets",
        tikrdisplay: "1",
        format: "bold lineabove pb-3",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["accountPayables_bs"],
        tikrKey: "accountPayables",
        name: "Account Payables",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["shortTermDebt_bs"],
        tikrKey: "shortTermDebt",
        name: "Short Term Debt",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["taxPayables_bs"],
        tikrKey: "taxPayables",
        name: "Tax Payables",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["deferredRevenue_bs"],
        tikrKey: "deferredRevenue",
        name: "Deferred Revenue",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["otherCurrentLiabilities_bs"],
        tikrKey: "otherCurrentLiabilities",
        name: "Other Current Liabilities",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["totalCurrentLiabilities_bs"],
        tikrKey: "totalCurrentLiabilities",
        name: "Total Current Liabilities",
        tikrdisplay: "1",
        format: "bold",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["longTermDebt_bs"],
        tikrKey: "longTermDebt",
        name: "Long Term Debt",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["deferredRevenueNonCurrent_bs"],
        tikrKey: "deferredRevenueNonCurrent",
        name: "Deferred Revenue Non Current",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["deferredTaxLiabilitiesNonCurrent_bs"],
        tikrKey: "deferredTaxLiabilitiesNonCurrent",
        name: "Deferred Tax Liabilities Non Current",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["otherNonCurrentLiabilities_bs"],
        tikrKey: "otherNonCurrentLiabilities",
        name: "Other Non Current Liabilities",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["totalNonCurrentLiabilities_bs"],
        tikrKey: "totalNonCurrentLiabilities",
        name: "Total Non Current Liabilities",
        tikrdisplay: "1",
        format: "bold",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["otherLiabilities_bs"],
        tikrKey: "otherLiabilities",
        name: "Other Liabilities",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["capitalLeaseObligations_bs"],
        tikrKey: "capitalLeaseObligations",
        name: "Capital Lease Obligations",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["totalLiabilities_bs"],
        tikrKey: "totalLiabilities",
        name: "Total Liabilities",
        tikrdisplay: "1",
        format: "bold lineabove pb-3",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["preferredStock_bs"],
        tikrKey: "preferredStock",
        name: "Preferred Stock",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["commonStock_bs"],
        tikrKey: "commonStock",
        name: "Common Stock",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["retainedEarnings_bs"],
        tikrKey: "retainedEarnings",
        name: "Retained Earnings",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["accumulatedOtherComprehensiveIncomeLoss_bs"],
        tikrKey: "accumulatedOtherComprehensiveIncomeLoss",
        name: "Accumulated Other Comprehensive Income Loss",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["othertotalStockholdersEquity_bs"],
        tikrKey: "othertotalStockholdersEquity",
        name: "Other Total Stockholders Equity",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["totalStockholdersEquity_bs"],
        tikrKey: "totalStockholdersEquity",
        name: "Total Stockholders Equity",
        tikrdisplay: "1",
        format: "bold lineabove",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["totalLiabilitiesAndStockholdersEquity_bs"],
        tikrKey: "totalLiabilitiesAndStockholdersEquity",
        name: "Total Liabilities And Stockholders Equity",
        tikrdisplay: "1",
        format: "bold lineabove",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["minorityInterest_bs"],
        tikrKey: "minorityInterest",
        name: "Minority Interest",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["totalEquity_bs"],
        tikrKey: "totalEquity",
        name: "Total Equity",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["totalLiabilitiesAndTotalEquity_bs"],
        tikrKey: "totalLiabilitiesAndTotalEquity",
        name: "Total Liabilities And Total Equity",
        tikrdisplay: "1",
        format: "bold lineabove pb-3",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: [""],
        tikrKey: "",
        name: "Supplementary Data:",
        tikrdisplay: "1",
        format: "bold italic pb-3 pt-3",
        formula: "h3",
        multiplyby: 1,
        type: "h3",
      },
      {
        key: ["totalDebt_bs"],
        tikrKey: "totalDebt",
        name: "Total Debt",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["netDebt_bs"],
        tikrKey: "netDebt",
        name: "Net Debt",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      // {
      //   key: ["totalInvestments_bs"],
      //   tikrKey: "totalInvestments",
      //   name: "Total Investments",
      //   tikrdisplay: "1",
      //   format: "normal",
      //   formula: "val",
      //   multiplyby: 1,
      //   type: "float",
      // },
    ],
  },
  {
    statement: "Cash Flow Statement",
    keys: [
      {
        key: ["netIncome_cf"],
        tikrKey: "netIncome",
        name: "Net Income",
        tikrdisplay: "1",
        format: "bold",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["depreciationAndAmortization_cf"],
        tikrKey: "depreciationAndAmortization",
        name: "Depreciation And Amortization",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["deferredIncomeTax_cf"],
        tikrKey: "deferredIncomeTax",
        name: "Deferred Income Tax",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["stockBasedCompensation_cf"],
        tikrKey: "stockBasedCompensation",
        name: "Stock Based Compensation",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["changeInWorkingCapital_cf"],
        tikrKey: "changeInWorkingCapital",
        name: "Change In Net Working Capital",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["otherNonCashItems_cf"],
        tikrKey: "otherNonCashItems",
        name: "Other Non Cash Items",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["netCashProvidedByOperatingActivities_cf"],
        tikrKey: "netCashProvidedByOperatingActivities",
        name: "Cash from Operations",
        tikrdisplay: "1",
        format: "bold lineabove pb-3",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["investmentsInPropertyPlantAndEquipment_cf"],
        tikrKey: "investmentsInPropertyPlantAndEquipment", // FIXME: example of different tikrkey..
        name: "Capital Expenditure",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["acquisitionsNet_cf"],
        tikrKey: "acquisitionsNet",
        name: "Cash Acquisitions",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["purchasesOfInvestments_cf"],
        tikrKey: "purchasesOfInvestments",
        name: "Purchases Of Investments",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["salesMaturitiesOfInvestments_cf"],
        tikrKey: "salesMaturitiesOfInvestments",
        name: "Sales / Maturities Of Investments",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["otherInvestingActivites_cf"],
        tikrKey: "otherInvestingActivites",
        name: "Other Investing Activites",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["netCashUsedForInvestingActivites_cf"],
        tikrKey: "netCashUsedForInvestingActivites",
        name: "Cash from Investing",
        tikrdisplay: "1",
        format: "bold lineabove pb-3",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["debtRepayment_cf"],
        tikrKey: "debtRepayment",
        name: "Debt Repayment",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["commonStockIssued_cf"],
        tikrKey: "commonStockIssued",
        name: "Common Stock Issued",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["commonStockRepurchased_cf"],
        tikrKey: "commonStockRepurchased",
        name: "Common Stock Repurchased",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["dividendsPaid_cf"],
        tikrKey: "dividendsPaid",
        name: "Dividends Paid",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["otherFinancingActivites_cf"],
        tikrKey: "otherFinancingActivites",
        name: "Other Financing Activites",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["netCashUsedProvidedByFinancingActivities_cf"],
        tikrKey: "netCashUsedProvidedByFinancingActivities",
        name: "Cash from Financing",
        tikrdisplay: "1",
        format: "bold lineabove",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["effectOfForexChangesOnCash_cf"],
        tikrKey: "effectOfForexChangesOnCash",
        name: "Effect Of Forex Changes On Cash",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["netChangeInCash_cf"],
        tikrKey: "netChangeInCash",
        name: "Net Change In Cash",
        tikrdisplay: "1",
        format: "bold lineabove pb-3",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: [""],
        tikrKey: "",
        name: "Supplementary Data:",
        tikrdisplay: "1",
        format: "bold italic pb-3 pt-3",
        formula: "h3",
        multiplyby: 1,
        type: "h3",
      },
      {
        key: ["freeCashFlow_cf"],
        tikrKey: "freeCashFlow",
        name: "Free Cash Flow",
        tikrdisplay: "1",
        format: "bold",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["freeCashFlow_cf"],
        tikrKey: "freeCashFlow",
        name: "% Change YoY",
        tikrdisplay: "1",
        format: "bold italic",
        formula: "dxdt",
        multiplyby: 1,
        type: "pct",
      },
      {
        key: ["freeCashFlow_cf", "revenue_is"],
        tikrKey: "fcfMargin",
        name: "% Free Cash Flow Margins",
        tikrdisplay: "1",
        format: "bold italic pb-3",
        formula: "div",
        multiplyby: 1,
        type: "pct",
      },
      {
        key: ["cashAtBeginningOfPeriod_cf"],
        tikrKey: "cashAtBeginningOfPeriod",
        name: "Cash At Beginning Of Period",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["cashAtEndOfPeriod_cf"],
        tikrKey: "cashAtEndOfPeriod",
        name: "Cash At End Of Period",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
    ],
  },
  {
    statement: "Ratios",
    keys: [
      {
        key: [""],
        tikrKey: "",
        name: "Return Ratios:",
        tikrdisplay: "1",
        format: "bold italic pb-3 pt-3",
        formula: "h3",
        multiplyby: 1,
        type: "h3",
      },
      {
        key: ["returnOnAssets_r"],
        tikrKey: "returnOnAssets_r",
        name: "Return on Assets %",
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["returnOnCapitalEmployed_r"],
        tikrKey: "returnOnCapitalEmployed_r",
        name: "Return on Capital Employed %",
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["returnOnEquity_r"],
        tikrKey: "returnOnEquity_r",
        name: "Return On Equity %",
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
        type: "float",
      },
      {
        key: [""],
        tikrKey: "",
        name: "Margin Analysis:",
        tikrdisplay: "1",
        format: "bold italic pb-3 pt-3",
        formula: "h3",
        multiplyby: 1,
        type: "h3",
      },
      {
        key: ["grossProfitMargin_r"],
        tikrKey: "grossProfitMargin_r",
        name: "Gross Profit Margin %",
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["operatingProfitMargin_r"],
        tikrKey: "operatingProfitMargin_r",
        name: "Operating Profit Margin %",
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["pretaxProfitMargin_r"],
        tikrKey: "pretaxProfitMargin_r",
        name: "Pre-Tax Profit Margin %",
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["netProfitMargin_r"],
        tikrKey: "netProfitMargin_r",
        name: "Net Profit Margin %",
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["netIncomePerEBT_r"],
        tikrKey: "netIncomePerEBT_r",
        name: "Net Income / EBT %",
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["effectiveTaxRate_r"],
        tikrKey: "effectiveTaxRate_r",
        name: "Effective Tax Rate %",
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["operatingCashFlowSalesRatio_r"],
        tikrKey: "operatingCashFlowSalesRatio_r",
        name: "Operating Cash Flow / Sales %",
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["freeCashFlowOperatingCashFlowRatio_r"],
        tikrKey: "freeCashFlowOperatingCashFlowRatio_r",
        name: "FCF / Operating Cash Flow %",
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
        type: "float",
      },
      {
        key: [""],
        tikrKey: "",
        name: "Asset Turnover:",
        tikrdisplay: "1",
        format: "bold italic pb-3 pt-3",
        formula: "h3",
        multiplyby: 1,
        type: "h3",
      },
      {
        key: ["assetTurnover_r"],
        tikrKey: "assetTurnover_r",
        name: "Asset Turnover",
        tikrdisplay: "1",
        format: "normal",
        formula: "turns",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["fixedAssetTurnover_r"],
        tikrKey: "fixedAssetTurnover_r",
        name: "Fixed Assets Turnover",
        tikrdisplay: "1",
        format: "normal",
        formula: "turns",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["receivablesTurnover_r"],
        tikrKey: "receivablesTurnover_r",
        name: "Receivables Turnover",
        tikrdisplay: "1",
        format: "normal",
        formula: "turns",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["inventoryTurnover_r"],
        tikrKey: "inventoryTurnover_r",
        name: "Inventory Turnover",
        tikrdisplay: "1",
        format: "normal",
        formula: "turns",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["payablesTurnover_r"],
        tikrKey: "payablesTurnover_r",
        name: "Payables Turnover",
        tikrdisplay: "1",
        format: "normal",
        formula: "turns",
        multiplyby: 1,
        type: "float",
      },
      {
        key: [""],
        tikrKey: "",
        name: "Short Term Liquidity:",
        tikrdisplay: "1",
        format: "bold italic pb-3 pt-3",
        formula: "h3",
        multiplyby: 1,
        type: "h3",
      },
      {
        key: ["currentRatio_r"],
        tikrKey: "currentRatio_r",
        name: "Current Ratio",
        tikrdisplay: "1",
        format: "normal",
        formula: "turns",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["quickRatio_r"],
        tikrKey: "quickRatio_r",
        name: "Quick Ratio",
        tikrdisplay: "1",
        format: "normal",
        formula: "turns",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["cashRatio_r"],
        tikrKey: "cashRatio_r",
        name: "Cash/Current Liabilities",
        tikrdisplay: "1",
        format: "normal",
        formula: "turns",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["shortTermCoverageRatios_r"],
        tikrKey: "shortTermCoverageRatios_r",
        name: "Operating Cash Flow / Short Term Debt",
        tikrdisplay: "1",
        format: "normal",
        formula: "turns",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["capitalExpenditureCoverageRatio_r"],
        tikrKey: "capitalExpenditureCoverageRatio_r",
        name: "Operating Cash Flow / CAPEX",
        tikrdisplay: "1",
        format: "normal",
        formula: "turns",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["dividendPaidAndCapexCoverageRatio_r"],
        tikrKey: "dividendPaidAndCapexCoverageRatio_r",
        name: "Operating Cash Flow / (CAPEX + Dividends)",
        tikrdisplay: "1",
        format: "normal",
        formula: "turns",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["dividendPayoutRatio_r"],
        tikrKey: "dividendPayoutRatio_r",
        name: "Dividends per Share / EPS",
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["cashConversionCycle_r"],
        tikrKey: "cashConversionCycle_r",
        name: "Avg. Cash Conversion Cycle",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["daysOfSalesOutstanding_r"],
        tikrKey: "daysOfSalesOutstanding_r",
        name: "Avg. Days Sales Outstanding",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["daysOfInventoryOutstanding_r"],
        tikrKey: "daysOfInventoryOutstanding_r",
        name: "Avg. Days Outstanding Inventory",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["daysOfPayablesOutstanding_r"],
        tikrKey: "daysOfPayablesOutstanding_r",
        name: "Avg. Days Payable Outstanding",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: [""],
        tikrKey: "",
        name: "Long Term Solvency:",
        tikrdisplay: "1",
        format: "bold italic pb-3 pt-3",
        formula: "h3",
        multiplyby: 1,
        type: "h3",
      },
      {
        key: ["debtEquityRatio_r"],
        tikrKey: "debtEquityRatio_r",
        name: "Total Debt/Equity",
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["totalDebtToCapitalization_r"],
        tikrKey: "totalDebtToCapitalization_r",
        name: "Total Debt/Capital",
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["companyEquityMultiplier_r"],
        tikrKey: "companyEquityMultiplier_r",
        name: "Equity Multiplier",
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["debtRatio_r"],
        tikrKey: "debtRatio_r",
        name: "Total Debt/Total Assets",
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["interestCoverage_r"],
        tikrKey: "interestCoverage_r",
        name: "EBIT/Interest Expense",
        tikrdisplay: "1",
        format: "normal",
        formula: "turns",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["cashFlowToDebtRatio_r"],
        tikrKey: "cashFlowToDebtRatio_r",
        name: "​Cash Flow/Debt",
        tikrdisplay: "1",
        format: "normal",
        formula: "turns",
        multiplyby: 1,
        type: "float",
      },
      {
        key: [""],
        tikrKey: "",
        name: "Valuation:",
        tikrdisplay: "1",
        format: "bold italic pb-3 pt-3",
        formula: "h3",
        multiplyby: 1,
        type: "h3",
      },
      {
        key: ["priceToSalesRatio_r"],
        tikrKey: "priceToSalesRatio_r",
        name: "Price/Sales",
        tikrdisplay: "1",
        format: "normal",
        formula: "turns",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["enterpriseValueMultiple_r"],
        tikrKey: "enterpriseValueMultiple_r",
        name: "EV/EBITDA",
        tikrdisplay: "1",
        format: "normal",
        formula: "turns",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["priceEarningsRatio_r"],
        tikrKey: "priceEarningsRatio_r",
        name: "Price/Earnings (P/E)",
        tikrdisplay: "1",
        format: "normal",
        formula: "turns",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["priceBookValueRatio_r"],
        tikrKey: "priceBookValueRatio_r",
        name: "Price/Book Value",
        tikrdisplay: "1",
        format: "normal",
        formula: "turns",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["priceToFreeCashFlowsRatio_r"],
        tikrKey: "priceToFreeCashFlowsRatio_r",
        name: "Price/FCF",
        tikrdisplay: "1",
        format: "normal",
        formula: "turns",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["priceCashFlowRatio_r"],
        tikrKey: "priceCashFlowRatio_r",
        name: "Price/Operating Cash Flow",
        tikrdisplay: "1",
        format: "normal",
        formula: "turns",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["dividendYield_r"],
        tikrKey: "dividendYield_r",
        name: "Dividend Yield",
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["operatingCashFlowPerShare_r"],
        tikrKey: "operatingCashFlowPerShare_r",
        name: "Operating Cash Flow/Share",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["freeCashFlowPerShare_r"],
        tikrKey: "freeCashFlowPerShare_r",
        name: "FCF/Share",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
      {
        key: ["cashPerShare_r"],
        tikrKey: "cashPerShare_r",
        name: "Cash/Share",
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        type: "float",
      },
    ],
  },
]

const previousObjects = [
  {
    dataitemid: 100186,
    dataitemname: "Revenue ",
    lineorder: 0,
    tikrdisplay: "1",
    format: "normal",
    formula: "val",
    multiplyby: 1,
    mean: 100180,
    median: 100181,
    high: 100182,
    low: 100183,
    numEst: 100184,
    stdDev: 100185,
    guideMid: 22322,
    guideHigh: 22390,
    guideLow: 22450,
  },
  {
    dataitemid: ["100180"],
    dataitemname: "NTM Revenues",
    format: "normal",
    formula: "val",
    type: "float",
    lineorder: 23,
    multiplyby: 1,
    tikrdisplay: "1",
    numerator: "tev",
    denominator: "100180",
  },
  {
    dataitemid: ["ntm_gp"],
    dataitemname: "NTM Gross Profit",
    format: "normal",
    formula: "val",
    type: "float",
    lineorder: 23,
    multiplyby: 1,
    tikrdisplay: "0",
    numerator: "tev",
    denominator: "ntm_gp",
  },
]

// LINK static/fmp_worker.js:1662
const fmpKeyObj = {
  revenue: {
    unitId: 2,
    divideBy: 1000000,
  },
  costOfRevenue: {
    unitId: 2,
    divideBy: 1000000,
  },
  grossProfit: {
    unitId: 2,
    divideBy: 1000000,
  },
  sellingGeneralAndAdministrativeExpenses: {
    unitId: 2,
    divideBy: 1000000,
  },
  researchAndDevelopmentExpenses: {
    unitId: 2,
    divideBy: 1000000,
  },
  otherExpenses: {
    unitId: 2,
    divideBy: 1000000,
  },
  operatingExpenses: {
    unitId: 2,
    divideBy: 1000000,
  },
  operatingIncome: {
    unitId: 2,
    divideBy: 1000000,
  },
  operatingIncomeRatio: {
    unitId: 0,
    divideBy: 0.01,
  },
  interestIncome: {
    unitId: 2,
    divideBy: 1000000,
  },
  interestExpense: {
    unitId: 2,
    divideBy: 1000000,
  },
  totalOtherIncomeExpensesNet: {
    unitId: 2,
    divideBy: 1000000,
  },
  incomeBeforeTax: {
    unitId: 2,
    divideBy: 1000000,
  },
  incomeBeforeTaxRatio: {
    unitId: 0,
    divideBy: 0.01,
  },
  incomeTaxExpense: {
    unitId: 2,
    divideBy: 1000000,
  },
  netIncome: {
    unitId: 2,
    divideBy: 1000000,
  },
  netIncomeRatio: {
    unitId: 0,
    divideBy: 0.01,
  },
  epsdiluted: {
    unitId: 0,
    divideBy: 1,
  },
  weightedAverageShsOutDil: {
    unitId: 2,
    divideBy: 1000000,
  },
  eps: {
    unitId: 0,
    divideBy: 1,
  },
  weightedAverageShsOut: {
    unitId: 2,
    divideBy: 1000000,
  },
  ebitda: {
    unitId: 2,
    divideBy: 1000000,
  },
  ebitdaratio: {
    unitId: 0,
    divideBy: 0.01,
  },
  depreciationAndAmortization: {
    unitId: 2,
    divideBy: 1000000,
  },
  generalAndAdministrativeExpenses: {
    unitId: 2,
    divideBy: 1000000,
  },
  sellingAndMarketingExpenses: {
    unitId: 2,
    divideBy: 1000000,
  },
  cashAndCashEquivalents: {
    unitId: 2,
    divideBy: 1000000,
  },
  shortTermInvestments: {
    unitId: 2,
    divideBy: 1000000,
  },
  cashAndShortTermInvestments: {
    unitId: 2,
    divideBy: 1000000,
  },
  netReceivables: {
    unitId: 2,
    divideBy: 1000000,
  },
  inventory: {
    unitId: 2,
    divideBy: 1000000,
  },
  otherCurrentAssets: {
    unitId: 2,
    divideBy: 1000000,
  },
  totalCurrentAssets: {
    unitId: 2,
    divideBy: 1000000,
  },
  propertyPlantEquipmentNet: {
    unitId: 2,
    divideBy: 1000000,
  },
  goodwill: {
    unitId: 2,
    divideBy: 1000000,
  },
  intangibleAssets: {
    unitId: 2,
    divideBy: 1000000,
  },
  longTermInvestments: {
    unitId: 2,
    divideBy: 1000000,
  },
  taxAssets: {
    unitId: 2,
    divideBy: 1000000,
  },
  otherNonCurrentAssets: {
    unitId: 2,
    divideBy: 1000000,
  },
  totalNonCurrentAssets: {
    unitId: 2,
    divideBy: 1000000,
  },
  otherAssets: {
    unitId: 2,
    divideBy: 1000000,
  },
  totalAssets: {
    unitId: 2,
    divideBy: 1000000,
  },
  accountPayables: {
    unitId: 2,
    divideBy: 1000000,
  },
  shortTermDebt: {
    unitId: 2,
    divideBy: 1000000,
  },
  taxPayables: {
    unitId: 2,
    divideBy: 1000000,
  },
  deferredRevenue: {
    unitId: 2,
    divideBy: 1000000,
  },
  otherCurrentLiabilities: {
    unitId: 2,
    divideBy: 1000000,
  },
  totalCurrentLiabilities: {
    unitId: 2,
    divideBy: 1000000,
  },
  longTermDebt: {
    unitId: 2,
    divideBy: 1000000,
  },
  deferredRevenueNonCurrent: {
    unitId: 2,
    divideBy: 1000000,
  },
  deferredTaxLiabilitiesNonCurrent: {
    unitId: 2,
    divideBy: 1000000,
  },
  otherNonCurrentLiabilities: {
    unitId: 2,
    divideBy: 1000000,
  },
  totalNonCurrentLiabilities: {
    unitId: 2,
    divideBy: 1000000,
  },
  otherLiabilities: {
    unitId: 2,
    divideBy: 1000000,
  },
  capitalLeaseObligations: {
    unitId: 2,
    divideBy: 1000000,
  },
  totalLiabilities: {
    unitId: 2,
    divideBy: 1000000,
  },
  preferredStock: {
    unitId: 2,
    divideBy: 1000000,
  },
  commonStock: {
    unitId: 2,
    divideBy: 1000000,
  },
  retainedEarnings: {
    unitId: 2,
    divideBy: 1000000,
  },
  accumulatedOtherComprehensiveIncomeLoss: {
    unitId: 2,
    divideBy: 1000000,
  },
  othertotalStockholdersEquity: {
    unitId: 2,
    divideBy: 1000000,
  },
  totalStockholdersEquity: {
    unitId: 2,
    divideBy: 1000000,
  },
  totalLiabilitiesAndStockholdersEquity: {
    unitId: 2,
    divideBy: 1000000,
  },
  minorityInterest: {
    unitId: 2,
    divideBy: 1000000,
  },
  totalEquity: {
    unitId: 2,
    divideBy: 1000000,
  },
  totalLiabilitiesAndTotalEquity: {
    unitId: 2,
    divideBy: 1000000,
  },
  totalDebt: {
    unitId: 2,
    divideBy: 1000000,
  },
  netDebt: {
    unitId: 2,
    divideBy: 1000000,
  },
  totalInvestments: {
    unitId: 2,
    divideBy: 1000000,
  },
  deferredIncomeTax: {
    unitId: 2,
    divideBy: 1000000,
  },
  stockBasedCompensation: {
    unitId: 2,
    divideBy: 1000000,
  },
  changeInWorkingCapital: {
    unitId: 2,
    divideBy: 1000000,
  },
  otherNonCashItems: {
    unitId: 2,
    divideBy: 1000000,
  },
  netCashProvidedByOperatingActivities: {
    unitId: 2,
    divideBy: 1000000,
  },
  investmentsInPropertyPlantAndEquipment: {
    unitId: 2,
    divideBy: 1000000,
  },
  acquisitionsNet: {
    unitId: 2,
    divideBy: 1000000,
  },
  purchasesOfInvestments: {
    unitId: 2,
    divideBy: 1000000,
  },
  salesMaturitiesOfInvestments: {
    unitId: 2,
    divideBy: 1000000,
  },
  otherInvestingActivites: {
    unitId: 2,
    divideBy: 1000000,
  },
  netCashUsedForInvestingActivites: {
    unitId: 2,
    divideBy: 1000000,
  },
  debtRepayment: {
    unitId: 2,
    divideBy: 1000000,
  },
  commonStockIssued: {
    unitId: 2,
    divideBy: 1000000,
  },
  commonStockRepurchased: {
    unitId: 2,
    divideBy: 1000000,
  },
  dividendsPaid: {
    unitId: 2,
    divideBy: 1000000,
  },
  otherFinancingActivites: {
    unitId: 2,
    divideBy: 1000000,
  },
  netCashUsedProvidedByFinancingActivities: {
    unitId: 2,
    divideBy: 1000000,
  },
  effectOfForexChangesOnCash: {
    unitId: 2,
    divideBy: 1000000,
  },
  netChangeInCash: {
    unitId: 2,
    divideBy: 1000000,
  },
  freeCashFlow: {
    unitId: 2,
    divideBy: 1000000,
  },
  cashAtEndOfPeriod: {
    unitId: 2,
    divideBy: 1000000,
  },
  cashAtBeginningOfPeriod: {
    unitId: 2,
    divideBy: 1000000,
  },
  // RATIOS BELOW
  returnOnAssets_r: {
    unitId: 0,
    divideBy: 0.01,
  },
  returnOnCapitalEmployed_r: {
    unitId: 0,
    divideBy: 0.01,
  },
  returnOnEquity_r: {
    unitId: 0,
    divideBy: 0.01,
  },
  grossProfitMargin_r: {
    unitId: 0,
    divideBy: 0.01,
  },
  operatingProfitMargin_r: {
    unitId: 0,
    divideBy: 0.01,
  },
  pretaxProfitMargin_r: {
    unitId: 0,
    divideBy: 0.01,
  },
  netProfitMargin_r: {
    unitId: 0,
    divideBy: 0.01,
  },
  netIncomePerEBT_r: {
    unitId: 0,
    divideBy: 0.01,
  },
  effectiveTaxRate_r: {
    unitId: 0,
    divideBy: 0.01,
  },
  operatingCashFlowSalesRatio_r: {
    unitId: 0,
    divideBy: 0.01,
  },
  freeCashFlowOperatingCashFlowRatio_r: {
    unitId: 0,
    divideBy: 0.01,
  },
  assetTurnover_r: {
    unitId: 0,
    divideBy: 1,
  },
  fixedAssetTurnover_r: {
    unitId: 0,
    divideBy: 1,
  },
  receivablesTurnover_r: {
    unitId: 0,
    divideBy: 1,
  },
  inventoryTurnover_r: {
    unitId: 0,
    divideBy: 1,
  },
  payablesTurnover_r: {
    unitId: 0,
    divideBy: 1,
  },
  currentRatio_r: {
    unitId: 0,
    divideBy: 1,
  },
  quickRatio_r: {
    unitId: 0,
    divideBy: 1,
  },
  cashRatio_r: {
    unitId: 0,
    divideBy: 1,
  },
  shortTermCoverageRatios_r: {
    unitId: 0,
    divideBy: 1,
  },
  capitalExpenditureCoverageRatio_r: {
    unitId: 0,
    divideBy: 1,
  },
  dividendPaidAndCapexCoverageRatio_r: {
    unitId: 0,
    divideBy: 1,
  },
  dividendPayoutRatio_r: {
    unitId: 0,
    divideBy: 0.01,
  },

  cashConversionCycle_r: {
    unitId: 0,
    divideBy: 1,
  },
  daysOfSalesOutstanding_r: {
    unitId: 0,
    divideBy: 1,
  },
  daysOfInventoryOutstanding_r: {
    unitId: 0,
    divideBy: 1,
  },
  daysOfPayablesOutstanding_r: {
    unitId: 0,
    divideBy: 1,
  },
  debtEquityRatio_r: {
    unitId: 0,
    divideBy: 0.01,
  },
  totalDebtToCapitalization_r: {
    unitId: 0,
    divideBy: 0.01,
  },
  companyEquityMultiplier_r: {
    unitId: 0,
    divideBy: 0.01,
  },
  debtRatio_r: {
    unitId: 0,
    divideBy: 0.01,
  },
  interestCoverage_r: {
    unitId: 0,
    divideBy: 1,
  },
  cashFlowToDebtRatio_r: {
    unitId: 0,
    divideBy: 1,
  },

  priceToSalesRatio_r: {
    unitId: 0,
    divideBy: 1,
  },
  enterpriseValueMultiple_r: {
    unitId: 0,
    divideBy: 1,
  },
  priceEarningsRatio_r: {
    unitId: 0,
    divideBy: 1,
  },
  priceBookValueRatio_r: {
    unitId: 0,
    divideBy: 1,
  },
  priceToFreeCashFlowsRatio_r: {
    unitId: 0,
    divideBy: 1,
  },
  priceCashFlowRatio_r: {
    unitId: 0,
    divideBy: 1,
  },
  dividendYield_r: {
    unitId: 0,
    divideBy: 0.01,
  },
  operatingCashFlowPerShare_r: {
    unitId: 0,
    divideBy: 1,
  },
  freeCashFlowPerShare_r: {
    unitId: 0,
    divideBy: 1,
  },
  cashPerShare_r: {
    unitId: 0,
    divideBy: 1,
  },
}

const fmpSegmentTemplate = {
  keys: [
    {
      dataitemid: [""],
      dataitemname: "Business Segments",
      tikrdisplay: "1",
      format: "bold italic pb-1 pt-3",
      formula: "h3",
      multiplyby: 1,
    },
    {
      dataitemid: "businessSegment",
      dataitemname: "Revenues",
      tikrdisplay: "1",
      format: "bold",
      formula: "val",
      multiplyby: 1,
    },
    {
      dataitemid: [""],
      dataitemname: "Geographic Segments",
      tikrdisplay: "1",
      format: "bold italic pb-1 pt-3",
      formula: "h3",
      multiplyby: 1,
    },
    {
      dataitemid: "geographySegment",
      dataitemname: "Revenues",
      tikrdisplay: "1",
      format: "bold",
      formula: "val",
      multiplyby: 1,
    },
  ],
  statement: "Segments, As Reported",
}

export { fmpTemplates, previousObjects, fmpKeyObj, fmpSegmentTemplate }
