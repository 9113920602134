import cloneDeep from "lodash/cloneDeep"
import { isEmpty } from "lodash"
import { fmpKeyObj, fmpTemplates } from "../templates/fmp_templates"
import { annualChangeCalc, divisionCalc } from "./fmpMath"
import { mergeKeys } from "./normalize"
import dayjs from "~/utils/tools/dayjs"
import {
  findFirstAvailableItemInObject,
  renameObjectProp,
} from "~/utils/tools/object"

const devStage = "dev"
const stage = process.env.LAMBDA_STAGE
const dev = devStage === stage

/**
 * fmpDateKeys object is utilized to standardize dateKeys within TIKR by
 * creating a map to the standard date keys (properties of fmpDateKeys)
 * to the keys used in the FmpStatement Objects
 * LINK: static/fmp_worker.js:2496
 */
const fmpDateKeys = {
  dateStr: ["date", "date_is", "date_bs", "date_cf", "date_r"], // string of the actual date
  filingDateStr: [
    "fillingDate",
    "fillingDate_is",
    "fillingDate_bs",
    "fillingDate_cf",
    "fillingDate_r",
  ], // string of the filing date
  acceptedDateStr: [
    "acceptedDate",
    "acceptedDate_is",
    "acceptedDate_bs",
    "acceptedDate_cf",
    "acceptedDate_r",
  ], // date time string of the accepted time?
  yearStr: [
    "calendarYear",
    "calendarYear_is",
    "calendarYear_bs",
    "calendarYear_cf",
    "calendarYear_r",
  ], // calendar year for the period
  quarterStr: ["period", "period_is", "period_bs", "period_cf", "period_r"], // quarter for the period or FY
  iso: [
    "reportedCurrency",
    "reportedCurrency_is",
    "reportedCurrency_bs",
    "reportedCurrency_cf",
    "reportedCurrency_r",
  ],
}

/**
 * commonObjKeys contains keys that will exist in the FmpStatement Objects
 * that are shared between statements and are keys whose values do not
 * contain any financial metrics. These keys can be skipped when processing
 * the raw response from FMP
 * LINK: static/fmp_worker.js:2480
 */
const commonObjKeys = {
  date: "2021-09-25",
  symbol: "AAPL",
  reportedCurrency: "USD",
  cik: "0000320193",
  fillingDate: "2021-10-29",
  acceptedDate: "2021-10-28 18:04:28",
  calendarYear: "2021",
  period: "FY",
  link: "https://www.sec.gov/Archives/edgar/data/320193/000032019321000105/0000320193-21-000105-index.htm",
  finalLink:
    "https://www.sec.gov/Archives/edgar/data/320193/000032019321000105/aapl-20210925.htm",
}

// LINK static/fmp_worker.js:2542
const createDateObj = (statementObj, dateKeysObj, suffix) => {
  const returnObj = {}

  Object.entries(dateKeysObj).forEach(([standardKey, statementKey]) => {
    const item = findFirstAvailableItemInObject({
      item: statementObj,
      keys: statementKey,
    })

    returnObj[standardKey] = item
  })

  const currKey = Object.keys(statementObj).find((key) => {
    if (statementObj?.[key]) {
      return Object.hasOwn(statementObj?.[key], "iso")
    }
    return false
  })

  const currObj = statementObj[currKey]

  const yearStr = returnObj.yearStr
  const quarterStr = returnObj.quarterStr

  returnObj.dateKey = `${yearStr}##${quarterStr}`
  returnObj.value = `${yearStr}##${quarterStr}`
  returnObj.text = `${yearStr}##${quarterStr}` // FIXME: this key should be set using dateFormatter in component
  const dateEpoch = new Date(returnObj.dateStr) // this is lazy and probably not right
  returnObj.dateEpoch = dateEpoch
  returnObj.timeVal = dayjs(returnObj.dateStr).valueOf()

  if (currObj) {
    returnObj.unauth = currObj?.unauth
    returnObj.pc = currObj.pc ?? 1
    returnObj.u = currObj.u
    returnObj.iso = currObj.iso ?? "USD"
  }

  return returnObj
}

// LINK static/fmp_worker.js:2584
const createFmpDateObjFromRawStatement = (
  rawStatementArr,
  fmpDateKeys,
  incomingDateObj = {}
) => {
  const dateObj = { ...incomingDateObj }
  rawStatementArr
    .filter((obj) => Object.keys(obj).length !== 0)
    .forEach((obj) => {
      const innerDateObj = createDateObj(obj, fmpDateKeys)
      const dateKey = innerDateObj.dateKey
      // save obj[dateKey]: innerDateObj for use later
      if (dateObj[dateKey]) {
        // date exists... compare deep?
      } else {
        dateObj[dateKey] = innerDateObj
      }
    })
  return dateObj
}

// LINK static/fmp_worker.js:2604
const processFmpFinancials = (rawFmpRes) => {
  const {
    is: rawIs,
    bs: rawBs,
    cf: rawCf,
    r: rawR,
    byGeo: rawGeo,
    bySegment: rawSegments,
  } = rawFmpRes

  const isDateObj = createFmpDateObjFromRawStatement(rawIs, fmpDateKeys)
  const bsDateObj = createFmpDateObjFromRawStatement(rawBs, fmpDateKeys)
  const cfDateObj = createFmpDateObjFromRawStatement(rawCf, fmpDateKeys)

  const dateObj = { ...isDateObj, ...bsDateObj, ...cfDateObj }

  const dateArr = Object.values(dateObj).map((obj) => {
    return {
      ...obj,
      ...renameObjectProp("pc", "exchangerate", obj),
      ...renameObjectProp("iso", "isocode", obj),
    }
  })
  dateArr.sort((b, a) => a.timeVal - b.timeVal)
  // Process IS, BS, CF Financial Statements
  const isResObj = processFmpStatement({
    rawStatement: rawIs,
    keysToIgnore: commonObjKeys,
    suffix: "_is",
    dateObj,
  })

  const bsResObj = processFmpStatement({
    rawStatement: rawBs,
    keysToIgnore: commonObjKeys,
    suffix: "_bs",
    resultObj: isResObj.resultObj,
  })

  const cfResObj = processFmpStatement({
    rawStatement: rawCf,
    keysToIgnore: commonObjKeys,
    suffix: "_cf",
    resultObj: bsResObj.resultObj,
  })

  // the rawRatios object has a different schema than IS & BS & CF ....
  // should I even bother transforming or just get straight to using
  // the results from dynamoDB which I believe have the correct format
  const { resultObj } = processFmpStatement({
    rawStatement: rawR,
    keysToIgnore: commonObjKeys,
    suffix: "_r",
    resultObj: cfResObj.resultObj,
  })

  // Process Segments Data if it Exists
  const segResultObj = processRevenueSegments(rawGeo, rawSegments, dateObj)

  const financialLineitems = generateFinancialLineitemArrays(
    cloneDeep(fmpTemplates),
    resultObj,
    dateArr
  )

  return { dateObj, dateArr, resultObj, segResultObj, financialLineitems }
}

const generateFinancialLineitemArrays = (
  arrayOfTemplateObjects,
  resultObj,
  dateArr
) => {
  return arrayOfTemplateObjects.map((templateObj) => {
    const keys = templateObj.keys.map((keyObj) => {
      // does keyObj exist?
      const metricArray = keyObj.key
      const metricExists = metricArray.reduce((acc, metric) => {
        const metricObj = resultObj[metric]
        return acc && metricObj
      }, true)

      if (keyObj.formula === "h3") {
        return keyObj
      } else if (metricExists) {
        // add data from resultObj to the lineitem entry for plotting
        // start with just val...
        if (
          keyObj.formula === "val" ||
          keyObj.formula === "turns" ||
          keyObj.formula === "pct"
        ) {
          const metricKey = metricArray[0]
          const metricDataObj = resultObj[metricKey]
          return { ...keyObj, ...metricDataObj }
        } else if (keyObj.formula === "dxdt") {
          // way to do math here?
          const pctChangeDataObj = annualChangeCalc(
            metricArray[0],
            resultObj,
            dateArr
          )
          return { ...keyObj, ...pctChangeDataObj }
        } else if (keyObj.formula === "div") {
          // do I need to do this or can it be done on the fly?
          // probably need to do it upfront for charting ability
          const divisionDataObj = divisionCalc(
            metricArray[0],
            metricArray[1],
            resultObj,
            dateArr
          )
          return { ...keyObj, ...divisionDataObj }
        } else if (dev) {
          console.error("unknown templateObj formula: ", keyObj)
        }
      } else {
        keyObj.tikrdisplay = "noData"
        return keyObj
      }

      return false
    })

    return { statement: templateObj.statement, keys }
  })
}

/**
 *
 * @param rawStatement is an array of objects
 * @param keysToIgnore is an object whose keys I need to ignore
 * @returns
 *
 * FIXME: Make sure this function can process an empty rawStatement array or worse
 * LINK static/fmp_worker.js:2401
 */
const processFmpStatement = ({
  rawStatement,
  keysToIgnore,
  suffix = "", // string "_is", "_bs", "_cf", "_r"
  resultObj = {},
}) => {
  try {
    const keysToIgnoreWithSuffix = Object.keys(keysToIgnore).map(
      (key) => `${key}${suffix}`
    )
    const findKeysToProcess = (rawStatementObj = {}) =>
      Object.keys(rawStatementObj).reduce((acc, keyName) => {
        const addKeyToObjectForProcessing =
          !keysToIgnoreWithSuffix.includes(keyName)
        if (addKeyToObjectForProcessing) {
          acc[keyName] = true
        }
        return acc
      }, {})

    const keysToProcessObj = rawStatement.reduce((acc, statementObj) => {
      const keyObj = findKeysToProcess(statementObj)
      return { ...acc, ...keyObj }
    }, {})

    const isRatio = suffix === "_r"

    rawStatement.forEach((obj) => {
      const innerDateObj = createDateObj(obj, fmpDateKeys, suffix)
      const dateKey = innerDateObj.dateKey

      Object.keys(keysToProcessObj).forEach((apiMetricKey) => {
        const tikrMetricKey = `${apiMetricKey}`
        const metricValue = obj[apiMetricKey]?.v || obj[apiMetricKey]

        if (metricValue) {
          const transformObj = fmpKeyObj[apiMetricKey]
            ? fmpKeyObj[apiMetricKey]
            : {
                unitId: 2,
                divideBy: 1000000,
              }
          const metricObject = {
            v: isRatio
              ? metricValue?.v || metricValue / transformObj.divideBy
              : metricValue,
            iso: innerDateObj?.iso || "USD",
            pc: innerDateObj.pc || 1,
            unauth: isRatio ? metricValue?.unauth : innerDateObj?.unauth,
            u: transformObj.unitId,
          }

          if (resultObj[tikrMetricKey]) {
            resultObj[tikrMetricKey][dateKey] = metricObject
          } else {
            const initMetricObject = {}
            initMetricObject[dateKey] = metricObject
            resultObj[tikrMetricKey] = initMetricObject
          }
        }
      })
    })
  } catch (error) {
    // idk do something with the error

    if (dev) {
      console.error(
        `error processing fmp statement: `,
        JSON.stringify(error, null, 2)
      )
    }
  }
  return { resultObj }
}

// LINK static/fmp_worker.js:2288
const makeDateMapForSegments = ({ dateObj, segmentDateKey }) => {
  const dateStrToDateKeyMap = {}

  Object.entries(dateObj).forEach(([dateKey, dateObj]) => {
    const rawSegmentDate = dateObj[segmentDateKey]
    dateStrToDateKeyMap[rawSegmentDate] = dateKey
  })
  return dateStrToDateKeyMap
}

// LINK static/fmp_worker.js:2298
const processRawSegmentObject = ({
  rawObj,
  dateObj,
  resultObj,
  segmentKey,
  dateStrToDateKeyMap,
}) => {
  const [rawSegmentDate, dataObj] = Object.entries(rawObj)[0] // Object.entries returns an array of arrays containing the entries
  const dateKey = dateStrToDateKeyMap[rawSegmentDate]

  if (dateKey) {
    const currency = dateObj[dateKey]?.iso
    const pc = dateObj[dateKey].pc
    const unauth = dateObj[dateKey].unauth

    Object.keys(dataObj).forEach((rawKey) => {
      // TODO: implement logic to see if user is on free tier
      const value = dataObj[rawKey]
      const metricKey = rawKey.toLowerCase()
      const metricObj = {
        v: unauth ?? value?.unauth ? 1.11 : value / 1000000,
        iso: currency,
        pc,
        u: 2,
        unauth: unauth ?? value?.unauth,
      }

      if (resultObj[segmentKey][metricKey]) {
        resultObj[segmentKey][metricKey][dateKey] = metricObj
      } else {
        const initialMetricObj = {}
        initialMetricObj[dateKey] = metricObj
        resultObj[segmentKey][metricKey] = initialMetricObj
      }
    })
  } else if (dev) {
    console.error(`Error geo segment missing datekey: `, rawObj)
  }
}

// LINK static/fmp_worker.js:2349
const processRevenueSegments = (
  rawGeo = [],
  rawSegments = [],
  dateObj = {},
  segmentDateKey = "dateStr"
) => {
  const dateStrToDateKeyMap = makeDateMapForSegments({
    dateObj,
    segmentDateKey,
  })

  const revenueSegments = {
    businessSegment: {},
    geographySegment: {},
  }

  rawGeo.forEach((geoObj) => {
    processRawSegmentObject({
      rawObj: geoObj,
      dateObj,
      resultObj: revenueSegments,
      segmentKey: "geographySegment",
      dateStrToDateKeyMap,
    })
  })

  rawSegments.forEach((businessObj) => {
    processRawSegmentObject({
      rawObj: businessObj,
      dateObj,
      resultObj: revenueSegments,
      segmentKey: "businessSegment",
      dateStrToDateKeyMap,
    })
  })

  const businessSegment = mergeKeys(revenueSegments.businessSegment)
  const geographySegment = mergeKeys(revenueSegments.geographySegment)

  if (isEmpty(businessSegment) && isEmpty(geographySegment)) {
    return {}
  }

  return {
    businessSegment,
    geographySegment,
  }
}

export { processFmpFinancials, commonObjKeys }
