import { VCard } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSelect } from 'vuetify/lib/components/VSelect';

import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.number.is-nan.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.bold.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_setup.loading ? _c(VCard, {
    staticClass: "py-6",
    attrs: {
      "elevation": "0"
    }
  }, [_c(VProgressCircular, {
    staticClass: "quote",
    attrs: {
      "size": 70,
      "width": 7,
      "color": "primaryAction",
      "indeterminate": ""
    }
  })], 1) : [_c('div', {
    staticClass: "er-wrapper",
    "class": {
      firstColumnFrozen: _setup.freezeFirstColumn
    }
  }, [_c('div', {
    staticClass: "wrapper"
  }, [_c(VDataTable, {
    attrs: {
      "dense": "",
      "hide-default-footer": true,
      "options": {
        itemsPerPage: _setup.activeQuarterData.data.length
      },
      "items": _setup.activeQuarterData.data,
      "mobile-breakpoint": 0
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('thead', [_c('tr', [_c('th', {
          staticClass: "first-row common-header-cell px-4 py-2",
          attrs: {
            "colspan": "2"
          }
        }, [_c(VSelect, {
          staticClass: "select-quarter",
          attrs: {
            "flat": "",
            "small": "",
            "solo": "",
            "items": _setup.currentPeriods
          },
          model: {
            value: _setup.activeQuarter,
            callback: function callback($$v) {
              _setup.activeQuarter = $$v;
            },
            expression: "activeQuarter"
          }
        })], 1), _vm._v(" "), _vm._l(_setup.activeQuarterData.headers.length - 2, function (_, idx) {
          return _c('th', {
            key: idx,
            staticClass: "first-row common-header-cell"
          });
        })], 2), _vm._v(" "), _c('tr', _vm._l(_setup.activeQuarterData.headers, function (h) {
          return _c('th', {
            key: h.value || h.columnId,
            staticClass: "common-header-cell second-row",
            "class": h["class"],
            attrs: {
              "id": "header_".concat(h.value || h.columnId)
            }
          }, [h.value !== 'metricName' ? [_c('span', [_vm._v(_vm._s(h.text))]), _vm._v(" "), _c('strong', {
            staticClass: "d-block"
          }, [_vm._v("\n                      " + _vm._s(h.bold) + "\n                    ")])] : _c('strong', [_vm._v("\n                    " + _vm._s(_setup.tickerSymbol) + " Q" + _vm._s(_setup.activeQuarterData.quarterInfo.fiscalquarter) + "\n                    Earnings |\n                    "), _c('wbr'), _vm._v("\n                    TIKR.com\n                  ")])], 2);
        }), 0)])];
      },
      proxy: true
    }, {
      key: "item",
      fn: function fn(_ref) {
        var _item$meta;
        var item = _ref.item;
        return [_c('tr', {
          staticClass: "common-row",
          "class": item === null || item === void 0 || (_item$meta = item.meta) === null || _item$meta === void 0 ? void 0 : _item$meta.rowClass
        }, _vm._l(Object.keys(item).filter(function (i) {
          return i !== 'meta';
        }), function (field) {
          return _c('td', {
            key: "".concat(field, "_").concat(item.metricName, "_").concat(item[field]),
            "class": _setup.getCellClass(field, item)
          }, [field === 'metricName' ? [_vm._v("\n                  " + _vm._s(item[field]) + "\n                ")] : _c(_setup.TableCell, {
            attrs: {
              "desired-units": _setup.desiredUnits,
              "currency-toggle": _setup.currencyToggle,
              "format": item[field].format,
              "decimals": _setup.decimals,
              "unauth": item[field].unauth,
              "unit-id": item[field].unitId,
              "value": item[field].value,
              "price-close": item[field].priceClose,
              "hide": item[field].hideRowValues || Number.isNaN(item[field].value),
              "format-function": _vm.formatFunction
            }
          })], 2);
        }), 0)];
      }
    }])
  })], 1)])]], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };