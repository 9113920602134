import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.is-nan.js";
import "core-js/modules/es.string.includes.js";
import { scaleEstimateValue } from "~/utils/values/scale";
import UnauthorizedIcon from "~/components/UnauthorizedIcon.vue";
export default {
  __name: 'BmTableCell',
  props: {
    desiredUnits: {
      type: Number,
      "default": 1
    },
    currencyToggle: {
      type: Number,
      "default": 0
    },
    format: {
      type: String,
      "default": "val"
    },
    decimals: {
      type: Number,
      "default": 2
    },
    unauth: {
      type: Boolean,
      "default": false
    },
    unitId: {
      type: Number,
      "default": null
    },
    value: {
      type: Number,
      "default": 0
    },
    priceClose: {
      type: Number,
      "default": 1
    },
    formatFunction: {
      type: Function,
      "default": function _default(value) {
        return value;
      }
    },
    hide: {
      type: Boolean,
      "default": false
    },
    customStringValue: {
      type: String,
      "default": ""
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var pctVars = ["whole_pct", "pct"];
    var absoluteFormats = [].concat(pctVars, ["bps", "turns"]);
    var val = computed(function () {
      if (absoluteFormats.includes(props.format)) {
        return props.value;
      }
      var convertedValue = props.currencyToggle ? props.value / props.priceClose : props.value;
      return scaleEstimateValue({
        value: convertedValue,
        currentUnit: props.unitId,
        desiredUnit: props.desiredUnits
      });
    });
    var formattedValue = computed(function () {
      if (props.customStringValue) {
        return props.customStringValue;
      }
      return props.formatFunction(val.value, props.format);
    });
    var unauthorized = computed(function () {
      return props.unauth && !props.hide;
    });
    var show = computed(function () {
      return !Number.isNaN(val.value) && !props.hide;
    });
    return {
      __sfc: true,
      props: props,
      pctVars: pctVars,
      absoluteFormats: absoluteFormats,
      val: val,
      formattedValue: formattedValue,
      unauthorized: unauthorized,
      show: show,
      UnauthorizedIcon: UnauthorizedIcon
    };
  }
};