import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/esnext.set.add-all.js";
import "core-js/modules/esnext.set.delete-all.js";
import "core-js/modules/esnext.set.difference.js";
import "core-js/modules/esnext.set.every.js";
import "core-js/modules/esnext.set.filter.js";
import "core-js/modules/esnext.set.find.js";
import "core-js/modules/esnext.set.intersection.js";
import "core-js/modules/esnext.set.is-disjoint-from.js";
import "core-js/modules/esnext.set.is-subset-of.js";
import "core-js/modules/esnext.set.is-superset-of.js";
import "core-js/modules/esnext.set.join.js";
import "core-js/modules/esnext.set.map.js";
import "core-js/modules/esnext.set.reduce.js";
import "core-js/modules/esnext.set.some.js";
import "core-js/modules/esnext.set.symmetric-difference.js";
import "core-js/modules/esnext.set.union.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _toConsumableArray(r) {
  return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread();
}
function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _iterableToArray(r) {
  if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r);
}
function _arrayWithoutHoles(r) {
  if (Array.isArray(r)) return _arrayLikeToArray(r);
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import Earnings from "~/components/tables/beatsAndMisses/EarningsReview.vue";
import BeatsAndMisses from "~/components/tables/beatsAndMisses/BeatsAndMisses.vue";
import useBeatsAndMisses from "~/functions/useBeatsAndMisses";
import TableChartButton from "~/components/charts/TableChartButton.vue";
import EstimatesBreakdown from "~/components/tables/EstimatesBreakdown.vue";
import useBaseUtils from "~/functions/useBaseUtils";
import useCiqTableOptions from "~/functions/useCiqTableOptions";
import useDateTimeFormatters from "~/functions/useDateTimeFormatters";
import useCiqFinTableInChart from "~/functions/useCiqFinTableInChart";
import useCIQEstimates from "~/functions/ciq/useCIQEstimates";
import StyledTabs from "~/components/StyledTabs";
import { estimatesTabs, findStatementIndex } from "~/utils/constants/tabs";
import { BEATS_AND_MISSES, EARNINGS_TRENDS } from "~/feature-toggle";
import { extraRows } from "~/utils/ciq/common";
export default defineComponent({
  components: {
    BeatsAndMisses: BeatsAndMisses,
    TableChartButton: TableChartButton,
    Earnings: Earnings,
    EstimatesBreakdown: EstimatesBreakdown,
    StyledTabs: StyledTabs
  },
  setup: function setup() {
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store,
      $route = _useNuxtApp.$route;
    var _useBaseUtils = useBaseUtils(),
      I18nFn = _useBaseUtils.I18nFn,
      freezeFirstColumn = _useBaseUtils.freezeFirstColumn,
      darkActive = _useBaseUtils.darkActive,
      onlyShowOrigEstMetrics = _useBaseUtils.onlyShowOrigEstMetrics,
      dev = _useBaseUtils.dev,
      freeTier = _useBaseUtils.freeTier,
      plusTier = _useBaseUtils.plusTier,
      refCode = _useBaseUtils.refCode,
      sectionWrapperStyle = _useBaseUtils.sectionWrapperStyle;
    var _useDateTimeFormatter = useDateTimeFormatters(),
      utcEpochToShortDate = _useDateTimeFormatter.utcEpochToShortDate;
    var _useCiqFinTableInChar = useCiqFinTableInChart(),
      transformEstRowToHighchart = _useCiqFinTableInChar.transformEstRowToHighchart;
    var _useBeatsAndMisses = useBeatsAndMisses(),
      earnings = _useBeatsAndMisses.earnings,
      earningsHeaders = _useBeatsAndMisses.earningsHeaders,
      loading = _useBeatsAndMisses.loading;
    var _useCiqTableOptions = useCiqTableOptions({
        storeModule: "ciq",
        tableType: "estimates"
      }),
      decimals = _useCiqTableOptions.decimals,
      formatPercentToDecimals = _useCiqTableOptions.formatPercentToDecimals,
      formatNumberToDecimals = _useCiqTableOptions.formatNumberToDecimals,
      desiredUnits = _useCiqTableOptions.desiredUnits,
      activePeriod = _useCiqTableOptions.activePeriod,
      currencyToggle = _useCiqTableOptions.currencyToggle,
      dateRange = _useCiqTableOptions.dateRange,
      makeDateLabels = _useCiqTableOptions.makeDateLabels,
      currencies = _useCiqTableOptions.currencies,
      reverseDates = _useCiqTableOptions.reverseDates; // er: Earnings Review, bm: Beats & Misses
    var quarterSlugs = ["er", "bm"];
    var actualsAndEstimatesTabId = 0;
    var mgmtGuidanceTabId = 1;
    var earningsTabId = 2;
    var beatsAndMissesTabId = 3;
    var earningTrendsTabId = 4; // old data() obj
    var stateKey = ref("estimates");
    var loadingKey = ref("fetchingActuals");
    var errorKey = ref("estimatesError");
    var mainTabTitle = ref("Actuals & Forward Estimates");
    var specialPeriod = ref(false);
    var options = ref(false);
    var units = ref([{
      text: "Displayed in thousands",
      value: 0,
      title: "k"
    }, {
      text: "Displayed in millions",
      value: 1,
      title: "MM"
    }, {
      text: "Displayed in billions",
      value: 2,
      title: "B"
    }]);
    var showAllEmptyRows = ref(false);
    var isBeatsAndMisses = ref(quarterSlugs.includes($route.query.tab));
    var isBmTab = ref($route.query.tab === "bm");
    var isEarningsReviewTab = ref($route.query.tab === "er");
    var isEstimatesBreakdown = ref($route.query.tab === "eb");
    var showTurns = ref(true);
    var getToLink = function getToLink(_ref) {
      var cid = _ref.cid,
        tid = _ref.tid,
        tab = _ref.tab,
        refCode = _ref.refCode;
      return "/stock/estimates?cid=".concat(cid, "&tid=").concat(tid, "&tab=").concat(tab, "&ref=").concat(refCode);
    };
    var ticker = computed(function () {
      return $store.state.ciq.ticker;
    });
    var statementTabs = computed(function () {
      return estimatesTabs.filter(function (item) {
        if (quarterSlugs.includes(item.slug)) {
          return BEATS_AND_MISSES;
        } // if is Estimates Breakdown tab
        if (item.slug === "eb") {
          return EARNINGS_TRENDS;
        }
        return true;
      }).map(function (m) {
        var toLink = getToLink({
          cid: ticker.value.companyid,
          tid: ticker.value.tradingitemid,
          tab: m.slug,
          refCode: refCode.value
        });
        return _objectSpread({
          to: toLink,
          nuxt: true,
          exact: true
        }, m);
      });
    });
    var currentIso = computed(function () {
      return currencies.value[currencyToggle.value].code;
    });
    var initialStatementIndex = computed(function () {
      var tabQueryParam = $route.query.tab;
      var statementArr = statementTabs.value;
      return findStatementIndex(statementArr, tabQueryParam);
    });
    var activeStatement = ref(initialStatementIndex.value);
    var displayRangeSlider = computed(function () {
      if (isBeatsAndMisses.value) {
        return false;
      }
      return !isEstimatesBreakdown.value;
    });
    var periods = computed(function () {
      return [{
        title: "Annual",
        value: "annual",
        period: "a",
        disabled: isBeatsAndMisses.value
      }, {
        title: "Quarterly",
        value: "quarter",
        period: "q"
      }, {
        title: "Semi Annual",
        value: "semiAnnual",
        period: "semi",
        disabled: isBeatsAndMisses.value || isEstimatesBreakdown.value
      }, {
        title: "Calendar Year",
        value: "cy",
        period: "cy",
        disabled: isBeatsAndMisses.value || isEstimatesBreakdown.value
      }];
    });
    var period = computed(function () {
      return periods.value[activePeriod.value].period;
    });
    var _useCIQEstimates = useCIQEstimates({
        period: period,
        dateRange: dateRange,
        reverseDates: reverseDates,
        stateKey: stateKey,
        extraRows: extraRows
      }),
      resData = _useCIQEstimates.resData,
      ciqCategories = _useCIQEstimates.ciqCategories,
      availableDates = _useCIQEstimates.availableDates,
      estimates = _useCIQEstimates.estimates,
      dateHeaders = _useCIQEstimates.dateHeaders,
      dateHeadersForHighcharts = _useCIQEstimates.dateHeadersForHighcharts,
      dailyPriceFactors = _useCIQEstimates.dailyPriceFactors,
      loadingEstimates = _useCIQEstimates.loading;
    var formatCellVal = function formatCellVal(value, format) {
      var lessThanZero = value < 0;
      var valToFormat = lessThanZero ? Math.abs(value) : value;
      if (format === "float") {
        return formatNumberToDecimals.value.format(value);
      } else if (format === "pct" || format === "whole_pct") {
        var formatted = formatPercentToDecimals.value.format(valToFormat);
        return lessThanZero ? "(".concat(formatted, ")") : formatted;
      } else if (format === "bps") {
        var finalVal = valToFormat.toFixed(0);
        return lessThanZero ? "(".concat(Math.abs(finalVal), "bps)") : "".concat(finalVal, "bps");
      } else if (format === "turns") {
        var isPositive = value >= 0;
        var _finalVal = isPositive ? value : Math.abs(value);
        var formatFn = formatNumberToDecimals.value.format;
        return isPositive ? "".concat(formatFn(_finalVal), "x") : "(".concat(formatFn(_finalVal), "x)");
      } else if (format === "val") {
        var _formatted = formatNumberToDecimals.value.format(valToFormat);
        return lessThanZero ? "(".concat(_formatted, ")") : _formatted;
      }
      return value;
    };
    var handleTabChange = function handleTabChange(e, idx) {
      activeStatement.value = idx;
    };
    var tableClassToMatch = computed(function () {
      if (isBmTab.value) {
        return ".bm-wrapper table";
      }
      if (isEarningsReviewTab.value) {
        return ".er-wrapper table";
      }
      if (isEstimatesBreakdown.value) {
        return ".estimates-table-wrapper table";
      }
      return null;
    });
    var data = computed(function () {
      var data = $store.state.ciq.estimates;
      if (stateKey.value !== "estimates") {
        data = $store.state.ciq[stateKey.value];
      }
      return data;
    });
    return {
      handleTabChange: handleTabChange,
      findStatementIndex: findStatementIndex,
      activeStatement: activeStatement,
      I18nFn: I18nFn,
      dev: dev,
      freezeFirstColumn: freezeFirstColumn,
      darkActive: darkActive,
      onlyShowOrigEstMetrics: onlyShowOrigEstMetrics,
      showAllEmptyRows: showAllEmptyRows,
      statementTabs: statementTabs,
      decimals: decimals,
      formatNumberToDecimals: formatNumberToDecimals,
      desiredUnits: desiredUnits,
      activePeriod: activePeriod,
      currencyToggle: currencyToggle,
      dateRange: dateRange,
      makeDateLabels: makeDateLabels,
      currencies: currencies,
      reverseDates: reverseDates,
      formatDate: utcEpochToShortDate,
      transformEstRowToHighchart: transformEstRowToHighchart,
      periods: periods,
      period: period,
      availableDates: availableDates,
      dateHeaders: dateHeaders,
      dateHeadersForHighcharts: dateHeadersForHighcharts,
      sectionWrapperStyle: sectionWrapperStyle,
      isBeatsAndMisses: isBeatsAndMisses,
      quarterSlugs: quarterSlugs,
      earnings: earnings,
      earningsHeaders: earningsHeaders,
      formatCellVal: formatCellVal,
      loadingTable: loading,
      isFreeTier: freeTier,
      isPlusTier: plusTier,
      isBmTab: isBmTab,
      isEarningsReviewTab: isEarningsReviewTab,
      isEstimatesBreakdown: isEstimatesBreakdown,
      displayRangeSlider: displayRangeSlider,
      tableClassToMatch: tableClassToMatch,
      actualsAndEstimatesTabId: actualsAndEstimatesTabId,
      mgmtGuidanceTabId: mgmtGuidanceTabId,
      earningsTabId: earningsTabId,
      beatsAndMissesTabId: beatsAndMissesTabId,
      earningTrendsTabId: earningTrendsTabId,
      resData: resData,
      ciqCategories: ciqCategories,
      showTurns: showTurns,
      stateKey: stateKey,
      loadingKey: loadingKey,
      errorKey: errorKey,
      mainTabTitle: mainTabTitle,
      specialPeriod: specialPeriod,
      options: options,
      units: units,
      estimates: estimates,
      currentIso: currentIso,
      dailyPriceFactors: dailyPriceFactors,
      data: data,
      loadingEstimates: loadingEstimates
    };
  },
  computed: {
    periodObj: function periodObj() {
      var period = this.periods[this.activePeriod];
      if (this.specialPeriod) {
        return {
          title: "Next Twelve Months",
          value: "ntm",
          period: "ntm"
        };
      }
      return period;
    },
    selectedRows: function selectedRows() {
      var period = this.period;
      var finData = this.$store.state.ciq.estimatesChart;
      if (finData[period]) {
        return finData[period] || {};
      } else {
        return {};
      }
    },
    rowsForChart: function rowsForChart() {
      var _this = this; // FIXME: this is running twice every time something is added?
      var selectedRows = this.selectedRows;
      var currencyToggle = this.currencyToggle;
      var desiredUnits = this.desiredUnits;
      var splitRows = Object.keys(selectedRows).reduce(function (acc, r) {
        var rowType = r.split("-")[0];
        if (rowType === "val" || rowType === "turns") {
          acc[0].push(r);
        } else {
          acc[1].push(r);
        }
        return acc;
      }, [[], []]);
      var orgRows = splitRows[0].concat(splitRows[1]); // rowid for selected object is formula+dataitemid
      return orgRows.map(function (rowId) {
        var splitRow = rowId.split("-");
        if (splitRow[0] === "guidance") {
          return _this.transformEstRowToHighchart(_this.resData, _this.dateHeadersForHighcharts, selectedRows[rowId], desiredUnits, currencyToggle, rowId, _this.formatNumberToDecimals, splitRow[2]);
        } else {
          return _this.transformEstRowToHighchart(_this.resData, _this.dateHeadersForHighcharts, selectedRows[rowId], desiredUnits, currencyToggle, rowId, _this.formatNumberToDecimals);
        }
      });
    },
    loading: function loading() {
      return this.$store.state.ciq[this.loadingKey];
    },
    error: function error() {
      return this.$store.state.ciq[this.errorKey];
    },
    ticker: function ticker() {
      return this.$store.state.ciq.ticker || {};
    },
    tid: function tid() {
      return this.$route.query.tid || false;
    },
    visibleMgmtGuidanceLineitems: function visibleMgmtGuidanceLineitems() {
      return this.estimates[1].filter(function (f) {
        if (f.tikrdisplay === "1" && f.formula !== "h3" && (_typeof(f.guideMid) === "object" || _typeof(f.guideHigh) === "object" || _typeof(f.guideLow) === "object")) {
          return f;
        }
        return false;
      });
    },
    visibleMgmtGuidanceDateHeaders: function visibleMgmtGuidanceDateHeaders() {
      var estPeriodIdsArr = [];
      this.visibleMgmtGuidanceLineitems.forEach(function (item) {
        var keys = Object.keys(item);
        if (keys.includes("guideMid")) {
          estPeriodIdsArr.push.apply(estPeriodIdsArr, _toConsumableArray(Object.keys(item.guideMid)));
        }
        if (keys.includes("guideHigh")) {
          estPeriodIdsArr.push.apply(estPeriodIdsArr, _toConsumableArray(Object.keys(item.guideHigh)));
        }
        if (keys.includes("guideLow")) {
          estPeriodIdsArr.push.apply(estPeriodIdsArr, _toConsumableArray(Object.keys(item.guideLow)));
        }
      });
      var estPeriodIds = new Set(estPeriodIdsArr);
      var visibleDateHeaders = this.availableDates.filter(function (dateHeader) {
        return estPeriodIds.has(dateHeader.value.toString());
      });
      return visibleDateHeaders;
    }
  },
  watch: {
    "$route.query.tab": {
      handler: function handler(slug) {
        this.activeStatement = this.findStatementIndex(this.statementTabs, slug);
        this.isBeatsAndMisses = this.quarterSlugs.includes(slug);
        this.isBmTab = slug === "bm";
        this.isEarningsReviewTab = slug === "er";
        this.isEstimatesBreakdown = slug === "eb";
      }
    }
  },
  mounted: function mounted() {
    var _this2 = this; // do stuff here
    try {
      this.$nextTick(function () {
        try {
          _this2.$vuetify.goTo("#stocktabbar");
        } catch (error) {
          console.error("Error .goto from multiplesTable: ", error);
        }
      });
    } catch (error) {
      console.error("Error .goto from estimatesTable: ", error);
    }
  },
  methods: {
    copyTable: function copyTable(tableId) {
      var _this3 = this;
      try {
        this.showTurns = false;
        this.$nextTick(function () {
          var table = document.querySelector(tableId || "table.fintab");
          var range = document.createRange(); // FIXME: change table.thead.style?
          var sel = window.getSelection();
          sel.removeAllRanges();
          range.selectNode(table);
          sel.addRange(range);
          document.execCommand("copy");
          _this3.showTurns = true;
        });
      } catch (error) {
        console.error("Error copying table: ", error); // perform more error handling here?
      }
    },
    removeRowFromSelectedObject: function removeRowFromSelectedObject(rowId) {
      // code to remove the finRow from the selectedRow Object
      // what happens if there is nothing here?
      // how to remove a key from and object resulting in a new copy...
      // need to invoke reactivity on removal of row
      var payload = {
        rowId: rowId,
        period: this.period,
        chartType: "estimatesChart"
      };
      this.$store.commit("ciq/removeFromChart", payload);
    }
  }
});