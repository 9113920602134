import "core-js/modules/es.number.constructor.js";
import useBaseUtils from "~/functions/useBaseUtils";
import useCiqTableUtils from "~/functions/useCiqTableUtils";
import glossary from "~/utils/templates/glossary";
export default defineComponent({
  props: {
    tbltitle: {
      type: String,
      "default": "Mangement Guidance"
    },
    dateHeaders: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    lineitems: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    estDataObj: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    estDataToShow: {
      type: Object,
      "default": function _default() {
        return {
          guideMid: "Guide mid",
          guideHigh: "Guide high",
          guideLow: "Guide low"
        };
      }
    },
    desiredunits: {
      type: Number,
      "default": 1
    },
    decimals: {
      type: Number,
      "default": 2
    },
    currencytoggle: {
      type: Number,
      "default": 0
    },
    showturns: {
      type: Boolean,
      "default": true
    },
    segType: {
      type: String,
      "default": "guidance"
    },
    period: {
      type: String,
      "default": "a"
    }
  },
  setup: function setup(props) {
    var _useBaseUtils = useBaseUtils(),
      freezeFirstColumn = _useBaseUtils.freezeFirstColumn,
      darkActive = _useBaseUtils.darkActive,
      I18nFn = _useBaseUtils.I18nFn;
    var _useCiqTableUtils = useCiqTableUtils(props),
      formatCiqDataObj = _useCiqTableUtils.formatCiqDataObj,
      nestedRowToSelectedKey = _useCiqTableUtils.nestedRowToSelectedKey,
      addRowToSelectedObject = _useCiqTableUtils.addRowToSelectedObject,
      removeRowFromSelectedObject = _useCiqTableUtils.removeRowFromSelectedObject,
      selectedRows = _useCiqTableUtils.selectedEstimateRows,
      generateRowClasses = _useCiqTableUtils.generateRowClasses; // const generateRowClasses = (darkMode, row, segmentName, title = true) => {
    //   let classArray = row.format.split(" ")
    //   if (!title) {
    //     classArray = []
    //   } else {
    //     if (classArray.includes("lineabove")) {
    //       if (darkMode) {
    //         classArray.push("whitelineabove")
    //       } else {
    //         classArray.push("blacklineabove")
    //       }
    //     }
    //   }
    //   const rowId = nestedRowToSelectedKey(row, segmentName)
    //   if (selectedRows.value[rowId]) {
    //     classArray.push("clicked")
    //   }
    //   return classArray
    // }
    var estGuideRowClick = function estGuideRowClick(lineitem, segmentName) {
      var rowId = nestedRowToSelectedKey(lineitem, segmentName);
      if (selectedRows.value[rowId]) {
        removeRowFromSelectedObject(rowId, props.period, "estimatesChart");
      } else {
        addRowToSelectedObject(lineitem, rowId, props.period, "estimatesChart");
      }
      return rowId;
    };
    return {
      freezeFirstColumn: freezeFirstColumn,
      darkActive: darkActive,
      formatCiqDataObj: formatCiqDataObj,
      generateRowClasses: generateRowClasses,
      estGuideRowClick: estGuideRowClick,
      nestedRowToSelectedKey: nestedRowToSelectedKey,
      selectedRows: selectedRows,
      I18nFn: I18nFn
    };
  },
  data: function data() {
    return {
      glossary: glossary
    };
  }
});